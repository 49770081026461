import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {LanguageContext} from '../language/lang';
import {
  Card,
  Div,
  Hr,
  IconButton,
  NotLoggenInMessage,
  Span,
} from './Components';
import {get} from 'lodash';
import useDebug from '../debug/useDebug';
import moment from 'moment';
import {COLORS} from '../common/constants';
import {Platform} from '../common/nativeFunctions';
import MenuBarNew from './MenuBarNew';

function AllChats({navigation, chats, app, user, width}) {
  useDebug('AllChats');
  const lang = useContext(LanguageContext);

  return (
    <Div style={{height: '100%'}}>
      <MenuBarNew
        shadow
        title={lang['chats']}
        onNavigate={() =>
          navigation.navigate(
            app === 'courier' ? 'courier-order-around' : 'sender-input-order-1',
          )
        }
      />
      {chats.length === 0 ? (
        user.uid ? (
          <Card
            style={{width: Platform.OS === 'web' ? width - 80 : width - 40}}>
            <Span style={styles.text} noTranslate>
              {lang['no-chats']}
            </Span>
          </Card>
        ) : (
          <NotLoggenInMessage
            redirect={app + '-chat-list'}
            navigation={navigation}
          />
        )
      ) : null}
      <Div scrollY>
        {chats
          .sort(
            (a, b) =>
              get(b, 'lastMessage.createdAt', 0) -
              get(a, 'lastMessage.createdAt', 0),
          )
          .map((chat, i) => (
            <>
              <IconButton
                style={{...styles.notificationContainer, width: '100%'}}
                key={i}
                title="openChat"
                onClick={() =>
                  navigation.navigate(app + '-chat-view', {
                    orderId: get(chat, 'orderId', ''),
                    uid: get(chat, 'uid', ''),
                  })
                }>
                <Div theme="flex-row jc-space-between">
                  <Span style={styles.title} noTranslate>
                    {get(chat, 'orderId', '')}
                  </Span>
                  <Span style={styles.date} noTranslate>
                    {moment(get(chat, 'lastMessage.createdAt', 0)).format(
                      lang['moment-full-datetime'],
                    )}
                  </Span>
                </Div>
                <Div theme="flex-row jc-space-between ai-center">
                  <Span style={{color: '#888'}} noTranslate>
                    {get(chat, 'lastMessage.text', '').substr(0, 40) +
                      (get(chat, 'lastMessage.text', '').length > 40
                        ? '...'
                        : '')}
                  </Span>
                  {get(chat, 'newMessages', '') ? (
                    <Div
                      style={{
                        backgroundColor: COLORS.red,
                        borderRadius: 30,
                        width: 15,
                        height: 15,
                        elevation: 2,
                      }}
                    />
                  ) : null}
                </Div>
              </IconButton>
              <Hr style={{marginTop: 0, marginBottom: 0}} />
            </>
          ))}
      </Div>
    </Div>
  );
}

const styles = {
  date: {
    color: 'grey',
    fontSize: 12,
    lineHeight: 20,
  },
  notificationContainer: {
    marginVertical: 5,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  card: {
    paddingVertical: 0,
  },
  label: {
    backgroundColor: '#f4f4f4',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: '#fff',
    borderTopWidth: 0,
    borderWidth: 0,
    color: 'grey',
    fontSize: 12,
    lineHeight: 22,
    paddingHorizontal: 3,
    textAlign: 'center',
    width: 150,
  },
  list: {
    backgroundColor: '#fcfcfc',
    marginBottom: 65,
    padding: 0,
    paddingTop: 10,
  },
  loaderContainer: {
    paddingVertical: 50,
  },
  noOrders: {
    margin: 20,
  },
  text: {
    fontSize: 18,
  },
};

const mapStateToProps = state => {
  const app = state.app;
  const chats = Object.entries(get(state, `chats`, {})).map(ent => {
    const messages = get(Object.values(ent[1] || {}), '[0].messages', {});
    const type = get(Object.values(ent[1] || {}), '[0].type', '');
    const uid = get(Object.keys(ent[1] || {}), '[0]', '');
    const lastMessage = Object.values(messages)
      .filter(message => typeof message === 'object')
      .reduce((a, b) => {
        if (a.createdAt > b.createdAt) return a;
        else return b;
      }, {});
    return {
      orderId: ent[0],
      lastMessage,
      uid,
      type,
      newMessages: get(messages, 'newMessages', false),
    };
  });
  return {
    chats: chats.filter(chat => chat.type === app),
    user: state.user,
    offline: state.offline,
    width: get(state, 'window.width', 0),
    height: get(state, 'window.height', 0),
    userInProgress: state.userInProgress,
    app,
  };
};

export default connect(mapStateToProps)(AllChats);
