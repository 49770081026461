import React from 'react';
import {connect} from 'react-redux';
import {Platform} from '../../common/nativeFunctions';
import Div from './Div';

const KeyboardPadding = ({keyboradOpen}) => {
  return keyboradOpen && Platform.OS !== 'web' ? (
    <Div style={{height: 400, width: '100%'}} />
  ) : null;
};

const mapStateToProps = state => ({
  keyboradOpen: state.keyboradOpen,
});

export default connect(mapStateToProps)(KeyboardPadding);
