import React from 'react';
import {useFocusOnRender} from '../hooks/useFocusOnRender';
import {useOnEsc} from '../hooks/useOnEsc';
import {FocusTrapWithErrorBoundary} from './FocusTrapWithErrorBoundary';

export default ({children, isVisible, onEscPressed}) => {
  const modalRef = useFocusOnRender();

  useOnEsc({
    onEscPressed,
  });

  return isVisible ? (
    <FocusTrapWithErrorBoundary>
      <div
        ref={modalRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#000000aa',
          zIndex: 7,
        }}>
        {children}
      </div>
    </FocusTrapWithErrorBoundary>
  ) : null;
};
