import React, {useContext, useEffect, useState} from 'react';
import {AutoSizer, List} from 'react-virtualized';
import {ThemeContext} from '../../themes/theme';

export default ({
  itemsToRender,
  itemRenderer,
  height,
  offset,
  rowHeight = 1,
}) => {
  const [renderTime, setrenderTime] = useState(Date.now());
  useEffect(() => {
    setrenderTime(Date.now());
  }, [itemsToRender]);
  const themeContext = useContext(ThemeContext);
  if (!height) {
    height = themeContext.height - (offset || 0);
  }
  const Renderer = itemRenderer || null;
  return (
    <AutoSizer disableHeight>
      {({width}) => (
        <List
          id={String(renderTime)}
          height={height}
          rowCount={(itemsToRender || []).length}
          rowHeight={rowHeight}
          overscanRowCount={20}
          rowRenderer={({index, style, key}) =>
            Renderer && (itemsToRender || [])[index] ? (
              <div style={style} key={key}>
                <Renderer item={(itemsToRender || [])[index] || {}} />
              </div>
            ) : null
          }
          width={width}
        />
      )}
    </AutoSizer>
  );
};
