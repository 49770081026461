import React from 'react';
import {connect} from 'react-redux';
import {IconButton, Div, Hr, Span} from './Components';
import {get} from 'lodash';
import {ICClose} from './Icons';

function SelectModal({list, isVisible, onSelect, width, height}) {
  const styles = {
    background: {
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-around',
      width: '100%',
    },
    close: {
      margin: 0,
      padding: 5,
    },
    foreground: {
      backgroundColor: 'white',
      borderRadius: 8,
      maxHeight: Math.min(height - 150, list.length * 61) + 40,
      padding: 10,
      width: width - 50,
    },
    hr: {
      borderBottomColor: '#EEEEEE',
      marginBottom: 0,
      marginTop: 0,
    },
    row: {
      height: 50,
      paddingHorizontal: 5,
      width: '100%',
    },
    rowText: {
      lineHeight: 20,
    },
  };

  const Card = ({item}) => (
    <>
      {item.id !== '0' ? <Hr style={styles.hr} /> : null}
      <IconButton
        title={item.text}
        style={styles.row}
        onClick={() =>
          typeof onSelect === 'function' ? onSelect(item.value) : null
        }>
        <Span
          theme="flex-column jc-center ai-center"
          style={styles.rowText}
          numberOfLines={1}
          ellipsizeMode="clip">
          {item.text}
        </Span>
      </IconButton>
    </>
  );

  return isVisible ? (
    <Div style={styles.background} role="dialog">
      <Div style={styles.foreground}>
        <Div theme="flex-row">
          <IconButton
            title="close"
            style={styles.close}
            onClick={() =>
              typeof onSelect === 'function' ? onSelect() : null
            }>
            <ICClose width={25} height={25} />
          </IconButton>
        </Div>
        <Div scrollY>
          {list.map(item => (
            <Card item={item} key={item.value} />
          ))}
        </Div>
      </Div>
    </Div>
  ) : null;
}

const mapStateToProps = state => ({
  ratings: get(state, 'savedRatings.ratings', []),
  list: get(state, 'selectModal.list', []),
  onSelect: get(state, 'selectModal.onSelect'),
  loaded: state.savedRatings,
  width: get(state, 'window.width', 0),
  height: get(state, 'window.height', 0),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectModal);
