import React, {useContext} from 'react';
import {get} from 'lodash';
import {ICChevronLeft, ICChevronRight} from '../Icons';
import {LanguageContext} from '../../language/lang';
import {connect} from 'react-redux';
import Div from './Div';
import Bold from './Bold';
import Span from './Span';

export const AddressComponent = ({
  fromAddress,
  toAddress,
  arrowColor,
  style,
  app,
  width,
}) => {
  const lang = useContext(LanguageContext);

  let mainColor = 'purple';
  if (app === 'courier') {
    mainColor = 'orange';
  }

  let fromCity = get(fromAddress, 'city', '----');
  let toCity = get(toAddress, 'city', '----');
  let fromStreet = get(fromAddress, 'street', '----');
  let toStreet = get(toAddress, 'street', '----');
  if (get(fromAddress, 'number')) {
    fromStreet = fromStreet + ', ' + get(fromAddress, 'number', 1);
  }
  if (get(toAddress, 'number')) {
    toStreet = toStreet + ', ' + get(toAddress, 'number', 1);
  }
  return (
    <Div
      theme="flex-row jc-space-between"
      style={{
        marginVertical: 0,
        marginTop: 15,
        marginBottom: 7,
        ...(style || {}),
      }}>
      <Div
        theme="flex-column jc-center flex-1 ai-center"
        style={{width: width / 2 - 40}}>
        <Bold numberOfLines={1}>{fromCity}</Bold>
        <Span numberOfLines={1} style={{fontSize: 10, lineHeight: 15}}>
          {fromStreet}
        </Span>
      </Div>
      {lang.id === 'HE' ? (
        <ICChevronLeft color={arrowColor || mainColor} height={38} />
      ) : (
        <ICChevronRight color={arrowColor || mainColor} height={38} />
      )}
      <Div
        theme="flex-column jc-center flex-1 ai-center"
        style={{width: width / 2 - 40}}>
        <Bold numberOfLines={1}>{toCity}</Bold>
        <Span numberOfLines={1} style={{fontSize: 10, lineHeight: 15}}>
          {toStreet}
        </Span>
      </Div>
    </Div>
  );
};

const mapStateToProps = state => ({
  app: state.app,
  width: get(state, 'window.width', 0),
});

export default connect(mapStateToProps)(AddressComponent);
