import React, {useState, useContext, useEffect} from 'react';
import {connect} from 'react-redux';
import {Div, Bold} from '../Components';
import {COLORS} from '../../common/constants';
import {get} from 'lodash';
import {LanguageContext} from '../../language/lang';
import {ICCheck} from '../Icons';
import moment from 'moment';
const STATE = {DISABLED: 'DISABLED', ACTIVE: 'ACTIVE', DONE: 'DONE'};

function ProgressBar({
  vertical,
  deliveryTime,
  expectedPickupTime,
  courierAcceptTime,
  expectedDeliverTime,
  senderPickedUpTime,
  status,
  width,
  height,
}) {
  const lang = useContext(LanguageContext);
  let ball1 = STATE.DISABLED;
  let ball2 = STATE.DISABLED;
  let ball3 = STATE.DISABLED;
  let progressBarStage1 = 0;
  let progressBarStage2 = 0;
  let label1 = '';
  let label2 = '';
  let label3 = '';

  const [time, settime] = useState(Date.now());
  useEffect(() => {
    let id = setInterval(() => settime(Date.now()), 60000);
    return () => {
      clearInterval(id);
    };
  }, []);

  switch (status) {
    case 'OFFER_ACCEPTED':
      label1 =
        lang['package-created'] +
        (moment(courierAcceptTime).isValid()
          ? ' ' +
            moment(courierAcceptTime).format(lang['moment-short-datetime'])
          : '');
      label2 =
        lang['expected-package-pickedup'] +
        (moment(expectedPickupTime).isValid()
          ? ' ' +
            moment(expectedPickupTime).format(lang['moment-short-datetime'])
          : '');
      label3 =
        lang['expected-package-delivered'] +
        (moment(expectedDeliverTime).isValid()
          ? ' ' +
            moment(expectedDeliverTime).format(lang['moment-short-datetime'])
          : '');
      ball1 = STATE.DONE;
      ball2 = STATE.ACTIVE;
      progressBarStage1 =
        (time - (courierAcceptTime || 0)) /
        ((expectedPickupTime || 0) - (courierAcceptTime || 0) + Number.EPSILON);
      break;
    case 'PACKAGE_PICKEDUP':
      label1 =
        lang['package-created'] +
        (moment(courierAcceptTime).isValid()
          ? ' ' +
            moment(courierAcceptTime).format(lang['moment-short-datetime'])
          : '');
      label2 =
        lang['package-pickedup'] +
        (moment(senderPickedUpTime).isValid()
          ? ' ' +
            moment(senderPickedUpTime).format(lang['moment-short-datetime'])
          : '');
      label3 =
        lang['expected-package-delivered'] +
        (moment(expectedDeliverTime).isValid()
          ? ' ' +
            moment(expectedDeliverTime).format(lang['moment-short-datetime'])
          : '');
      ball1 = STATE.DONE;
      ball2 = STATE.DONE;
      ball3 = STATE.ACTIVE;
      progressBarStage1 = 1;
      progressBarStage2 =
        (time - (senderPickedUpTime || 0)) /
        ((expectedDeliverTime || 0) -
          (senderPickedUpTime || 0) +
          Number.EPSILON);
      break;
    case 'PACKAGE_RECEIVED':
      label1 =
        lang['package-created'] +
        (moment(courierAcceptTime).isValid()
          ? ' ' +
            moment(courierAcceptTime).format(lang['moment-short-datetime'])
          : '');
      label2 =
        lang['package-pickedup'] +
        (moment(senderPickedUpTime).isValid()
          ? ' ' +
            moment(senderPickedUpTime).format(lang['moment-short-datetime'])
          : '');
      label3 =
        lang['package-delivered'] +
        (moment(deliveryTime).isValid()
          ? ' ' + moment(deliveryTime).format(lang['moment-short-datetime'])
          : '');
      ball1 = STATE.DONE;
      ball2 = STATE.DONE;
      ball3 = STATE.DONE;
      progressBarStage1 = 1;
      progressBarStage2 = 1;
      break;
  }

  if (progressBarStage1 < -0.0000001) {
    progressBarStage1 = 1;
  }
  if (progressBarStage2 < -0.0000001) {
    progressBarStage2 = 1;
  }

  return vertical ? (
    <Div>
      <Div theme="flex-row">
        {getBall(ball1)}
        <Bold style={verticalStyles.textLine(ball1)}>{label1}</Bold>
      </Div>
      <Div theme="flex-row">
        <Div style={verticalStyles.emptyLine(height)} />
        <Div style={verticalStyles.fullLine(height, progressBarStage1)} />
      </Div>
      <Div theme="flex-row">
        {getBall(ball2)}
        <Bold style={verticalStyles.textLine(ball2)}>{label2}</Bold>
      </Div>
      <Div theme="flex-row">
        <Div style={verticalStyles.emptyLine(height)} />
        <Div style={verticalStyles.fullLine(height, progressBarStage2)} />
      </Div>
      <Div theme="flex-row">
        {getBall(ball3)}
        <Bold style={verticalStyles.textLine(ball3)}>{label3}</Bold>
      </Div>
    </Div>
  ) : (
    <Div theme="flex-column">
      <Div theme="flex-row jc-center">
        <Div style={horizontalStyles.ball('#cccccc')} />
        <Div style={horizontalStyles.thinLine(width)} />
        <Div style={horizontalStyles.ball('#cccccc')} />
        <Div style={horizontalStyles.thinLine(width)} />
        <Div style={horizontalStyles.ball('#cccccc')} />
      </Div>
      <Div
        theme="flex-row"
        style={{
          position: 'absolute',
        }}>
        <Div
          style={horizontalStyles.ball(
            ball1 !== STATE.DONE ? '#cccccc' : COLORS.mainColorSender,
          )}
        />
        <Div style={horizontalStyles.thickLine(width, progressBarStage1)} />
        <Div style={horizontalStyles.hiddenLine(width, progressBarStage1)} />
        <Div
          style={horizontalStyles.ball(
            ball2 !== STATE.DONE ? '#cccccc' : COLORS.mainColorSender,
          )}
        />
        <Div style={horizontalStyles.thickLine(width, progressBarStage2)} />
        <Div style={horizontalStyles.hiddenLine(width, progressBarStage2)} />
        <Div
          style={horizontalStyles.ball(
            ball3 !== STATE.DONE ? '#cccccc' : COLORS.mainColorSender,
          )}
        />
      </Div>
    </Div>
  );

  function getBall(ball) {
    return (
      <Div style={verticalStyles.ball(ball)}>
        {ball === STATE.DONE ? (
          <ICCheck color="#fff" width={20} height={20} />
        ) : ball === STATE.ACTIVE ? (
          <Div style={verticalStyles.innerBall} />
        ) : null}
      </Div>
    );
  }
}

const verticalStyles = {
  ball: state => ({
    width: state === STATE.DISABLED ? 20 : 25,
    height: state === STATE.DISABLED ? 20 : 25,
    padding: state === STATE.DONE ? 2.5 : 0,
    marginHorizontal: state === STATE.DISABLED ? 2.5 : 0,
    borderRadius: 20,
    backgroundColor:
      state !== STATE.DISABLED ? COLORS.mainColorSender : '#cccccc',
  }),
  emptyLine: height => ({
    width: 3,
    height: height / 5,
    backgroundColor: '#cccccc',
    marginHorizontal: 11,
  }),
  fullLine: (height, process) => ({
    width: 5,
    height: (height / 5) * Math.min(process, 1),
    position: 'absolute',
    backgroundColor: COLORS.mainColorSender,
    marginHorizontal: 10,
  }),
  innerBall: {
    backgroundColor: '#cccccc',
    borderRadius: 10,
    height: 15,
    margin: 5,
    width: 15,
  },
  textLine: state => ({
    lineHeight: 17,
    marginHorizontal: 5,
    color: state !== STATE.DISABLED ? 'black' : '#cccccc',
  }),
};

const horizontalStyles = {
  ball: color => ({
    width: 8,
    height: 8,
    backgroundColor: color,
    borderRadius: 10,
  }),
  hiddenLine: (width, process) => ({
    width: (width / 2 - 15) * (1 - Math.min(process, 1)),
    height: 2,
  }),
  thickLine: (width, progress) => ({
    width: (width / 2 - 15) * Math.min(progress, 1),
    height: 2,
    marginVertical: 3,
    backgroundColor: COLORS.mainColorSender,
  }),
  thinLine: width => ({
    width: width / 2 - 15,
    height: 1,
    marginVertical: 3.5,
    backgroundColor: '#cccccc',
  }),
};

const mapStateToProps = state => ({
  height: get(state, 'window.height', 0),
});

export default connect(mapStateToProps)(ProgressBar);
