import React from 'react';
import {connect} from 'react-redux';
import {Div, Title} from './Components';
import {
  ICPurpleLoader,
  ICOrangeLoader,
  ICLoadingGradient,
  ICLoadingForCourier,
} from './Icons';
import {get} from 'lodash';
import {COLORS} from '../common/constants';

function Loader({loader, height, width, app}) {
  return (
    <>
      {loader === 'create-package' ? (
        <>
          <Div
            style={{
              width: '100%',
              height: '100%',
              top: 0,
              position: 'absolute',
            }}
          />
          <Div
            theme="flex-column jc-center ai-center"
            style={{
              ...styles.image,
              height,
            }}>
            <Div
              style={{
                height: height - 300,
                width: width - 80,
                borderRadius: 16,
                backgroundColor: '#fff',
              }}
            />
          </Div>
          <Div
            theme="flex-column jc-center ai-center"
            style={{...styles.image, height: height}}>
            <Div style={{}}>
              <ICLoadingGradient width={220} height={220} />
            </Div>
          </Div>
          <Div
            theme="flex-column jc-center ai-center"
            style={{...styles.image, height: height}}>
            <ICLoadingForCourier width={220} height={220} />
          </Div>
          <Div
            theme="flex-column jc-center ai-center"
            style={{...styles.image, height: height}}>
            <Title
              style={{
                marginTop: 272,
                fontWeight: 'bold',
                fontSize: 18,
              }}>
              posting-package
            </Title>
          </Div>
        </>
      ) : (
        <Div style={styles.background}>
          <Div style={{}}>
            {app === 'courier' ? (
              <ICOrangeLoader width={250} height={250} />
            ) : (
              <ICPurpleLoader width={250} height={250} />
            )}
          </Div>
        </Div>
      )}
    </>
  );
}

function LoaderAdaptor({loader, isVisible, ...props}) {
  return isVisible ? <Loader loader={loader} {...props} /> : null;
}
const styles = {
  background: {
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
    width: '100%',
  },
  button: {
    backgroundColor: 'white',
    paddingHorizontal: 10,
    paddingVertical: 0,
  },
  buttonText: {
    color: COLORS.black,
    fontSize: 20,
    fontWeight: 'bold',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  image: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  maxWidth: {
    overflow: 'hidden',
    width: '100%',
  },
};

const mapStateToProps = state => ({
  width: get(state, 'window.width', 0),
  height: get(state, 'window.height', 0),
  app: state.app,
  loader: state.loader,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoaderAdaptor);
