import React, {useContext} from 'react';
import {LanguageContext, flipDirection} from '../../language/lang';
import {COLORS} from '../../common/constants';
import {ThemeContext} from '../../themes/theme';

export default ({children, theme, noTranslate, style, id}) => {
  const lang = useContext(LanguageContext);
  const themeContext = useContext(ThemeContext);
  let color = COLORS.black;
  switch (theme || '') {
    case 'orange':
      color = COLORS.mainColorCourier;
      break;
    case 'purple':
      color = COLORS.mainColorSender;
      break;
    case 'green':
      color = COLORS.covidMainColor;
      break;
  }
  let text = children || '';
  if (!noTranslate && lang[text]) {
    text = lang[text];
  }
  let styles = {
    fontSize: 14,
    margin: 0,
    color: color,
    fontWeight: 'bold',
    textAlign: flipDirection(lang.id) ? 'right' : 'left',
    ...style,
  };
  (theme || '').split(' ').forEach(t => {
    if (themeContext[t]) {
      styles = {...styles, ...themeContext[t]};
    }
  });
  if (styles.flexDirection && flipDirection(lang.id)) {
    if (styles.flexDirection === 'row') {
      styles.flexDirection = 'row-reverse';
    } else if (styles.flexDirection === 'row-reverse') {
      styles.flexDirection = 'row';
    }
  }
  let css = styles;
  for (let key in css) {
    if (key === 'marginHorizontal') {
      css.marginLeft = css.marginHorizontal + 'px';
      css.marginRight = css.marginHorizontal + 'px';
    }
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }
  return (
    <p style={css} id={id}>
      {text}
    </p>
  );
};
