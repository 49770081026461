import React, {useContext} from 'react';
import {LanguageContext, LANGUAGES} from '../../language/lang';
import {COLORS} from '../../common/constants';
import {ThemeContext} from '../../themes/theme';

export default ({children, theme, noTranslate, style, ariaHidden, id}) => {
  const lang = useContext(LanguageContext);
  const themeContext = useContext(ThemeContext);
  let color = COLORS.black;
  switch (theme || '') {
    case 'orange':
      color = COLORS.mainColorCourier;
      break;
    case 'purple':
      color = COLORS.mainColorSender;
      break;
    case 'green':
      color = COLORS.covidMainColor;
      break;
  }
  let text = children || '';
  if (!noTranslate && lang[text]) {
    text = lang[text];
  }
  let styles = {
    fontSize: 14,
    lineHeight: 26,
    color: color,
    margin: 0,
    textAlign: lang.id === LANGUAGES.HE ? 'right' : 'left',
    ...style,
  };
  (theme || '').split(' ').forEach(t => {
    if (themeContext[t]) {
      styles = {...styles, ...themeContext[t]};
    }
  });

  let css = styles;
  for (let key in css) {
    if (key === 'marginHorizontal') {
      css.marginLeft = css[key];
      css.marginRight = css[key];
    }
  }
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }
  return (
    <p id={id} style={css} aria-hidden={ariaHidden}>
      {text}
    </p>
  );
};
