import React from 'react';
import {connect} from 'react-redux';
import Div from './Div';
import Loader from './Loader';
import Title from './Title';
import Button from './Button';

export const NotLoggenInMessage = ({
  user,
  userInProgress,
  app,
  redirect,
  navigation,
}) => {
  return !user || !user.uid ? (
    userInProgress ? (
      <Div theme="flex-row jc-center ai-center" style={{height: 300}}>
        <Loader />
      </Div>
    ) : (
      <Div style={{padding: 40}}>
        <Title style={{marginVertical: 10, color: 'grey'}}>
          you-are-logged-out
        </Title>
        <Button
          theme={(app === 'courier' ? 'orange' : 'purple') + ' empty'}
          onClick={() =>
            navigation.navigate(
              (app !== 'courier' ? 'sender' : 'courier') + '-login',
              redirect
                ? {
                    redirect,
                  }
                : {},
            )
          }>
          login
        </Button>
      </Div>
    )
  ) : null;
};

const mapStateToProps = state => ({
  app: state.app,
  user: state.user,
  userInProgress: state.userInProgress,
});

export default connect(mapStateToProps)(NotLoggenInMessage);
