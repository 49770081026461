import React, {useEffect} from 'react';

import {get} from 'lodash';

const WebView = ({style, key, onLoad, onError, onBambizzMessage, source}) => {
  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    return () => window.removeEventListener('message', onMessage);
  }, []);
  function onMessage(e) {
    if (
      get(e, 'data.id', '') === 'payment-success' &&
      typeof onBambizzMessage === 'function'
    ) {
      onBambizzMessage(e.data);
    }
  }
  return (
    <iframe
      key={key}
      style={{border: 'none', ...style}}
      onError={onError}
      onLoad={e => {
        if (typeof onLoad === 'function') {
          onLoad(e);
        }
      }}
      src={get(source, 'uri', '')}
    />
  );
};
export default WebView;
