import Storage from '../common/storage';

const SEC = 1000;
const MIN = SEC * 60;
const HOUR = MIN * 60;
const DAY = 24 * HOUR;

class Cache {
  constructor(name) {
    this.pointer = {};
    this.name = name;
    Storage.getItem('@' + name).then(res => {
      if (res) {
        this.pointer = JSON.parse(res);
      }
    });
  }

  getItem(key, lang) {
    let obj = this.pointer[key];
    if (obj && obj.lang === lang && Date.now() - obj.timeStamp < 10 * DAY) {
      return obj.object;
    }
    if (obj && Date.now() - obj.timeStamp > 10 * DAY) {
      delete this.pointer[key];
      Storage.setItem('@' + this.name, JSON.stringify(this.pointer));
    }
    return null;
  }

  cacheItem(key, lang, obj) {
    this.pointer[key] = {
      object: obj,
      timeStamp: Date.now(),
      lang,
    };
    if (Object.keys(this.pointer).length > 500) {
      let temp = {};
      Object.entries(this.pointer)
        .sort((a, b) => b[1].timeStamp - a[1].timeStamp)
        .forEach(ent => {
          temp[ent[0]] = ent[1];
        });
      this.pointer = temp;
    }
    Storage.setItem('@' + this.name, JSON.stringify(this.pointer));
  }
}

export default Cache;
