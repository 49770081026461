import {get} from 'lodash';
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {connect, useDispatch} from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Analytics from '../common/analytics';
import useDebug from '../debug/useDebug';
import {LanguageContext} from '../language/lang';
import {setKeyboardState} from '../redux/actions';
import {initializeSender} from '../redux/senderActions';
import {Div, Loader} from './Components';
import MenuBarDrawer from './senderApp/MenuBarDrawer';
import {setSEO} from '../common/nativeFunctions';
import AllChats from './AllChats';
import OrderChat from './OrderChat';
import FAQPage from './FAQPage';
import InputDetailsDataManager from './senderApp/InputDetailsDataManager';
import {COLORS} from '../common/constants';

const AboutUs = lazy(() => import('./AboutUs'));
const ContactUs = lazy(() => import('./ContactUs'));
const NotificationsList = lazy(() => import('./NotificationsList'));
const Billing = lazy(() => import('./senderApp/Billing'));
const Login = lazy(() => import('./senderApp/Login'));
const OrdersList = lazy(() => import('./senderApp/OrdersList'));
const OrderView = lazy(() => import('./senderApp/OrderView'));
const PointsOverview = lazy(() => import('./senderApp/PointsOverview'));
const OrderPackageFlow1 = lazy(() => import('./senderApp/OrderPackageFlow1'));
const OrderPackageFlow2 = lazy(() => import('./senderApp/OrderPackageFlow2'));
const OrderPackageFlow3 = lazy(() => import('./senderApp/OrderPackageFlow3'));
const OrderPackageFlow4 = lazy(() => import('./senderApp/OrderPackageFlow4'));
const OrderPackageFlow5 = lazy(() => import('./senderApp/OrderPackageFlow5'));
const Terms = lazy(() => import('./Terms'));
const Settings = lazy(() => import('./senderApp/Settings'));

const NotificationsListComponent = props => {
  const navigateTo = useNavigate();
  return (
    <NotificationsList
      {...props}
      onClose={navigate =>
        navigate ? navigateTo('/sender-input-order-1') : null
      }
    />
  );
};

const OrdersListComponent = props => {
  const navigateTo = useNavigate();
  return (
    <OrdersList
      {...props}
      onClose={() => navigateTo('/sender-input-order-1')}
    />
  );
};

const OrderViewComponent = props => {
  const {id} = Object.fromEntries(new URLSearchParams(window.location.search));

  return <OrderView {...props} id={id} />;
};

const OrderChatComponent = props => {
  const {uid, orderId} = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  return <OrderChat {...props} uid={uid} orderId={orderId} />;
};

const AllChatsComponent = props => {
  return <AllChats {...props} />;
};

const LoginComponent = props => {
  const {redirect} = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  return <Login {...props} redirect={redirect} />;
};

function SenderApp({width, height}) {
  useDebug('SenderApp');
  const navigateTo = useNavigate();
  let location = useLocation();
  const lang = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [openSideMenu, setopenSideMenu] = useState(false);
  const listeners = useRef([]);
  const navigation = useMemo(
    () => ({
      navigate: (path, params) => {
        if (params && params.redirectParams) {
          delete params.redirectParams;
        }
        const searchParams = new URLSearchParams(params || {});
        const query = searchParams.toString();
        navigateTo('/' + path + (query ? '?' + query : ''));
      },
      openDrawer: () => setopenSideMenu(true),
      closeDrawer: () => setopenSideMenu(false),
      goBack: () => navigateTo(-1),
      addListener: (_, func) => {
        listeners.current = [...(listeners.current || []), func];
      },
    }),
    [navigateTo, setopenSideMenu, listeners],
  );
  useEffect(() => {
    dispatch(initializeSender(navigation));
    document.body.addEventListener('focus', onFocus, true);
    document.body.addEventListener('blur', onBlur, true);
    return () => {
      document.body.removeEventListener('focus', onFocus);
      document.body.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    try {
      const pathname = location?.pathname || '';
      Analytics.reportPage(pathname.substr(1));
      setSEO(pathname, lang);

      const event = {
        data: {
          state: {
            routes: [{name: pathname, params: {}}],
            index: 0,
          },
        },
      };
      listeners.current.forEach(func => func(event));

      window.parent.postMessage(
        {id: 'page-change', path: location?.pathname},
        '*',
      );
      document.getElementById(
        'desktop-canonical',
      ).href = `https://bambizz.com/${lang.id.toLowerCase()}/application${
        location?.pathname
      }`;
    } catch (e) {}
  }, [location]);

  function onFocus(event) {
    const target = event.target;
    switch (target.tagName) {
      case 'INPUT':
      case 'TEXTAREA':
      case 'SELECT':
        dispatch(setKeyboardState(true));
    }
  }

  function onBlur() {
    dispatch(setKeyboardState(false));
  }

  useEffect(() => {
    if (width > 800) {
      window.location.replace('https://bambizz.com/he/application/');
    }
  }, [width]);

  return (
    <Suspense
      fallback={
        <Div theme="flex-column jc-center ai-center" style={{width, height}}>
          <Loader />
        </Div>
      }>
      {openSideMenu ? (
        <div
          id="side-menu-bg"
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            backgroundColor: '#000000aa',
            display: 'flex',
            width: '100%',
            height: '100%',
            zIndex: 10,
          }}
          onClick={() => setopenSideMenu(false)}>
          <div
            style={{
              width: '300px',
              backgroundColor: COLORS.white,
            }}>
            <MenuBarDrawer navigationRef={{current: navigation}} />
          </div>
        </div>
      ) : null}
      <InputDetailsDataManager />
      <Routes>
        <Route
          path="/sender-about-us"
          element={<AboutUs navigation={navigation} />}
        />

        <Route
          path="/sender-terms-and-conditions"
          element={<Terms navigation={navigation} />}
        />

        <Route
          path="/sender-contact-us"
          element={<ContactUs navigation={navigation} />}
        />

        <Route
          path="/settings"
          element={<Settings navigation={navigation} />}
        />

        <Route
          path="/sender-notifications"
          element={<NotificationsListComponent navigation={navigation} />}
        />

        <Route
          path="/sender-my-orders"
          element={<OrdersListComponent navigation={navigation} />}
        />

        <Route
          path="/sender-points-overview"
          element={<PointsOverview navigation={navigation} />}
        />
        <Route
          path="/sender-billing"
          element={
            <Billing
              // eslint-disable-next-line no-undef
              navigation={{navigate: path => history.push('/' + path)}}
            />
          }
        />

        <Route
          path="/sender-login"
          element={<LoginComponent navigation={navigation} />}
        />
        <Route
          path="/sender-login"
          element={<LoginComponent navigation={navigation} />}
        />
        <Route
          path="/sender-order-view"
          element={<OrderViewComponent navigation={navigation} />}
        />
        <Route
          path="/sender-chat-view"
          element={<OrderChatComponent navigation={navigation} />}
        />
        <Route
          path="/sender-chat-view"
          element={<OrderChatComponent navigation={navigation} />}
        />
        <Route
          path="/sender-chat-list"
          element={<AllChatsComponent navigation={navigation} />}
        />
        <Route
          path="/sender-faq"
          element={<FAQPage navigation={navigation} />}
        />
        <Route
          path="/sender-input-order-1"
          element={<OrderPackageFlow1 navigation={navigation} />}
        />
        <Route
          path="/sender-input-order-2"
          element={<OrderPackageFlow2 navigation={navigation} />}
        />
        <Route
          path="/sender-input-order-3"
          element={<OrderPackageFlow3 navigation={navigation} />}
        />
        <Route
          path="/sender-input-order-4"
          element={<OrderPackageFlow4 navigation={navigation} />}
        />
        <Route
          path="/sender-input-order-5"
          element={<OrderPackageFlow5 navigation={navigation} />}
        />
        <Route
          path="/sender-input-order"
          element={<Navigate replace to="/sender-input-order-1" />}
        />
        <Route
          exact
          path="/"
          element={<Navigate replace re to="/sender-input-order-1" />}
        />
        <Route
          path="*"
          element={<Navigate replace to="/sender-input-order-1" />}
        />
      </Routes>
    </Suspense>
  );
}

const mapStateToProps = state => ({
  width: get(state, 'window.width', 0),
  leftOffset: get(state, 'window.leftOffset', 0),
  rightOffset: get(state, 'window.rightOffset', 0),
  height: get(state, 'window.height', 0),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SenderApp);
