import React, {useEffect} from 'react';
export {Send} from 'react-gifted-chat-updated';
import {GiftedChat as OriginGiftedChat} from 'react-gifted-chat-updated';

export const GiftedChat = props => {
  useEffect(() => {
    try {
      document.getElementById('chat-script').innerText = `
    .gifted-chat-container * {
      direction: ltr;
    }
    `;
    } catch (e) {}
  });
  return (
    <div
      className="gifted-chat-container"
      style={{height: 'calc(100% - 130px)'}}>
      <style id="chat-script" />
      <OriginGiftedChat {...props} />
    </div>
  );
};
