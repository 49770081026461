import React from 'react';
import {connect} from 'react-redux';
import {IconButton, Title, Div, Shadow} from './Components';
import {COLORS} from '../common/constants';
import {get} from 'lodash';
import {ICMenu} from './Icons';
import {hideKeyboard, Platform} from '../common/nativeFunctions';
import {useId} from './hooks/useId';

function MenuBarNew({
  navigation,
  shadow,
  iconLeft,
  iconLeftId,
  iconLeftTitle,
  onActionLeft,
  iconRight,
  iconRightId,
  iconRightTitle,
  onActionRight,
  title,
  app,
  navIcon,
  navIconId,
  navIconTitle,
  navIconDisabled,
  onTitleClick,
  onNavIcon,
}) {
  const titleId = useId();
  let mainColor = '#000';
  if (app === 'sender') {
    mainColor = COLORS.mainColorSender;
  }
  if (app === 'courier') {
    mainColor = COLORS.mainColorCourier;
  }

  return (
    <>
      {/*<Div style={{
      borderBottomWidth: 0.4,
      borderBottomColor: 'black',
      width,
      top: 30
    }} />*/}
      <Div
        theme="flex-row jc-start ai-center bg-main-color"
        style={{height: 56, backgroundColor: mainColor}}>
        <IconButton title="menu" onClick={toggleDrawer} style={{padding: 0}}>
          <ICMenu width={56} height={30} color={COLORS.white} />
        </IconButton>
        {iconRight ? (
          <IconButton
            labelledBy={iconRightId}
            title={iconRightTitle}
            style={{width: 50, flex: 1}}
            onClick={() =>
              typeof onActionRight === 'function' ? onActionRight() : null
            }>
            <Div style={{width: 30}} theme="flex-row jc-center">
              {iconRight}
            </Div>
          </IconButton>
        ) : null}
        {typeof onTitleClick === 'function' ? (
          <IconButton labelledBy={titleId} title={title} onClick={onTitleClick}>
            <Title
              id={titleId}
              numberOfLines={1}
              style={styles.title(mainColor)}>
              {title}
            </Title>
          </IconButton>
        ) : (
          <Title numberOfLines={1} style={styles.title(mainColor)}>
            {title}
          </Title>
        )}
        {iconLeft ? (
          <IconButton
            labelledBy={iconLeftId}
            style={{flex: 3}}
            title={iconLeftTitle}
            onClick={() =>
              typeof onActionLeft === 'function' ? onActionLeft() : null
            }>
            {iconLeft}
          </IconButton>
        ) : null}
        {navIcon ? (
          <IconButton
            labelledBy={navIconId}
            disabled={navIconDisabled}
            style={{flex: 1}}
            title={navIconTitle}
            onClick={() =>
              typeof onNavIcon === 'function' ? onNavIcon() : null
            }>
            {navIcon}
          </IconButton>
        ) : null}
      </Div>
      {shadow ? <Shadow /> : null}
    </>
  );
  function toggleDrawer() {
    if (Platform.OS === 'ios') {
      hideKeyboard();
    }
    try {
      navigation.openDrawer();
    } catch (e) {
      console.log(e);
    }
  }
}

const styles = {
  shadow: {height: 4},
  title: () => ({
    lineHeight: 20,
    fontSize: 18,
    margin: 8,
    color: COLORS.white,
  }),
};

const mapStateToProps = state => ({
  height: get(state, 'window.height', 0),
  leftOffset: get(state, 'window.leftOffset', 0),
  navigation: state.navigation,
  app: state.app,
});

export default connect(mapStateToProps)(MenuBarNew);
