import {getUniqueDviceId} from './getUniqueId';
import {getAppBuildNumber, getAppVersion} from './nativeFunctions';

export const CONSTANTS = {
  version: getAppVersion(),
  buildNum: getAppBuildNumber(),
  captcha: '6LeA4uQUAAAAAFdBK7qSEaMbvKefCvsFtjbgJllF',
  deviceID: 'loading',
};

(async () => {
  CONSTANTS.deviceID = await getUniqueDviceId();
})().catch(console.log);

export const PHONE_REGEX =
  /(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?)|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})/;
export const MESSAGE_PUB_KEY =
  'BG9fA2xra0HMtOrPTQP8MdZ2U3NAhkXP-dSMhOmPFQfWnm1zlmxMBgjS0rKEMWkfKRLGKI4V3C7K6PAlB4WMrv0';

export const MESSAGE_ACTIONS = {
  ADD_OFFER: 'ADD_OFFER',
  COURIER_CANCEL_OFFER: 'COURIER_CANCEL_OFFER',
  CONFIRM_DELIVERED_COURIER: 'CONFIRM_DELIVERED_COURIER',
  COURIER_ADD_REVIEW: 'COURIER_ADD_REVIEW',
  ACCEPT_OFFER_SENDER: 'ACCEPT_OFFER_SENDER',
  SHOW_NEW_ORDER_COURIER: 'SHOW_NEW_ORDER_COURIER',
  ACCEPT_PICKUP_SENDER: 'ACCEPT_PICKUP_SENDER',
  CONFIRM_DELIVERY_SENDER: 'CONFIRM_DELIVERY_SENDER',
  SENDER_ADD_REVIEW: 'SENDER_ADD_REVIEW',
  GENERAL_MESSAGE: 'GENERAL_MESSAGE',
};

export const ACTIONS = {
  SAVE_COURIER_LOCATION: 'SAVE_COURIER_LOCATION',
  SET_KEYBOARDACCESS_BUTTONS: 'SET_KEYBOARDACCESS_BUTTONS',
  SENDER_SAVE_PRICES: 'SENDER_SAVE_PRICES',
  SET_SHOW_CC: 'SET_SHOW_CC',
  SET_COURIER_ORDERS_LOADING: 'SET_COURIER_ORDERS_LOADING',
  COURIER_GET_ORDERS: 'COURIER_GET_ORDERS',
  COURIER_GET_ALL_OFFERS: 'COURIER_GET_ALL_OFFERS',
  COURIER_SEND_OFFER: 'COURIER_SEND_OFFER',
  COURIER_CANCEL_OFFER: 'COURIER_CANCEL_OFFER',
  COURIER_GET_CONFIRMED_ORDERS: 'COURIER_GET_CONFIRMED_ORDERS',
  COURIER_GET_ORDER: 'COURIER_GET_ORDER',
  COURIER_CONFIRM_DELIVERY: 'COURIER_CONFIRM_DELIVERY',
  COURIER_ACCEPT_ORDER: 'COURIER_ACCEPT_ORDER',
  COURIER_GET_PACKAGE: 'COURIER_GET_PACKAGE',
  COURIER_REMOVE_ORDER: 'COURIER_REMOVE_ORDER',
  COURIER_CONFIRM_PICKUP: 'COURIER_CONFIRM_PICKUP',
  COURIER_SET_USER_DATA: 'COURIER_SET_USER_DATA',
  COURIER_SET_ORDER_STATUSES: 'COURIER_SET_ORDER_STATUSES',
  COURIER_GET_DATA: 'COURIER_GET_DATA',
  COURIER_GET_ALL_PAYMENTS: 'COURIER_GET_ALL_PAYMENTS',
  COURIER_SET_USER_DATA_QUIET: 'COURIER_SET_USER_DATA_QUIET',
  COURIER_ACCEPT_ORDERS_GROUP: 'COURIER_ACCEPT_ORDERS_GROUP',
  COURIER_CANCEL_ORDER: 'COURIER_CANCEL_ORDER',

  SENDER_GET_ALL_OFFERS: 'SENDER_GET_ALL_OFFERS',
  SENDER_ACCEPT_OFFER: 'SENDER_ACCEPT_OFFER',
  SENDER_SEND_REVIEW: 'SENDER_SEND_REVIEW',
  SENDER_CONFIRM_PICKUP: 'SENDER_CONFIRM_PICKUP',
  SENDER_REPORT_ABUSE: 'SENDER_REPORT_ABUSE',
  SENDER_GET_DATA: 'SENDER_GET_DATA',
  SENDER_GET_PACKAGE: 'SENDER_GET_PACKAGE',
  SENDER_ADD_ORDER: 'SENDER_ADD_ORDER',
  SENDER_CANCEL_ORDER: 'SENDER_CANCEL_ORDER',
  SENDER_GET_ORDERS: 'SENDER_GET_ORDERS',
  SHOW_SENDER_ORDERS_MODAL: 'SHOW_SENDER_ORDERS_MODAL',

  SAVE_CHATS: 'SAVE_CHATS',
  KEYBOARD_OPEN: 'KEYBOARD_OPEN',
  GOOGLE_MAPS_LOADED: 'GOOGLE_MAPS_LOADED',
  SET_USER_IN_PROGRESS: 'SET_USER_IN_PROGRESS',
  ADD_SENDER_MESSAGES: 'ADD_SENDER_MESSAGES',
  REFFERD_BY_USER: 'REFFERD_BY_USER',
  SAVE_SHARE_LINK: 'SAVE_SHARE_LINK',
  SET_ORDERS_GROUP_MODAL: 'SET_ORDERS_GROUP_MODAL',
  SET_SELECT_MODAL_CONTENT: 'SET_SELECT_MODAL_CONTENT',
  SET_FILTER_CONTENT: 'SET_FILTER_CONTENT',
  SET_WEBVIEW_CONTENT: 'SET_WEBVIEW_CONTENT',
  SET_AUTOCOMPLETE_CONTENT: 'SET_AUTOCOMPLETE_CONTENT',
  SET_AUTOCOMPLETE_BANK_CONTENT: 'SET_AUTOCOMPLETE_BANK_CONTENT',
  SET_AUTOCOMPLETE_LANG_CONTENT: 'SET_AUTOCOMPLETE_LANG_CONTENT',
  SET_PAYMENT: 'SET_PAYMENT',
  SET_LANG: 'SET_LANG',
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_MODAL: 'OPEN_MODAL',
  SET_USER: 'SET_USER',
  HIDE_LOADER: 'HIDE_LOADER',
  SHOW_LOADER: 'SHOW_LOADER',
  SAVE_PACKAGE_FIELDS: 'SAVE_PACKAGE_FIELDS',
  USER_LOGOUT: 'USER_LOGOUT',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  SEND_CONTACT_US: 'SEND_CONTACT_US',
  SET_USER_DATA: 'SET_USER_DATA',
  SAVE_CONFIRMATION_RES: 'SAVE_CONFIRMATION_RES',
  LOCATION_TIME: 'LOCATION_TIME',
  SET_SERVER_CONSTANTS: 'SET_SERVER_CONSTANTS',
  RESTORE_SAVED_STATE: 'RESTORE_SAVED_STATE',
  SET_SCREEN_ORIENTATION: 'SET_SCREEN_ORIENTATION',
  SET_WINDOW_SIZE: 'SET_WINDOW_SIZE',
  SET_APP: 'SET_APP',
  SAVE_CREDIT_CARD: 'SAVE_CREDIT_CARD',
  VALIDATE_CREDIT_CARD: 'VALIDATE_CREDIT_CARD',
  DELETE_CREDIT_CARD: 'DELETE_CREDIT_CARD',
  SAVE_CARD_LOCAL: 'SAVE_CARD_LOCAL',
  SAVE_CURRENT_NAVIGATION_PAGE: 'SAVE_CURRENT_NAVIGATION_PAGE',
  SAVE_NAVIGATION: 'SAVE_NAVIGATION',
  SET_QUICK_ORDER_VIEW: 'SET_QUICK_ORDER_VIEW',
  SET_ORDER_IDS_MODAL: 'SET_ORDER_IDS_MODAL',
  SET_TEMP_USER_DATA: 'SET_TEMP_USER_DATA',
  CLEAR_NETWORK_CALL_QUEUE: 'CLEAR_NETWORK_CALL_QUEUE',
  ADD_NETWORK_CALL: 'ADD_NETWORK_CALL',
  INITIAL_URL_NAVIGATED: 'INITIAL_URL_NAVIGATED',
  HIDE_WARNING_BEFORE_ACCEPTING_ORDER: 'HIDE_WARNING_BEFORE_ACCEPTING_ORDER',
  NONE: 'NONE',
  DELETE_USER: 'DELETE_USER',
  SET_GOOGLE_SDK: 'SET_GOOGLE_SDK',
};

export const COLORS = {
  bgVolunteerColor: '#f7c2fc',
  volunteerColor: '#e342f5',
  bgColorCourier: '#FFEDE5',
  bgColorSender: '#c4f4ff',
  mainColorSender: '#36BBDA',
  red: '#f54b2a',
  mainColorCourier: '#ff9e71',
  filledButtonText: '#ffffff',
  lightBorder: '#D8D9DE',
  inputText: '#393B45',
  status: '#12E5DC',
  disabled: '#cacaca',
  senderBackground: '#f7e0ff',
  courierBackground: '#fff6e6',
  backgroundColor: '#FCFCFC',
  covidMainColor: 'rgb(85, 233, 226)',
  labelRed: '#ffb3ba',
  labelGreen: '#ffdfba',
  labelBlue: '#ffffba',
  labelOrange: '#baffc9',
  labelYellow: '#bae1ff',
  labelGrey: '#f4f4f4',
  black: '#333',
  grey: '#555',
  white: '#ffffff',
  explainGrey: '#888',
};
