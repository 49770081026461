import {get} from 'lodash';
import {apiRequest, makeApiCall, showMessage} from '../common/function';
import {ACTIONS, CONSTANTS} from '../common/constants';
import Storage from '../common/storage';
import {runFunction} from '../debug/useDebug';
import {
  getSenderData,
  senderGetAllOffers,
  senderGetAllPackages,
  senderGetPackage,
  senderGetUserData,
} from './senderActions';
import {
  courierGetAllOffers,
  courierGetAllPackages,
  courierGetOrder,
  courierGetOrderGroup,
  courierGetOrders,
  courierGetPackage,
  courierGetUserData,
  courierRemoveOrder,
  getCourierData,
} from './courierActions';
import Analytics from '../common/analytics';
import {
  confirmAuthSMSCode,
  getCurrentFCMToken,
  getRealTimeDB,
  onAuthStateChanged,
  onFCMTokenRefresh,
  registerDeviceForFCM,
  setAuthForIOSEmulator,
  signInWithCustomToken,
  signInWithPhoneNumber,
  signOut,
} from '../common/firebase';
import {
  addAppStateChangeListener,
  addNetworkChangeListener,
  getCurrentAppState,
  initializeGoogleMapSDK,
  listenToGoogleSDK,
  openInStore,
  Platform,
  registerForGlobalErrors,
  reloadApp,
  removeAppStateChangeListener,
} from '../common/nativeFunctions';

/**********GENERAL ACTIONS**********/

export const onNetworkStateChange = state => {
  return async (dispatch, getState) => {
    runFunction('onNetworkStateChange');
    try {
      if (state.isInternetReachable) {
        let networkCallQueue = [...(getState().networkCallQueue || [])];
        dispatch({
          type: ACTIONS.CLEAR_NETWORK_CALL_QUEUE,
        });
        setTimeout(() => {
          networkCallQueue.forEach(networkCall => {
            try {
              dispatch(networkCall.func(...networkCall.params));
            } catch (e) {
              console.log(e);
            }
          });
        }, 1000);
      }
    } catch (e) {}
  };
};

export const restoreSaveState = () => {
  return async dispatch => {
    runFunction('restoreSaveState');
    try {
      let state = await Storage.getItem('@saved-state');
      let parsedState = JSON.parse(state);
      dispatch({
        type: ACTIONS.RESTORE_SAVED_STATE,
        payload: state ? parsedState : {},
      });
    } catch (e) {}
  };
};

export const saveCurrentNavigationPage = (page, id) => {
  return async dispatch => {
    runFunction('saveCurrentNavigationPage');
    try {
      dispatch({
        type: ACTIONS.SAVE_CURRENT_NAVIGATION_PAGE,
        payload: {page, id},
      });
    } catch (e) {}
  };
};

export const showQuickShowOrder = id => {
  return async (dispatch, getState) => {
    runFunction('showQuickShowOrder');
    if (id) {
      Analytics.reportCourierOrderInteraction(
        'showQuickShowOrder',
        id,
        getState()?.user?.uid || '',
      );
    }
    try {
      dispatch({
        type: ACTIONS.SET_QUICK_ORDER_VIEW,
        payload: id,
      });
    } catch (e) {}
  };
};

export const saveShareLink = link => {
  return async dispatch => {
    runFunction('saveShareLink');
    try {
      dispatch({
        type: ACTIONS.SAVE_SHARE_LINK,
        payload: link,
      });
    } catch (e) {}
  };
};

export const hideQuickShowOrder = () => {
  return async dispatch => {
    runFunction('showQuickShowOrder');
    try {
      dispatch({
        type: ACTIONS.SET_QUICK_ORDER_VIEW,
        payload: null,
      });
    } catch (e) {}
  };
};

export const showOrderIdsModal = ids => {
  return async dispatch => {
    runFunction('showOrderIdsModal');
    try {
      dispatch({
        type: ACTIONS.SET_ORDER_IDS_MODAL,
        payload: ids,
      });
    } catch (e) {}
  };
};

export const setCourierOrdersLoading = (loading = false) => {
  return async dispatch => {
    runFunction('setCourierOrdersLoading');
    try {
      dispatch({
        type: ACTIONS.SET_COURIER_ORDERS_LOADING,
        payload: loading,
      });
    } catch (e) {}
  };
};

export const hideOrderIdsModal = () => {
  return async dispatch => {
    runFunction('hideOrderIdsModal');
    try {
      dispatch({
        type: ACTIONS.SET_ORDER_IDS_MODAL,
        payload: null,
      });
    } catch (e) {}
  };
};

export const showOrderGroupModal = id => {
  return async dispatch => {
    runFunction('showOrderGroupModal');
    try {
      dispatch({
        type: ACTIONS.SET_ORDERS_GROUP_MODAL,
        payload: id,
      });
    } catch (e) {}
  };
};

export const hideOrderGroupModal = () => {
  return async dispatch => {
    runFunction('hideOrderGroupModal');
    try {
      dispatch({
        type: ACTIONS.SET_ORDERS_GROUP_MODAL,
        payload: null,
      });
    } catch (e) {}
  };
};

export const showSenderOrdersModal = () => {
  return async dispatch => {
    runFunction('showSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SHOW_SENDER_ORDERS_MODAL,
        payload: true,
      });
    } catch (e) {}
  };
};

export const hideSenderOrdersModal = () => {
  return async dispatch => {
    runFunction('hideSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SHOW_SENDER_ORDERS_MODAL,
        payload: false,
      });
    } catch (e) {}
  };
};

export const showAutoComplete = (onChange, address, type, error) => {
  return async dispatch => {
    runFunction('showSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SET_AUTOCOMPLETE_CONTENT,
        payload: {onChange, address, type, error},
      });
    } catch (e) {}
  };
};

export const hideAutoComplete = () => {
  return async dispatch => {
    runFunction('hideSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SET_AUTOCOMPLETE_CONTENT,
        payload: null,
      });
    } catch (e) {}
  };
};

export const showAutoCompleteBank = (onChange, branch, bankCode, error) => {
  return async dispatch => {
    runFunction('showAutoCompleteBank');
    try {
      dispatch({
        type: ACTIONS.SET_AUTOCOMPLETE_BANK_CONTENT,
        payload: {onChange, branch, bankCode, error},
      });
    } catch (e) {}
  };
};

export const hideAutoCompleteBank = () => {
  return async dispatch => {
    runFunction('hideSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SET_AUTOCOMPLETE_BANK_CONTENT,
        payload: null,
      });
    } catch (e) {}
  };
};

export const showAutoCompleteLang = () => {
  return async dispatch => {
    runFunction('showAutoCompleteLang');
    try {
      dispatch({
        type: ACTIONS.SET_AUTOCOMPLETE_LANG_CONTENT,
        payload: {},
      });
    } catch (e) {}
  };
};

export const hideAutoCompleteLang = () => {
  return async dispatch => {
    runFunction('hideAutoCompleteLang');
    try {
      dispatch({
        type: ACTIONS.SET_AUTOCOMPLETE_LANG_CONTENT,
        payload: null,
      });
    } catch (e) {}
  };
};

export const showWebView = (url, title) => {
  return async dispatch => {
    runFunction('showSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SET_WEBVIEW_CONTENT,
        payload: {url, title},
      });
    } catch (e) {}
  };
};

export const hideWebView = () => {
  return async dispatch => {
    runFunction('hideSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SET_WEBVIEW_CONTENT,
        payload: null,
      });
    } catch (e) {}
  };
};

export const showFilter = (filters, onChange) => {
  return async dispatch => {
    runFunction('showSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SET_FILTER_CONTENT,
        payload: {filters, onChange},
      });
    } catch (e) {}
  };
};

export const hideFilter = () => {
  return async dispatch => {
    runFunction('hideSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SET_FILTER_CONTENT,
        payload: null,
      });
    } catch (e) {}
  };
};

export const showSelectModal = (list, onSelect) => {
  return async dispatch => {
    runFunction('showSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SET_SELECT_MODAL_CONTENT,
        payload: {list, onSelect},
      });
    } catch (e) {}
  };
};

export const hideSelectModal = () => {
  return async dispatch => {
    runFunction('hideSenderOrdersModal');
    try {
      dispatch({
        type: ACTIONS.SET_SELECT_MODAL_CONTENT,
        payload: null,
      });
    } catch (e) {}
  };
};

export const setLanguage = lang => {
  return async (dispatch, getState) => {
    runFunction('setLanguage');
    Storage.setItem('@language', lang).catch(console.log);

    if (get(getState(), 'user.uid', '')) {
      getRealTimeDB()
        .ref('language/' + get(getState(), 'user.uid', ''))
        .set(lang)
        .catch(console.log);
    }

    dispatch({
      type: ACTIONS.SET_LANG,
      payload: lang,
    });
  };
};

export const saveCardLocal = action => {
  return async dispatch => {
    runFunction('saveCardLocal');
    dispatch({
      type: ACTIONS.SAVE_CARD_LOCAL,
      payload: action,
    });
  };
};

export const setApp = action => {
  return async dispatch => {
    runFunction('setApp');
    dispatch({
      type: ACTIONS.SET_APP,
      payload: action,
    });
  };
};

export const reloadToApp = action => {
  return async () => {
    runFunction('reloadToApp');
    await Storage.setItem('@appState', action);
    reloadApp('reloadToApp');
  };
};

export const savePackageFields = fields => {
  return async dispatch => {
    runFunction('savePackageFields');
    dispatch({
      type: ACTIONS.SAVE_PACKAGE_FIELDS,
      payload: fields,
    });
  };
};

export const saveAvailablePrices = prices => {
  return async dispatch => {
    runFunction('saveAvailablePrices');
    dispatch({
      type: ACTIONS.SENDER_SAVE_PRICES,
      payload: prices,
    });
  };
};

export const setWindowSize = (window, screen) => {
  return async dispatch => {
    runFunction('setWindowSize');
    dispatch({
      type: ACTIONS.SET_WINDOW_SIZE,
      payload: {window, screen},
    });
  };
};

export const setKeyboardAccessButtons = element => {
  return async dispatch => {
    runFunction('setKeyboardAccessButtons');
    dispatch({
      type: ACTIONS.SET_KEYBOARDACCESS_BUTTONS,
      payload: element,
    });
  };
};

export const setScreenOrientation = orientation => {
  return async dispatch => {
    runFunction('setScreenOrientation');
    dispatch({
      type: ACTIONS.SET_SCREEN_ORIENTATION,
      payload: orientation,
    });
  };
};

export const setKeyboardState = state => {
  return async dispatch => {
    runFunction('setKeyboardState');
    dispatch({
      type: ACTIONS.KEYBOARD_OPEN,
      payload: state || false,
    });
  };
};

export const setCreditCard = state => {
  return async dispatch => {
    runFunction('setCreditCard');
    dispatch({
      type: ACTIONS.SET_SHOW_CC,
      payload: state || false,
    });
  };
};

export const openModal = conf => {
  return async dispatch => {
    runFunction('openModal');
    dispatch({
      type: ACTIONS.OPEN_MODAL,
      payload: conf,
    });
  };
};

export const closeModal = () => {
  return async dispatch => {
    runFunction('closeModal');
    dispatch({
      type: ACTIONS.CLOSE_MODAL,
    });
  };
};

export const showLoader = theme => {
  return async dispatch => {
    runFunction('showLoader');
    dispatch({
      type: ACTIONS.SHOW_LOADER,
      payload: theme,
    });
  };
};

export const hideLoader = () => {
  return async dispatch => {
    runFunction('hideLoader');
    dispatch({
      type: ACTIONS.HIDE_LOADER,
    });
  };
};

const allMessagesNavigated = async (messages, uid) => {
  await Promise.all(
    messages.map(message =>
      getRealTimeDB()
        .ref('messages/' + uid + '/' + message.id + '/navigated')
        .set(true),
    ),
  );
};

export const addNotifications = (notification, user) => {
  return async (dispatch, getState) => {
    runFunction('addNotifications');
    try {
      let unread = (notification || []).filter(m => m.status === 'UNREAD');
      let toNavigate = [];
      let toSave = [];
      let promises = [];
      let courierOrderMessage = [];
      let courierOrderGroupMessage = [];
      unread.forEach(message =>
        promises.push(
          (async () => {
            await getRealTimeDB()
              .ref(
                'messages/' +
                  get(user, 'uid', '') +
                  '/' +
                  message.id +
                  '/status',
              )
              .set('READ');
            (get(message, 'actions', []) || []).forEach(action => {
              try {
                switch (action.func) {
                  case 'senderGetUserData':
                    dispatch(senderGetUserData(action.payload));
                    break;
                  case 'courierGetAllPackages':
                    dispatch(courierGetAllPackages(action.payload));
                    break;
                  case 'courierGetUserData':
                    dispatch(courierGetUserData(action.payload));
                    break;
                  case 'courierGetOrders':
                    dispatch(courierGetOrders(action.payload));
                    break;
                  case 'courierRemoveOrder':
                    dispatch(courierRemoveOrder(action.payload));
                    break;
                  case 'courierGetOrder':
                    dispatch(courierGetOrder(action.payload));
                    break;
                  case 'courierGetAllOffers':
                    dispatch(courierGetAllOffers(action.payload));
                    break;
                  case 'senderGetAllOffers':
                    dispatch(senderGetAllOffers(action.payload));
                    break;
                  case 'senderGetAllPackages':
                    dispatch(senderGetAllPackages(action.payload));
                    break;
                  case 'getSenderData':
                    dispatch(getSenderData(action.payload));
                    break;
                  case 'senderGetPackage':
                    dispatch(senderGetPackage(action.payload));
                    break;
                  case 'courierGetPackage':
                    dispatch(courierGetPackage(action.payload));
                    break;
                }
              } catch (err) {
                console.log(err);
              }
            });
          })(),
        ),
      );
      await Promise.all(promises);
      let oldMessages = get(getState(), 'notifications', []) || [];
      oldMessages = oldMessages.filter(message =>
        notification.every(not => not.id !== message.id),
      );
      let allMessages = [...oldMessages, ...(notification || [])];
      allMessages.forEach(message => {
        if (message.navigate && !message.navigated) {
          toNavigate.push(message);
        }
        if (!message.silent && !message.isOrder) {
          toSave.push(message);
        }
        if (message.isOrder) {
          courierOrderMessage.push(message);
        }
        if (message.isGroup) {
          courierOrderGroupMessage.push(message);
        }
      });
      if (toNavigate.length > 0) {
        navigateMessage(toNavigate, getState, dispatch);
      }
      if (courierOrderMessage.length > 0) {
        showCourierOrders(courierOrderMessage, getState, dispatch);
      }
      if (courierOrderGroupMessage.length > 0) {
        showCourierOrderGroupMessage(
          courierOrderGroupMessage,
          getState,
          dispatch,
        );
      }
      dispatch({
        type: ACTIONS.ADD_NOTIFICATION,
        payload: toSave,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteNotification = id => {
  return async (dispatch, getState) => {
    runFunction('deleteNotification');
    try {
      if (id) {
        await getRealTimeDB()
          .ref('messages/' + get(getState(), 'user.uid', '') + '/' + id)
          .set(null);
      } else {
        (getState().notifications || []).forEach(message => {
          getRealTimeDB()
            .ref(
              'messages/' + get(getState(), 'user.uid', '') + '/' + message.id,
            )
            .set(null);
        });
      }
      dispatch({
        type: ACTIONS.DELETE_NOTIFICATION,
        payload: id,
      });
    } catch (err) {
      Analytics.reportError(err);
    }
  };
};

export const saveCourierLocationLog = ({lat, lng}) => {
  return async (dispatch, getState) => {
    runFunction('saveCourierLocationLog');
    const state = getState();
    if (get(state, 'user.needsToSubscribe')) {
      return;
    }
    if (
      !state.locationTime ||
      Date.now() - state.locationTime > 1000 * 60 * 10
    ) {
      await apiRequest('/apicourier_v1/save-location', {lat, lng}, 'POST');
      dispatch({
        type: ACTIONS.LOCATION_TIME,
      });
    }
  };
};

export const saveCourierLocation = place => {
  return async dispatch => {
    runFunction('saveCourierLocation');
    dispatch({
      type: ACTIONS.SAVE_COURIER_LOCATION,
      payload: place,
    });
  };
};

export const initializeCloudMessaging = (user, lang) => {
  return async () => {
    runFunction('initializeCloudMessaging');
    try {
      if (Platform.OS === 'web') {
        Analytics.registerDevice(lang, '');
      }

      await registerDeviceForFCM();
      let currentToken = await getCurrentFCMToken();
      onFCMTokenRefresh(async token => {
        Analytics.registerDevice(lang, token);
        let res = await apiRequest(
          '/apisender_v1/set-messaging-token',
          {token: currentToken},
          'POST',
        );
        Analytics.reportAPICall(
          '/apisender_v1/set-messaging-token',
          get(user, 'uid', ''),
          res.success,
        );
      });
      if (currentToken) {
        let res = await apiRequest(
          '/apisender_v1/set-messaging-token',
          {token: currentToken},
          'POST',
        );
        Analytics.registerDevice(lang, currentToken);
        Analytics.reportAPICall(
          '/apisender_v1/set-messaging-token',
          get(user, 'uid', ''),
          res.success,
        );
      }
    } catch (err) {
      Analytics.reportError(err);
    }
  };
};

const navigateMessage = async (messages, getState, dispatch) => {
  let listener;

  setTimeout(async () => {
    const handleNavigation = () => {
      try {
        let currentPage = getState().currentPage;
        let currentSection = getState().app;
        if (
          get(currentPage, 'page', '').indexOf('-chat-list') !== -1 ||
          get(currentPage, 'page', '').indexOf('-chat-view') !== -1
        ) {
          return;
        }
        if (
          messages.length === 1 &&
          currentSection === get(messages, '[0].section', '') &&
          get(currentPage, 'page', '') === get(messages, '[0].page', '') &&
          get(currentPage, 'id', '-') === get(messages, '[0].id', '-')
        ) {
          return;
        }
        if (getCurrentAppState() === 'active') {
          allMessagesNavigated(messages, get(getState(), 'user.uid', '')).catch(
            console.log,
          );
          const modalConf = {
            type: 'MESSAGES',
            title: messages.length === 1 ? 'new-message' : 'new-messages',
            text:
              messages.length === 1
                ? messages[0].modalText
                : 'do-you-want-to-see-messages-qs',
            button1: {
              text: 'show-details',
              onClick: () => {
                dispatch(closeModal());
                let navigation = getState().navigation;
                if (messages.length === 1) {
                  dispatch(deleteNotification(messages[0].id));
                  if (currentSection !== messages[0].section) {
                    if (Platform.OS !== 'web') {
                      dispatch(reloadToApp(messages[0].section));
                    }
                  } else if (messages[0].page) {
                    navigation.navigate(messages[0].page, {
                      id: messages[0].payloadId,
                    });
                  }
                } else {
                  let count = messages.filter(
                    m => m.section === currentSection,
                  ).length;
                  if (count > 0) {
                    navigation.navigate(currentSection + '-notifications');
                  } else if (currentSection === 'sender') {
                    dispatch(reloadToApp('courier'));
                  } else {
                    dispatch(reloadToApp('sender'));
                  }
                }
              },
            },
            button2: {
              text: 'close',
              onClick: () => {
                dispatch(closeModal());
                if (messages.length === 1) {
                  dispatch(deleteNotification(messages[0].id));
                }
              },
            },
          };
          dispatch(openModal(modalConf));
          removeAppStateChangeListener(handleNavigation, listener);
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (getCurrentAppState() === 'active') {
      handleNavigation('active');
    } else {
      listener = addAppStateChangeListener(handleNavigation);
    }
  }, 500);
};

const showCourierOrders = async (messages, getState, dispatch) => {
  let listener;

  try {
    setTimeout(async () => {
      const handleNavigation = state => {
        try {
          if (state === 'active') {
            if (get(getState(), 'app', '') === 'sender') {
              return;
            }
            if (!Array.isArray(messages)) {
              return;
            }
            messages.forEach(message => {
              dispatch(courierGetOrder(message.payloadId));
            });
            setTimeout(() => {
              messages.forEach(message => {
                dispatch(deleteNotification(message.id));
              });
            }, 200);
            if (messages.length === 1 && !messages[0].navigated) {
              dispatch(showQuickShowOrder(messages[0].payloadId));
            } /* else {
              dispatch(
                showOrderIdsModal(messages.map((message) => message.payloadId)),
              );
            }*/
            removeAppStateChangeListener(handleNavigation, listener);
          }
        } catch (err) {
          Analytics.reportError(err);
        }
      };
      if (getCurrentAppState() === 'active') {
        handleNavigation('active');
      } else {
        listener = addAppStateChangeListener(handleNavigation);
      }
    }, 500);
  } catch (err) {
    Analytics.reportError(err);
  }
};

const showCourierOrderGroupMessage = async (messages, getState, dispatch) => {
  try {
    setTimeout(async () => {
      const handleNavigation = state => {
        try {
          if (state === 'active') {
            if (get(getState(), 'app', '') === 'sender') {
              return;
            }
            if (!Array.isArray(messages)) {
              return;
            }
            messages.forEach(message => {
              dispatch(courierGetOrderGroup(message.payloadId));
            });
            setTimeout(() => {
              messages.forEach(message => {
                dispatch(deleteNotification(message.id));
              });
            }, 200);
            if (messages.length === 1 && !messages[0].navigated) {
              dispatch(showOrderGroupModal(messages[0].payloadId));
            } /* else {
              dispatch(
                showOrderIdsModal(messages.map((message) => message.payloadId)),
              );
            }*/
            addAppStateChangeListener(handleNavigation);
          }
        } catch (err) {
          Analytics.reportError(err);
        }
      };
      if (getCurrentAppState() === 'active') {
        handleNavigation('active');
      } else {
        addAppStateChangeListener(handleNavigation);
      }
    }, 500);
  } catch (err) {
    Analytics.reportError(err);
  }
};

export const chatListener = (timeout = 500) => {
  return async (dispatch, getState) => {
    runFunction('chatListener');
    let uid = get(getState(), 'user.uid', '');
    try {
      let chatsRef = await getRealTimeDB().ref('chats/' + uid);
      let chats = await chatsRef.once('value');
      chats = await chats.val();
      dispatch({
        type: ACTIONS.SAVE_CHATS,
        payload: chats,
      });
      chatsRef.on('value', async chatsSnap => {
        let chats = chatsSnap.val();
        dispatch({
          type: ACTIONS.SAVE_CHATS,
          payload: chats,
        });
      });
    } catch (err) {
      Analytics.reportError(err);
      setTimeout(() => {
        dispatch(chatListener(timeout * 2));
      }, timeout);
    }
  };
};

export const messageListener = user => {
  return async dispatch => {
    runFunction('messageListener');
    try {
      let messagesRef = await getRealTimeDB().ref(
        'messages/' + get(user, 'uid', ''),
      );
      let messages = await messagesRef.once('value');
      messages = await messages.val();
      messages = Object.values(messages || {});
      dispatch(addNotifications(messages, user));

      messagesRef.on('child_removed', async messageSnap => {
        let message = messageSnap.val();
        dispatch({
          type: ACTIONS.DELETE_NOTIFICATION,
          payload: message.id,
        });
      });
      messagesRef.on('child_added', async messageSnap => {
        try {
          let message = messageSnap.val();
          if (messages.some(m => m.id === message.id)) {
            return;
          }
          if (message && message.status === 'UNREAD') {
            dispatch(addNotifications([message], user));
            try {
              Analytics.reportNotification(message, get(user, 'uid', ''));
            } catch (e) {}
          }
        } catch (err) {
          Analytics.reportError(err);
        }
      });
    } catch (err) {
      Analytics.reportError(err);
      setTimeout(() => {
        dispatch(messageListener());
      }, 60000);
    }
  };
};

export const setNavigation = navigation => {
  return async dispatch => {
    runFunction('setNavigation');
    try {
      dispatch({
        type: ACTIONS.SAVE_NAVIGATION,
        payload: navigation,
      });
      navigation.addListener('state', event => {
        let pages = get(event, 'data.state.routes', []);
        let index = get(event, 'data.state.index', 0);
        let page = get(pages, '[' + index + '].name', '');
        let id = get(pages, '[' + index + '].params.id', '');
        dispatch(saveCurrentNavigationPage(page, id));
      });
    } catch (err) {
      Analytics.reportError(err);
    }
  };
};

export const initializeRealTimeOrders = () => {
  return async dispatch => {
    runFunction('initializeRealTimeOrders');
    try {
      var orderStatusesRef = getRealTimeDB().ref('realtime-order-status');
      orderStatusesRef.on('value', async snap => {
        let orderStatuses = snap.val();
        dispatch({
          type: ACTIONS.COURIER_SET_ORDER_STATUSES,
          payload: orderStatuses,
        });
      });
    } catch (err) {
      Analytics.reportError(err);
    }
  };
};

export const initializeApp = () => {
  return async (dispatch, getState) => {
    runFunction('initializeApp');
    Analytics.reportClientDetails(get(getState(), 'lang', ''));
    registerForGlobalErrors((error, isFatal) =>
      Analytics.reportError(error, get(getState(), 'user.uid', ''), isFatal),
    );

    addNetworkChangeListener(state => dispatch(onNetworkStateChange(state)));
    try {
      try {
        listenToGoogleSDK(key => {
          if (!getState().googleMapLoaded) {
            initializeGoogleMapSDK(dispatch, key, get(getState(), 'lang', ''));
            dispatch({
              type: ACTIONS.SET_GOOGLE_SDK,
              payload: key,
            });
          }
        });
        var constatnsRef = getRealTimeDB().ref('constants');
        constatnsRef.on('value', async snap => {
          let constants = snap.val();
          if (
            constants.buildNum > CONSTANTS.buildNum &&
            get(getState(), 'serverConstants.buildNum') !== constants.buildNum
          ) {
            const modalConf = {
              type: 'UPDATE_APP',
              title: 'update-app-title',
              text: 'update-app',
              button1: {
                text: 'update',
                onClick: () => {
                  dispatch(closeModal());
                  openInStore();
                },
              },
              button2: {
                text: 'close',
                onClick: () => {
                  dispatch(closeModal());
                },
              },
            };
            dispatch(openModal(modalConf));
          }
          const payload = {...constants};
          delete constants.GOOGLE_API_KEY;

          dispatch({
            type: ACTIONS.SET_SERVER_CONSTANTS,
            payload,
          });
        });
      } catch (e) {
        console.log(e);
      }
      let initialized = false;
      await onAuthStateChanged(async user => {
        let app = getState().app;
        if (user && !initialized) {
          Analytics.initializeUser(user);
          initialized = true;
          let func = senderGetUserData;
          if (app === 'courier') {
            func = courierGetUserData;
          }
          dispatch(chatListener());
          dispatch(
            func(async res => {
              dispatch(
                initializeCloudMessaging(res.user, get(getState(), 'lang', '')),
              );
              dispatch(messageListener(res.user));
            }),
          );
          addAppStateChangeListener(() => {
            if (getCurrentAppState() === 'active') {
              let app = getState().app;
              let func = getSenderData;
              if (app === 'courier') {
                func = getCourierData;
              }
              dispatch(chatListener());
              dispatch(
                func({}, async res => {
                  dispatch(
                    initializeCloudMessaging(
                      res.user,
                      get(getState(), 'lang', ''),
                    ),
                  );
                  dispatch(messageListener(res.user));
                }),
              );
            }
          });
        } else if (!user) {
          dispatch({
            type: ACTIONS.SET_USER,
            payload: {uid: '', phone: '', name: ''},
          });
        }
      });
    } catch (err) {
      Analytics.reportError(err);
    }
  };
};

export const sendContactUs = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('sendContactUs');
    try {
      dispatch(showLoader());
      let res = await apiRequest('/api_v1/send-contact-us', fields, 'POST');
      Analytics.reportAPICall(
        '/api_v1/send-contact-us',
        get(getState(), 'user.uid', ''),
        res.success,
      );
      if (typeof callback === 'function') {
        callback(res);
      }
      dispatch(hideLoader());
      showMessage(dispatch, 'message-send', 'will-contact-soon');
      if (res.success) {
        dispatch({
          type: ACTIONS.SEND_CONTACT_US,
        });
      } else {
        showMessage(dispatch, 'error', res.code);
      }
    } catch (err) {
      Analytics.reportError(err);
      dispatch(hideLoader());
    }
  };
};

/**********LOGIN**********/

export const loginWithTwilioLoginSMS = async (phone, code) => {
  let res = await apiRequest(
    '/api_v1/get-custom-login-token',
    {phone, code},
    'POST',
  );
  if (!res.success) {
    throw new Error('sms-validation-failed');
  }
  return await signInWithCustomToken(res.token);
};

export const sendTwilioLoginSMS = fields => {
  return async (dispatch, getState) => {
    runFunction('sendTwilioLoginSMS');
    makeApiCall(dispatch, getState, {
      url: '/api_v1/send-login-sms',
      payload: fields,
      showLoader: true,
      action: ACTIONS.NONE,
      parseRes: () => ({}),
      callback: res => {
        if (res.success) {
          showMessage(dispatch, 'message', 'sms-send-success');
        } else {
          showMessage(dispatch, 'message', 'sms-send-fail');
        }
      },
    });
  };
};

export const sendSMS = (phoneNumber, name, email, callback) => {
  return async (dispatch, getState) => {
    runFunction('sendSMS');
    try {
      dispatch(showLoader());
      setAuthForIOSEmulator();
      let confirmationResult = await signInWithPhoneNumber(phoneNumber);
      if (!confirmationResult) {
        dispatch(hideLoader());
        return;
      }
      onAuthStateChanged(user => {
        if (user) {
          Analytics.initializeUser(user);
          dispatch({
            type: ACTIONS.SET_USER_IN_PROGRESS,
          });
          dispatch(showLoader());
          if (typeof callback === 'function') {
            callback(true);
          }
          if (getState().app === 'sender') {
            dispatch(getSenderData({name, email, uid: user.uid}));
            dispatch(hideLoader());
          } else {
            dispatch(getCourierData({name, email, uid: user.uid}));
            dispatch(initializeRealTimeOrders());
            dispatch(hideLoader());
          }
        }
      });
      Analytics.reportSendSMS(phoneNumber);
      dispatch({
        type: ACTIONS.SAVE_CONFIRMATION_RES,
        payload: confirmationResult,
      });
      dispatch(hideLoader());
      if (typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      console.log(err);
      dispatch(hideLoader());
      showMessage(dispatch, 'error', err.code);
      Analytics.reportError(err);
    }
  };
};

export const confirmCode = (verificationCode, name, email, phone) => {
  return async (dispatch, getState) => {
    runFunction('confirmCode');
    try {
      dispatch(showLoader());
      let user;
      if (verificationCode.indexOf('TW') !== -1) {
        user = await loginWithTwilioLoginSMS(phone, verificationCode);
      } else {
        if (!getState().authConfirmation) {
          dispatch(hideLoader());
          showMessage(dispatch, 'error', 'No confirmation');
        }
        user = await confirmAuthSMSCode(
          verificationCode,
          getState().authConfirmation,
        );
      }
      Analytics.reportConfirm(get(user, 'phone', ''));
      dispatch({
        type: ACTIONS.SET_USER_IN_PROGRESS,
      });
      if (getState().app === 'sender') {
        dispatch(getSenderData({name, email, uid: get(user, 'uid', '')}));
      } else {
        dispatch(getCourierData({name, email, uid: get(user, 'uid', '')}));
        dispatch(initializeRealTimeOrders());
      }
    } catch (err) {
      Analytics.reportError(err);
      dispatch(hideLoader());
      showMessage(dispatch, 'error', err.code || err.message);
    }
  };
};

export const userLogout = callback => {
  return async (dispatch, getState) => {
    runFunction('userLogout');
    try {
      await signOut();
      Analytics.reportLogout(get(getState(), 'user.uid', ''));
      if (typeof callback === 'function') {
        callback();
      }
      dispatch({
        type: ACTIONS.USER_LOGOUT,
      });
      if (Platform.OS === 'web') {
        setTimeout(() => {
          reloadApp('logout');
        }, 200);
      }
    } catch (err) {
      showMessage(dispatch, 'error', err.message);
      Analytics.reportError(err);
    }
  };
};
