import React from 'react';
import {get} from 'lodash';
import {connect} from 'react-redux';
import Div from './Div';
import Span from './Span';

export const TimerComponent = ({
  text,
  percent = 1,
  strokeWidth = 20,
  size = 400,
}) => {
  const endCircle = (2 - strokeWidth / 1000) * Math.PI;
  const radius = size / 2 - strokeWidth / 2;

  const startCircle = (strokeWidth / 1000) * Math.PI;

  let rad = (2 - strokeWidth / 1000) * Math.PI;
  let dx = Math.sin(rad) * radius;
  let dy = -Math.cos(rad) * radius;
  const startX = size / 2 + dx;
  const startY = size / 2 + dy;
  percent = Math.min(Math.max(percent, 0), 1);
  const offset = (endCircle - startCircle) * (1 - percent);

  rad = startCircle + offset;
  dx = Math.sin(rad) * radius;
  dy = -Math.cos(rad) * radius;
  const x = size / 2 + dx;
  const y = size / 2 + dy;
  const d = `M ${startX} ${startY} A ${radius} ${radius} 0 ${
    percent > 0.5 ? 1 : 0
  } 0 ${x} ${y}`;
  let color = 'green';
  if (percent < 0.2) {
    color = 'orange';
  }
  if (percent < 0.1) {
    color = 'red';
  }
  return (
    <>
      <svg
        height={size}
        width={size}
        viewBox={`0 0 ${size} ${size}`}
        style={{fill: 'none'}}>
        <circle
          cy={size / 2}
          cx={size / 2}
          r={radius}
          stroke={percent === 0 ? 'red' : '#bdbdbd'}
          strokeWidth={strokeWidth}
        />
        {percent > 0 ? (
          <path
            strokeWidth={strokeWidth}
            d={d}
            stroke={color}
            strokeLinecap="round"
            fill="none"
          />
        ) : null}
      </svg>
      <Div
        theme="flex-column jc-center"
        style={{
          position: 'absolute',
          width: size - 4,
          height: size + 7.5,
          marginHorizontal: 5,
          top: 0,
        }}>
        <Span
          noTranslate
          style={{
            fontSize: 8,
            lineHeight: 8,
            textAlign: 'center',
          }}>
          {text}
        </Span>
      </Div>
    </>
  );
};

const mapStateToProps = state => ({
  app: state.app,
  width: get(state, 'window.width', 0),
});

export default connect(mapStateToProps)(TimerComponent);
