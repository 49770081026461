import React, {useContext} from 'react';
import {LanguageContext} from '../../language/lang';

export default ({children, style, onClick, disabled, title}) => {
  const lang = useContext(LanguageContext);

  const styles = {
    backgroundColor: 'white',
    borderColor: '#dedede',
    borderRadius: 5,
    borderWidth: 1,
    elevation: 2,
    marginHorizontal: 20,
    marginVertical: 7,
    padding: 20,
    shadowColor: '#000',
    border: 'none',
    background: 'none',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    ...style,
  };
  let css = styles;
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }

  let ariaLabel = lang[title] || title;

  if (!ariaLabel) {
    console.warn(`The following CardButton is missing aria-label`);
  }

  return (
    <button
      aria-label={ariaLabel}
      title={ariaLabel}
      onClick={() => (typeof onClick === 'function' ? onClick() : null)}
      disabled={disabled}
      style={css}>
      {children}
    </button>
  );
};
