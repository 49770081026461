import {useEffect, useRef} from 'react';
import {DEV} from '../common/nativeFunctions';

const SHOW_REACT_CLASSES = false;
const SHOW_FUNCTIONS = false;
const SHOW_ACTIONS = false;

export default (name = 'MISSING_MANE') => {
  if (DEV && SHOW_REACT_CLASSES) {
    const counter = useRef(0);
    useEffect(() => {
      console.log('FIRST_RENDER => ' + name);
      counter.current = 0;
    }, []);

    useEffect(() => {
      if (counter.current > 0) {
        console.log('RENDER(' + counter.current + ') => ' + name);
      }
      counter.current += 1;
    });
  }
};

let countersFunc = {};

export const runFunction = name => {
  if (DEV && SHOW_FUNCTIONS) {
    console.log('FUNCTION(' + (countersFunc[name] || 0) + ') => ' + name);
    countersFunc[name] = (countersFunc[name] || 0) + 1;
  }
};

let countersAction = {};

export const logAction = name => {
  if (DEV && SHOW_ACTIONS) {
    console.log('ACTION(' + (countersAction[name] || 0) + ') => ' + name);
    countersAction[name] = (countersAction[name] || 0) + 1;
  }
};
