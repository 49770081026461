import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {
  Title,
  IconButton,
  Div,
  Span,
  Address,
  Card as CardView,
  Button,
  Bold,
  Shadow,
  List,
} from '../Components';
import {LanguageContext, LANGUAGES} from '../../language/lang';
import {get, isEqual} from 'lodash';
import {
  ICBackLeft,
  ICBackRight,
  ICClock,
  ICClose,
  ICCustomSize,
  ICEdit,
  ICMinus,
  ICPlus,
  ICQuestionInfo,
  ICUpTo10KG,
  ICUpTo2KG,
  ICUpTo5KG,
} from '../Icons';
import moment from 'moment';
import {COLORS} from '../../common/constants';
import {
  closeModal,
  hideOrderGroupModal,
  hideOrderIdsModal,
  initializeCloudMessaging,
  openModal,
} from '../../redux/actions';
import {courierAcceptOrdersGroup} from '../../redux/courierActions';
import useDebug from '../../debug/useDebug';
import {
  checkNotifications,
  requestNotifications,
  RESULTS,
} from '../../common/permissions';
import {showMessage} from '../../common/function';
import Analytics from '../../common/analytics';
import {Platform} from '../../common/nativeFunctions';
import {useId} from '../hooks/useId';

const WarnCourier = () => (
  <Div style={{padding: 10}}>
    <Span style={{fontSize: 16, lineHeight: 16, marginHorizontal: 10}}>
      about-to-accept-order
    </Span>
    <Span
      style={{
        fontSize: 16,
        lineHeight: 16,
        marginHorizontal: 10,
        color: 'red',
      }}>
      about-to-accept-order-warning-1
    </Span>
  </Div>
);

class Card extends React.Component {
  static contextType = LanguageContext;
  shouldComponentUpdate(nextProps) {
    let item = get(this, 'props.item', {});
    let nextItem = get(nextProps, 'item', {});
    return !isEqual(item, nextItem);
  }
  render() {
    const lang = this.context;
    let item = get(this, 'props.item', {});
    let Size = null;
    let sizeTitle = '';
    switch (get(item, 'size', '')) {
      case '2-kg':
        Size = ICUpTo2KG;
        sizeTitle = '2-kg-s';
        break;
      case '5-kg':
        Size = ICUpTo5KG;
        sizeTitle = '5-kg-s';
        break;
      case '10-kg':
        Size = ICUpTo10KG;
        sizeTitle = '10-kg-s';
        break;
      case 'custom-package':
        Size = ICCustomSize;
        sizeTitle = 'custom-package-s';
        break;
    }
    function getDay(time) {
      let day;
      if (new Date().getDay() === new Date(time).getDay()) {
        day = lang.today;
      } else if (new Date().getDay() + 1 === new Date(time).getDay()) {
        day = lang.tomorrow;
      } else {
        day = lang[new Date(time).getDay()];
      }
      return day;
    }
    return (
      <CardView style={{...styles.card, width: item.width - 50}}>
        <Address fromAddress={item.fromAddress} toAddress={item.toAddress} />
        {get(item, 'pickupTime', '') ? (
          <Div style={{paddingHorizontal: 10, paddingVertical: 5}}>
            <Span numberOfLines={2}>{get(item, 'pickupTime', '')}</Span>
          </Div>
        ) : null}
        {get(item, 'description', '') ? (
          <Div style={{paddingHorizontal: 10, paddingVertical: 5}}>
            <Span numberOfLines={2}>{get(item, 'description', '')}</Span>
          </Div>
        ) : null}
        {get(item, 'backAndFoth') ? (
          <Div
            style={{paddingHorizontal: 10, marginTop: 0}}
            theme="flex-row jc-space-between ai-center">
            <Bold style={{fontSize: 16}}>back-and-foth</Bold>
            <IconButton
              title={`${lang.moreInformation} ${lang['back-and-foth']}`}
              onClick={item.showBackAndForthExplanation}>
              <ICQuestionInfo />
            </IconButton>
          </Div>
        ) : null}
        <Div theme="flex-row" style={{marginTop: 7}}>
          <Div
            theme="flex-row"
            style={{
              width: (item.width - 50) / 2,
              backgroundColor: COLORS.mainColorCourier + '88',
              paddingHorizontal: 5,
            }}>
            <ICClock width={30} height={47} />
            <Div style={{paddingHorizontal: 10, paddingVertical: 5}}>
              <Span style={{fontSize: 12, lineHeight: 16}}>
                {lang['courier-delivery-time'] +
                  ' ' +
                  getDay(get(item, 'maxDeliveryTime', ''))}
              </Span>
              <Span style={{lineHeight: 18, fontWeight: 'bold'}}>
                {moment(get(item, 'maxDeliveryTime', '')).format(
                  lang['moment-short-datetime'],
                )}
              </Span>
            </Div>
          </Div>
          <Div
            theme="flex-row"
            style={{
              width: (item.width - 50) / 2,
              backgroundColor: COLORS.mainColorCourier + '44',
              paddingHorizontal: 5,
            }}>
            {Size ? <Size height={47} width={26} /> : null}
            <Div style={{paddingHorizontal: 10, paddingVertical: 5}}>
              <Span style={{fontSize: 12, lineHeight: 16}}>size</Span>
              <Span style={{lineHeight: 18, fontWeight: 'bold'}}>
                {sizeTitle}
              </Span>
            </Div>
          </Div>
        </Div>
        <Div theme="flex-row jc-flex-end">
          <IconButton title="delete" onClick={() => item.onDelete()}>
            <Span
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                color: COLORS.mainColorCourier,
                margin: 4,
                marginHorizontal: 10,
              }}>
              {item.deleteOrder}
            </Span>
          </IconButton>
        </Div>
      </CardView>
    );
  }
}
const itemRenderer = ({item}) => <Card item={item} />;

function PackagesModal({
  group,
  isVisible,
  navigation,
  loggedIn,
  width,
  height,
  user,
}) {
  useDebug('PackagesModal');
  const lang = useContext(LanguageContext);
  const [deletedOrders, setdeletedOrders] = useState([]);
  const [stage, setstage] = useState(0);
  const dispatch = useDispatch();
  const activeId = useRef(false);
  const offerPriceRef = useRef(0);
  const [error, seterror] = useState({});
  const labelId = useId();

  const notificationsEnabled = useRef(false);
  useEffect(() => {
    checkNotifications().then(({status}) => {
      notificationsEnabled.current = status === RESULTS.GRANTED;
    });
  }, []);
  useEffect(() => {
    if (isVisible && group) {
      Analytics.reportCourierEvent('group-order-modal', get(user, 'uid', ''), {
        group: get(group, 'id'),
      });
    }
  }, [group, isVisible]);
  const ordersToRender = useMemo(() => {
    if (Array.isArray(get(group, 'orders'))) {
      let orders = (group.orders || []).filter(
        order => deletedOrders.indexOf(order.id) === -1,
      );
      return orders.map(order => {
        return {
          id: get(order, 'id', ''),
          renderId: get(order, 'id', ''),
          pickupTime: get(order, 'pickupTime', ''),
          backAndFoth: get(order, 'backAndFoth', false),
          description: get(order, 'description', ''),
          fromAddress: get(order, lang.id + '.fromAddress', null),
          toAddress: get(order, lang.id + '.toAddress', null),
          maxDeliveryTime: get(order, 'maxDeliveryTime', 0),
          size: get(order, 'size', ''),
          width,
          onDelete: () =>
            setdeletedOrders([...deletedOrders, get(order, 'id', '')]),
          showBackAndForthExplanation: () =>
            showMessage(
              dispatch,
              'info',
              'back-and-forth-explanation-courier',
              ['close'],
              'INFO',
            ),
          deleteOrder: lang['remove-order'],
        };
      });
    } else {
      return [];
    }
  }, [group, deletedOrders]);

  const bambizzCourierPrice = useMemo(() => {
    if (Array.isArray(get(group, 'orders'))) {
      let orders = (group.orders || []).filter(
        order => deletedOrders.indexOf(order.id) === -1,
      );
      return orders
        .map(order => get(order, 'bambizzCourierPrice', 0))
        .reduce((a, b) => a + b, 0);
    } else {
      return 0;
    }
  }, [group, deletedOrders]);

  const bambizzCourierMinPrice = useMemo(() => {
    if (Array.isArray(get(group, 'orders'))) {
      let orders = (group.orders || []).filter(
        order => deletedOrders.indexOf(order.id) === -1,
      );
      return orders
        .map(order => get(order, 'bambizzCourierMinPrice', 0))
        .reduce((a, b) => a + b, 0);
    } else {
      return 0;
    }
  }, [group, deletedOrders]);

  const bambizzCourierMaxPrice = useMemo(() => {
    if (Array.isArray(get(group, 'orders'))) {
      let orders = (group.orders || []).filter(
        order => deletedOrders.indexOf(order.id) === -1,
      );
      return orders
        .map(order => get(order, 'bambizzCourierMaxPrice', 0))
        .reduce((a, b) => a + b, 0);
    } else {
      return 0;
    }
  }, [group, deletedOrders]);

  const [offerPrice, setofferPrice] = useState(bambizzCourierPrice);

  useEffect(() => {
    offerPriceRef.current = offerPrice;
  }, [offerPrice]);
  useEffect(() => {
    if (!isVisible) {
      setdeletedOrders([]);
    }
    if (!isVisible) {
      setstage(0);
    }
  }, [isVisible]);
  useEffect(() => {
    if (
      (ordersToRender || []).length === 0 &&
      get(group, 'orders', []).length > 0
    ) {
      onClose();
    }
  }, [ordersToRender]);
  useEffect(() => {
    setofferPrice(bambizzCourierPrice);
  }, [ordersToRender]);
  let navIcon =
    lang.id !== LANGUAGES.EN ? (
      <ICBackLeft width={30} height={40} color={COLORS.mainColorCourier} />
    ) : (
      <ICBackRight width={30} height={40} color={COLORS.mainColorCourier} />
    );
  return isVisible ? (
    <Div style={styles.background} role="dialog" aria-labelledby={labelId}>
      <Div
        style={{
          ...styles.foreground,
          width: width - 30,
          minHeight: height - 150,
        }}>
        {stage === 0 ? (
          <>
            <Div theme="flex-row jc-space-between ai-center">
              <IconButton title="close" onClick={onClose}>
                <ICClose
                  width={30}
                  height={40}
                  color={COLORS.mainColorCourier}
                />
              </IconButton>
              <Div>
                <Title
                  id={labelId}
                  theme="orange"
                  style={{lineHeight: 20, fontSize: 18}}>
                  group
                </Title>
                <Title theme="orange" style={{lineHeight: 20, fontSize: 18}}>
                  {(ordersToRender || []).length + ' ' + lang.orders}
                </Title>
              </Div>
              <Div style={{width: 50}} />
            </Div>
            <Shadow />
            <Div>
              <List
                itemRenderer={itemRenderer}
                itemsToRender={ordersToRender || []}
                offset={270}
              />
            </Div>
            <Shadow buttom />
            <Button
              theme="orange"
              style={styles.buttonLeft}
              onClick={() => setstage(1)}>
              next
            </Button>
          </>
        ) : null}
        {stage === 1 ? (
          <Div
            theme="flex-column jc-space-between"
            style={{height: height - 150}}>
            <Div theme="flex-row jc-space-between ai-center">
              <IconButton title="close" onClick={onClose}>
                <ICClose
                  width={30}
                  height={40}
                  color={COLORS.mainColorCourier}
                />
              </IconButton>
              <Div>
                <Title theme="orange" style={{lineHeight: 20, fontSize: 18}}>
                  group
                </Title>
              </Div>
              <IconButton onClick={() => setstage(0)}>{navIcon}</IconButton>
            </Div>
            <Div theme="flex-row jc-center ai-center">
              <Title theme="orange" style={{lineHeight: 20, fontSize: 18}}>
                {(ordersToRender || []).length + ' ' + lang.orders}
              </Title>
              <IconButton title="edit" onClick={() => setstage(0)}>
                <ICEdit color={COLORS.mainColorCourier} />
              </IconButton>
            </Div>
            <Title
              style={{
                color: COLORS.covidMainColor,
                fontSize: 16,
                lineHeight: 20,
                marginHorizontal: 10,
              }}>
              multiple-orders-desc
            </Title>
            <Div theme="flex-row jc-center">
              <Div
                theme="flex-row jc-center"
                style={{
                  width: 200,
                  height: 200,
                  borderWidth: 3,
                  borderColor: COLORS.mainColorCourier,
                  borderRadius: 100,
                }}>
                <Div theme="flex-column jc-space-between ai-center">
                  <Bold style={{fontSize: 12, marginTop: 10}}>your-offer</Bold>
                  <Div theme="flex-row jc-space-between ai-center">
                    <IconButton
                      title="addPrice"
                      onPressIn={() => {
                        activeId.current = true;
                        addPrice();
                      }}
                      onPressOut={() => (activeId.current = false)}>
                      <Div theme="flex-row jc-flex-end">
                        <ICPlus width={50} height={50} />
                      </Div>
                    </IconButton>
                    <Title style={{fontSize: 40, lineHeight: 45}}>
                      {parseInt(offerPrice, 10) + '₪'}
                    </Title>
                    <IconButton
                      title="subPrice"
                      onPressIn={() => {
                        activeId.current = true;
                        subPrice();
                      }}
                      onPressOut={() => (activeId.current = false)}>
                      <Div theme="flex-row jc-flex-start">
                        <ICMinus width={50} height={50} />
                      </Div>
                    </IconButton>
                  </Div>
                  <Div style={{height: 30}} />
                </Div>
              </Div>
            </Div>
            <Div>
              <Title
                style={{
                  color: COLORS.covidMainColor,
                  fontSize: 18,
                  lineHeight: 20,
                  marginHorizontal: 10,
                }}>
                call-sender-warning
              </Title>
              <Button
                onClick={acceptOrder}
                theme="orange"
                style={styles.buttonLeft}>
                take-package
              </Button>
            </Div>
          </Div>
        ) : null}
      </Div>
    </Div>
  ) : null;
  function onClose() {
    dispatch(hideOrderGroupModal());
  }
  function addPrice(timeout = 500) {
    if (bambizzCourierMaxPrice > offerPriceRef.current && activeId.current) {
      setofferPrice(parseInt(offerPriceRef.current + 1, 10));
      setTimeout(() => {
        addPrice(timeout / 3);
      }, timeout);
    }
  }
  function subPrice(timeout = 500) {
    if (bambizzCourierMinPrice < offerPriceRef.current && activeId.current) {
      setofferPrice(parseInt(offerPriceRef.current - 1, 10));
      setTimeout(() => {
        subPrice(timeout / 3);
      }, timeout);
    }
  }
  async function acceptOrder() {
    if ((get(group, 'orders') || []).some(order => get(order, 'backAndFoth'))) {
      let result = await showMessage(
        dispatch,
        'warning',
        'back-and-forth-explanation-courier-warning',
        ['accept-order', 'close'],
        'WARNING_BACK_FORTH',
      );
      if (result !== 'accept-order') {
        return;
      }
    } else {
      let result = await warnBeforeAccept();
      if (!result) {
        return;
      }
    }
    let err = {};
    if (parseFloat(offerPrice) <= 0 || isNaN(parseFloat(offerPrice))) {
      showMessage(dispatch, 'error', 'enter-valid-price');
      err.offerPrice = true;
    }

    seterror(err);
    if (Object.keys(err).length) {
      return;
    }

    if (Platform.OS !== 'web' && !notificationsEnabled.current) {
      await requestNotifications(['alert', 'badge', 'sound', 'criticalAlert']);
      dispatch(initializeCloudMessaging(navigation));
    }

    if (!loggedIn) {
      try {
        dispatch(hideOrderGroupModal());
        dispatch(hideOrderIdsModal());
        navigation.navigate('courier-login', {
          redirect: 'courier-order-around',
          redirectParams: {},
        });
      } catch (e) {}
      return;
    }

    if (user?.needsToSubscribe) {
      dispatch(hideOrderGroupModal());
      dispatch(hideOrderIdsModal());
      navigation.navigate('courier-subscribe');
      return;
    }

    dispatch(
      courierAcceptOrdersGroup(
        {
          offer: offerPrice || 0,
          groupId: get(group, 'id', ''),
          ordersId: ordersToRender.map(order => order.id),
        },
        () => {
          if (typeof onClose === 'function') {
            onClose();
          }
        },
      ),
    );
  }
  function warnBeforeAccept() {
    return new Promise(resolve => {
      const modalConf = {
        type: 'WARN_BEFORE_ACCEPT',
        title: 'warning',
        onClose: () => {
          dispatch(closeModal());
          resolve(false);
        },
        children: WarnCourier,
        value: false,
        button1: {
          text: 'accept-order',
          onClick: () => {
            dispatch(closeModal());
            resolve(true);
          },
        },
        button2: {
          text: 'close',
          onClick: () => {
            dispatch(closeModal());
            resolve(false);
          },
        },
      };
      dispatch(openModal(modalConf));
    });
  }
}

const styles = {
  background: {
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
    width: '100%',
  },
  buttonLeft: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderRadius: 0,
    marginBottom: -1,
    width: '100%',
  },
  card: {
    padding: 0,
    marginHorizontal: 9,
  },
  foreground: {
    backgroundColor: 'white',
    borderRadius: 5,
    paddingBottom: 0,
  },
  labelBackground: (background, width) => ({
    backgroundColor: background || '#f4f4f4',
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    overflow: 'hidden',
    width,
    paddingHorizontal: 3,
  }),
  labelText: (color, width) => ({
    width,
    color: color || 'grey',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 26,
  }),
  list: height => ({
    height: height - 270,
  }),
};

const mapStateToProps = state => {
  let id = get(state, 'receiverApp.ordersGroupModal', null);
  let lang = (state.lang || '').toUpperCase();
  let group;
  let orderStatuses = get(state, 'receiverApp.orderStatuses', null);
  let loggedIn = !!get(state, 'user.uid', null);
  let checkOrder = () => true;
  if (orderStatuses) {
    checkOrder = order => get(orderStatuses, order.id + '.status') === 'POSTED';
  }
  group = (get(state, 'receiverApp.orders', []) || []).find(
    order => order.id === id,
  );
  if (Array.isArray(get(group, 'orders'))) {
    group.orders = (group.orders || []).filter(
      order => order.status === 'POSTED' && checkOrder(order),
    );
    group.orders = group.orders.map(order => {
      let fromAddress = get(order, lang + '.fromAddress', {});
      let toAddress = get(order, lang + '.toAddress', {});
      if (Object.keys(fromAddress).length > 0) {
        order.fromAddress = fromAddress;
      }
      if (Object.keys(toAddress).length > 0) {
        order.toAddress = toAddress;
      }
      return order;
    });
  }
  return {
    loggedIn,
    group,
    navigation: state.navigation,
    width: get(state, 'window.width', 0),
    height: get(state, 'window.height', 0),
    user: state.user,
    hideWarningBeforeAccepting: get(
      state,
      'receiverApp.hideWarningBeforeAccepting',
    ),
    paymentsEnabled: get(state, 'serverConstants.paymentsEnabled', ''),
  };
};

export default connect(mapStateToProps)(PackagesModal);
