import {get} from 'lodash';
import packageJson from '../../package.json';
import Cache from './cache';
import {RESULTS} from './permissions';
import {v4 as uuid} from 'uuid';
import {formfactor} from 'platform-detect';
import clipboardCopy from 'clipboard-copy';

const getLangId = () => {
  if (navigator?.languages || navigator?.language) {
    let languages = (navigator?.languages || [])
      .map(lang => /^(?<langId>[a-z\d]{2,4})/.exec(lang)?.groups?.langId)
      .filter(x => x);
    let language =
      /^(?<langId>[a-z\d]{2,4})/.exec(navigator?.language || '')?.groups
        ?.langId || '';
    if (language) {
      return language;
    }
    if (languages.length) {
      return languages[0];
    }
    return 'en';
  }
};

export const hideKeyboard = () => null;

export const getAppVersion = () => packageJson.version;

export const getAppBuildNumber = () => packageJson.buildNumber;

export const getUniqueDviceId = () => {
  try {
    // eslint-disable-next-line no-undef
    let deviceId = localStorage.getItem('@device-id');
    if (deviceId) {
      return deviceId;
    }
    deviceId = uuid();
    // eslint-disable-next-line no-undef
    localStorage.setItem('@device-id', deviceId);
    return deviceId;
  } catch (err) {
    return 'incognito';
  }
};

export const isTablet = () => false;

export const Platform = {OS: 'web'};

export const StaticSafeAreaInsets = {
  safeAreaInsetsTop: 0,
  safeAreaInsetsBottom: 0,
  safeAreaInsetsLeft: 0,
  safeAreaInsetsRight: 0,
};

export const getWindowWidth = () => window.innerWidth;

export const getWindowHeight = () => window.innerHeight;

export const getCurrentPosition = (onSuccess, onError) => {
  try {
    if (!navigator.geolocation) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('@location-permission', RESULTS.UNAVAILABLE);
    } else {
      navigator.geolocation.getCurrentPosition(
        geolocation => {
          // eslint-disable-next-line no-undef
          localStorage.setItem('@location-permission', RESULTS.GRANTED);
          if (typeof onSuccess === 'function') {
            onSuccess(geolocation);
          }
        },
        onSuccess,
        err => {
          console.log(err);
          if (typeof onError === 'function') {
            onError(err);
          }
        },
      );
    }
  } catch (err) {}
};

export const watchPosition = () => null;

export const downloadAndOpenFile = async url => {
  window.open(url, 'bambizz_pdf');
};

export const exitApp = () => null;

export const addAppStateChangeListener = func => {
  let hidden, visibilityChange;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    visibilityChange = 'webkitvisibilitychange';
  }
  if (typeof document.addEventListener !== 'undefined' && hidden) {
    document.addEventListener(visibilityChange, func, false);
  }
};

export const removeAppStateChangeListener = func => {
  let hidden, visibilityChange;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    visibilityChange = 'webkitvisibilitychange';
  }
  if (typeof document.addEventListener !== 'undefined' && hidden) {
    document.removeEventListener(visibilityChange, func, false);
  }
};

export const addKeyboardShowListener = () => ({remove: () => null});

export const addKeyboardHideListener = () => ({remove: () => null});

export const addBackPressListener = () => null;

export const removeBackPressListener = () => null;

export const getCurrentAppState = () => {
  let hidden;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
  }
  return document[hidden] ? '' : 'active';
};

export const addNetworkChangeListener = func => {
  var connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  if (connection && connection.addEventListener) {
    connection.addEventListener('change', () => {
      if (typeof func === 'function') {
        func({
          isInternetReachable: navigator.onLine,
        });
      }
    });
  }
};

export const isDesktop = () => formfactor === 'desktop';

export const openInStore = () => {
  try {
    return (
      window.open('https://link.bambizz.com/6eZ4', 'bambizz_link').name ===
      'bambizz_link'
    );
  } catch (err) {
    return false;
  }
};

export const registerForGlobalErrors = () => null;

export const DEV =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const openUrl = async url => {
  try {
    let name = window.open(url, 'bambizz_link').name;
    return name === 'bambizz_link';
  } catch (err) {
    return false;
  }
};

export const useFocusEffect = () => null;

export const sendPhoneCall = () => null;

export const share = async message => {
  await navigator.share({
    title: message,
    message,
  });
};
export const copyToClipboard = message => clipboardCopy(message);

export const SystemSettings = {
  getSetting: async () => 'ENABLED',
  openSetting: async () => null,
  LOCATION_SETTING: 'LOCATION_SETTING',
  ENABLED: 'ENABLED',
  ACTION_LOCATION_SOURCE_SETTINGS: 'ACTION_LOCATION_SOURCE_SETTINGS',
};

export const initializeGoogleMapSDK = (dispatch, googleKey, langId) => {
  let script = document.createElement('script');
  let lang = ['he', 'iw'].includes(getLangId()) ? 'iw' : 'en';
  if (langId === 'HE') {
    lang = 'iw';
  }
  if (langId.includes('EN')) {
    lang = 'en';
  }
  script.src =
    'https://maps.googleapis.com/maps/api/js?key=' +
    googleKey +
    '&callback=initService&libraries=places&v=weekly&language=' +
    lang;
  script.defer = true;
  window.initService = function () {
    dispatch({
      type: 'GOOGLE_MAPS_LOADED',
    });
  };
  document.head.appendChild(script);
};

const getAutoCompleteDetailsCache = new Cache(
  'Get-Auto-Complete-Details-Cache',
);

export const listenToGoogleSDK = callback => {
  callback('AIzaSyDb2eOLVnt2gJPLiG3qrYZCHVNfKBCeFSs');
};

export const getAutoCompleteDetails = (input, token, lang) => {
  return new Promise(resolve => {
    try {
      let cached = getAutoCompleteDetailsCache.getItem(input, lang);
      if (cached) {
        resolve(cached);
      }
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {input, sessionToken: token},
        function (predictions, status) {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            resolve([]);
          } else {
            let places = predictions.map(pred => {
              return {
                text: get(pred, 'description', ''),
                placeId: get(pred, 'place_id', ''),
              };
            });
            getAutoCompleteDetailsCache.cacheItem(input, lang, places);
            resolve(places);
          }
        },
      );
    } catch (err) {
      console.log(err);
      resolve([]);
    }
  });
};

export const getAutoCompleteToken = () => {
  try {
    return new window.google.maps.places.AutocompleteSessionToken();
  } catch (err) {
    return '';
  }
};

const getPlaceDetailsCache = new Cache('Get-Place-Details-Cache');

export const getPlaceDetails = (placeId, token, lang) => {
  return new Promise(resolve => {
    try {
      let cached = getPlaceDetailsCache.getItem(placeId, lang);
      if (cached) {
        resolve(cached);
      }
      const service = new window.google.maps.places.PlacesService(
        document.getElementById('map-google-place-service'),
      );
      service.getDetails(
        {
          placeId,
          fields: ['address_component', 'formatted_address', 'geometry'],
          sessionToken: token,
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            let address_components = get(place, 'address_components', []);
            const placeResult = {
              text: get(place, 'formatted_address', ''),
              googleId: placeId,
              country: get(
                address_components.find(comp =>
                  get(comp, 'types', []).some(type => type === 'country'),
                ),
                'short_name',
                '',
              ),
              state: get(
                address_components.find(comp =>
                  get(comp, 'types', []).some(
                    type => type === 'administrative_area_level_1',
                  ),
                ),
                'long_name',
                '',
              ),
              city: get(
                address_components.find(comp =>
                  get(comp, 'types', []).some(type => type === 'locality'),
                ),
                'long_name',
                '',
              ),
              street: get(
                address_components.find(comp =>
                  get(comp, 'types', []).some(type => type === 'route'),
                ),
                'long_name',
                '',
              ),
              number: get(
                address_components.find(comp =>
                  get(comp, 'types', []).some(type => type === 'street_number'),
                ),
                'long_name',
                '',
              ),
              lat: get(place, 'geometry.location.lat', () => 0)(),
              lng: get(place, 'geometry.location.lng', () => 0)(),
            };
            getPlaceDetailsCache.cacheItem(placeId, lang, placeResult);
            resolve(placeResult);
          }
        },
      );
    } catch (err) {
      console.log(err);
      resolve({});
    }
  });
};

const getLocationDetailsCache = new Cache('Get-Location-Details-Cache');

export const getLocationDetails = async ({lat, lng}, lang, googleKey) => {
  try {
    let latLng =
      Math.round(lat * 100000) / 100000 +
      ',' +
      Math.round(lng * 100000) / 100000;
    let cached = getLocationDetailsCache.getItem(latLng, lang);
    if (cached) {
      return [cached];
    }
    let res = await fetch(
      'https://maps.googleapis.com/maps/api/geocode/json?language=' +
        (lang?.includes?.('EN') ? 'en' : 'iw') +
        '&latlng=' +
        lat +
        ',' +
        lng +
        '&key=' +
        googleKey +
        '&location_type=ROOFTOP',
    );
    if (res.ok) {
      let text = await res.text();
      let parsed = JSON.parse(text);
      if (parsed.status === 'OK') {
        let places = parsed.results.map(place => {
          let address_components = get(place, 'address_components', []);
          return {
            text: get(place, 'formatted_address', ''),
            googleId: get(place, 'place_id', ''),
            country: get(
              address_components.find(comp =>
                get(comp, 'types', []).some(type => type === 'country'),
              ),
              'short_name',
              '',
            ),
            state: get(
              address_components.find(comp =>
                get(comp, 'types', []).some(
                  type => type === 'administrative_area_level_1',
                ),
              ),
              'long_name',
              '',
            ),
            city: get(
              address_components.find(comp =>
                get(comp, 'types', []).some(type => type === 'locality'),
              ),
              'long_name',
              '',
            ),
            street: get(
              address_components.find(comp =>
                get(comp, 'types', []).some(type => type === 'route'),
              ),
              'long_name',
              '',
            ),
            number: get(
              address_components.find(comp =>
                get(comp, 'types', []).some(type => type === 'street_number'),
              ),
              'long_name',
              '',
            ),
            lat: get(place, 'geometry.location.lat', 0),
            lng: get(place, 'geometry.location.lng', 0),
          };
        });
        if (places.length > 0) {
          getLocationDetailsCache.cacheItem(latLng, lang, places[0]);
        }
        return places;
      }
    }
    return [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const reloadApp = () => window.location.reload();

export const setSEO = (path, lang) => {
  try {
    let seo = lang[path.substr(3)];
    if (!seo || typeof seo !== 'object') {
      seo = lang[path];
    }
    if (!seo || typeof seo !== 'object') {
      seo = lang['/sender-input-order'];
    }
    document.getElementById('page-title').innerText = get(
      seo,
      'title',
      'Bambizz',
    );
    document.getElementById('description').content = get(
      seo,
      'description',
      '',
    );
    document.getElementById('he-alternate').href = get(
      window,
      'location.href',
      '',
    ).replace('/en/', '/he/');
    document.getElementById('en-alternate').href = get(
      window,
      'location.href',
      '',
    ).replace('/he/', '/en/');
  } catch (e) {}
};
