import React from 'react';
import generalStyle from './generalStyle.json';
import courierStyle from './courierStyle.json';
import senderStyle from './senderStyle.json';

export const ThemeContext = React.createContext({});

export const getTheme = (app, width, height) => {
  let theme = {
    ...generalStyle,
    width,
    height,
    'width-st': {width},
    'height-st': {height},
  };
  switch (app) {
    case 'courier':
      theme = {...theme, ...courierStyle};
      break;
    case 'sender':
      theme = {...theme, ...senderStyle};
      break;
  }
  return theme;
};
