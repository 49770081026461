import {CONSTANTS} from '../common/constants';
import {get} from 'lodash';
import getLang from '../language/lang';
import {
  getWindowHeight,
  getWindowWidth,
  isTablet,
  Platform,
  StaticSafeAreaInsets,
} from '../common/nativeFunctions';

let height = getWindowHeight();
let width = getWindowWidth();

if (Platform.OS === 'ios') {
  height =
    height -
    (StaticSafeAreaInsets.safeAreaInsetsTop +
      StaticSafeAreaInsets.safeAreaInsetsBottom);
  width =
    width -
    (StaticSafeAreaInsets.safeAreaInsetsLeft +
      StaticSafeAreaInsets.safeAreaInsetsRight);
}

let leftOffset = 0;
let rightOffset = 0;

const initialState = {
  googleMapLoaded: false,
  lang: get(getLang(), 'id', 'en'),
  loader: false,
  initialURLNavigated: false,
  user: {
    uid: '',
    phone: '',
    name: '',
    blockNotifications: false,
  },
  window:
    height > width || isTablet() || Platform.OS === 'web'
      ? {
          height: Math.round(height || 1),
          width: Math.round(width || 1),
          rightOffset,
          leftOffset,
        }
      : {
          width: Math.round(height || 1),
          height: Math.round(width || 1),
          rightOffset,
          leftOffset,
        },
  location: {
    lat: 31,
    lng: 34,
    type: 'initialized',
  },
  modal: [],
  offline: true,
  serverConstants: {
    version: CONSTANTS.version,
    priceInCity: 70,
    pricePerKM: 3,
    routeDistance: 5,
    minPrice: 70,
    buildNum: CONSTANTS.buildNum,
    showAccessibilityButton: false,
  },
  chats: {},
  senderApp: {
    fields: {
      fromAddress: {},
      toAddress: {},
      size: '',
      time: '',
      description: '',
      receiptName: '',
      needReceipt: false,
      receiverPhone: '',
      receiverName: '',
      sendingToSelf: false,
      backAndFoth: false,
      pickupTime: '',
      volunteerOrganizationName: '',
      volunteerOrder: false,
      numberOfPackages: null,
    },
    orders: null,
    offers: null,
  },
  receiverApp: {
    payments: null,
    quickOrderView: null,
    orderIdsModal: null,
    confirmedOrders: null,
    orders: null,
    offers: null,
    ordersVolunteer: null,
    courierRoutes: null,
  },
  networkCallQueue: [],
  app: 'welcome',
  visited: false,
  message: false,
  notifications: null,
};
export default initialState;
