import {useState, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {savePackageFields} from '../../redux/actions';
import {get} from 'lodash';

export default (savedFields = {}) => {
  const fieldsRef = useRef({});
  const dispatch = useDispatch();
  const [fields, setfields] = useState({
    fromAddress: {},
    toAddress: {},
    size: '',
    time: '',
    description: '',
    receiptName: '',
    receiverPhone: '',
    receiverName: '',
    needReceipt: false,
    sendingToSelf: false,
    backAndFoth: false,
    pickupTime: '',
    volunteerOrganizationName: '',
    volunteerOrder: false,
    numberOfPackages: null,
  });

  useEffect(() => {
    fieldsRef.current = fields;
  }, [fields]);

  useEffect(() => {
    if (savedFields) {
      setfields(savedFields);
    }
  }, [savedFields]);

  function setField(name, val) {
    setfields({...fieldsRef.current, [name]: val});
    setTimeout(() => {
      dispatch(savePackageFields(fieldsRef.current));
    }, 20);
  }

  function reset() {
    const obj = {
      fromAddress: get(fieldsRef.current, 'fromAddress', {}),
      toAddress: {},
      size: '',
      time: '',
      description: '',
      receiptName: '',
      receiverPhone: '',
      receiverName: '',
      needReceipt: false,
      sendingToSelf: false,
      backAndFoth: false,
      pickupTime: '',
      bambizzPrice: '',
      customTimePrice: null,
    };
    setfields(obj);
    setTimeout(() => {
      dispatch(savePackageFields(obj));
    }, 20);
  }
  return [fields, setField, fieldsRef.current, reset];
};
