import initialState from './initialState';
import {get} from 'lodash';
import {saveState} from '../common/function';
import {logAction} from '../debug/useDebug';
import {ACTIONS} from '../common/constants';
import Analytics from '../common/analytics';
import {
  isTablet,
  Platform,
  StaticSafeAreaInsets,
} from '../common/nativeFunctions';

const reduce = (state = initialState, action) => {
  let returnState = state;
  logAction(action.type);
  let modalQueue = [];
  let removeDups = [];
  let messages = [];
  try {
    switch (action.type) {
      case ACTIONS.RESTORE_SAVED_STATE:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            confirmedOrders: get(
              action,
              'payload.receiverApp.confirmedOrders',
              null,
            ),
            courierRoutes: get(
              action,
              'payload.receiverApp.courierRoutes',
              null,
            ),
            offers: get(action, 'payload.receiverApp.offers', null),
            payments: get(action, 'payload.receiverApp.payments', null),
          },
          senderApp: {
            ...get(state, 'senderApp', {}),
            offers: get(action, 'payload.senderApp.offers', null),
            orders: get(action, 'payload.senderApp.orders', null),
          },
          serverConstants: get(
            action,
            'payload.serverConstants',
            state.serverConstants,
          ),
          courierLocation: get(action, 'payload.courierLocation', {}),
          user: get(action, 'payload.user', state.user),
          chats: get(action, 'payload.chats', state.chats) || {},
          lang: get(action, 'payload.lang', state.lang),
          shareLink: get(action, 'payload.shareLink', state.shareLink),
          reffredByUser: get(
            action,
            'payload.reffredByUser',
            state.reffredByUser,
          ),
          restored: true,
        };
        break;
      case ACTIONS.REFFERD_BY_USER:
        returnState = {...state, reffredByUser: get(action, 'payload', '')};
        break;
      case ACTIONS.SET_COURIER_ORDERS_LOADING:
        returnState = {...state, courierLoading: get(action, 'payload', false)};
        break;
      case ACTIONS.SAVE_CHATS:
        returnState = {...state, chats: get(action, 'payload', {}) || {}};
        break;
      case ACTIONS.GOOGLE_MAPS_LOADED:
        returnState = {...state, googleMapLoaded: true};
        break;
      case ACTIONS.ADD_SENDER_MESSAGES:
        messages = [];
        [...(get(action, 'payload', {}) || [])].forEach(message => {
          if (messages.every(m => m.id !== message.id)) {
            messages.push(message);
          }
        });
        returnState = {
          ...state,
          modalQueue,
          senderApp: {
            ...get(state, 'senderApp', {}),
            messages,
          },
        };
        break;
      case ACTIONS.SET_LANG:
        returnState = {...state, lang: get(action, 'payload', 'EN')};
        break;
      case ACTIONS.CLEAR_NETWORK_CALL_QUEUE:
        returnState = {...state, networkCallQueue: []};
        break;
      case ACTIONS.ADD_NETWORK_CALL:
        returnState = {
          ...state,
          networkCallQueue: [
            ...(get(state, 'networkCallQueue', []) || []),
            get(action, 'payload', {}),
          ].slice(-5),
        };
        break;
      case ACTIONS.SET_QUICK_ORDER_VIEW:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'ORDER_QUICK_VIEW'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'ORDER_QUICK_VIEW')
        ) {
          modalQueue = [...modalQueue, 'ORDER_QUICK_VIEW'];
        }
        returnState = {
          ...state,
          modalQueue,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            quickOrderView: action.payload,
          },
        };
        break;
      case ACTIONS.SET_ORDER_IDS_MODAL:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'ORDERS_LIST_COURIER'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'ORDERS_LIST_COURIER')
        ) {
          modalQueue = [...modalQueue, 'ORDERS_LIST_COURIER'];
        }
        returnState = {
          ...state,
          modalQueue,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            orderIdsModal: action.payload,
          },
        };
        break;
      case ACTIONS.SET_ORDERS_GROUP_MODAL:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'ORDERS_GROUP_COURIER'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'ORDERS_GROUP_COURIER')
        ) {
          modalQueue = [...modalQueue, 'ORDERS_GROUP_COURIER'];
        }
        returnState = {
          ...state,
          modalQueue,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            ordersGroupModal: action.payload,
          },
        };
        break;
      case ACTIONS.SHOW_SENDER_ORDERS_MODAL:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'ORDERS_LIST_SENDER'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'ORDERS_LIST_SENDER')
        ) {
          modalQueue = [...modalQueue, 'ORDERS_LIST_SENDER'];
        }
        returnState = {
          ...state,
          modalQueue,
          senderApp: {
            ...get(state, 'senderApp', {}),
            ordersModal: action.payload,
          },
        };
        break;
      case ACTIONS.SET_AUTOCOMPLETE_CONTENT:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'AUTOCOMPLETE'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'AUTOCOMPLETE')
        ) {
          modalQueue = [...modalQueue, 'AUTOCOMPLETE'];
        }
        returnState = {
          ...state,
          modalQueue,
          autocomplete: action.payload,
        };
        break;
      case ACTIONS.SET_AUTOCOMPLETE_BANK_CONTENT:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'AUTOCOMPLETE_BANK'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'AUTOCOMPLETE_BANK')
        ) {
          modalQueue = [...modalQueue, 'AUTOCOMPLETE_BANK'];
        }
        returnState = {
          ...state,
          modalQueue,
          autocompleteBank: action.payload,
        };
        break;
      case ACTIONS.SET_AUTOCOMPLETE_LANG_CONTENT:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'AUTOCOMPLETE_LANG'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'AUTOCOMPLETE_LANG')
        ) {
          modalQueue = [...modalQueue, 'AUTOCOMPLETE_LANG'];
        }
        returnState = {
          ...state,
          modalQueue,
          autocompleteLang: action.payload,
        };
        break;
      case ACTIONS.SET_WEBVIEW_CONTENT:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'WEBVIEW'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'WEBVIEW')
        ) {
          modalQueue = [...modalQueue, 'WEBVIEW'];
        }
        returnState = {
          ...state,
          modalQueue,
          webview: action.payload,
        };
        break;
      case ACTIONS.SET_FILTER_CONTENT:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'FILTER'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'FILTER')
        ) {
          modalQueue = [...modalQueue, 'FILTER'];
        }
        returnState = {
          ...state,
          modalQueue,
          filter: action.payload,
        };
        break;
      case ACTIONS.SET_SELECT_MODAL_CONTENT:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          !action.payload &&
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'SELECT_MODAL'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        } else if (
          action.payload &&
          modalQueue.every(modal => modal !== 'SELECT_MODAL')
        ) {
          modalQueue = [...modalQueue, 'SELECT_MODAL'];
        }
        returnState = {
          ...state,
          modalQueue,
          selectModal: action.payload,
        };
        break;
      case ACTIONS.SAVE_NAVIGATION:
        returnState = {...state, navigation: get(action, 'payload', {})};
        break;
      case ACTIONS.SAVE_CURRENT_NAVIGATION_PAGE:
        returnState = {...state, currentPage: get(action, 'payload', '')};
        break;
      case ACTIONS.SET_WINDOW_SIZE:
        {
          let height = get(action, 'payload.window.height', 10);
          let width = get(action, 'payload.window.width', 10);
          let leftOffset = 0;
          let rightOffset = 0;
          if (Platform.OS === 'ios') {
            height =
              height -
              (StaticSafeAreaInsets.safeAreaInsetsTop +
                StaticSafeAreaInsets.safeAreaInsetsBottom);
            width =
              width -
              (StaticSafeAreaInsets.safeAreaInsetsLeft +
                StaticSafeAreaInsets.safeAreaInsetsRight);
          }

          returnState = {
            ...state,
            window:
              height > width || isTablet() || Platform.OS === 'web'
                ? {
                    height: Math.round(height || 1),
                    width: Math.round(width || 1),
                    leftOffset,
                    rightOffset,
                  }
                : {
                    width: Math.round(height || 1),
                    height: Math.round(width || 1),
                    leftOffset,
                    rightOffset,
                  },
          };
        }
        break;
      case ACTIONS.SET_SCREEN_ORIENTATION:
        returnState = {...state, orientation: get(action, 'payload', '')};
        break;
      case ACTIONS.SET_SHOW_CC:
        returnState = {...state, showCC: get(action, 'payload', false)};
        break;
      case ACTIONS.KEYBOARD_OPEN:
        returnState = {...state, keyboradOpen: get(action, 'payload', false)};
        break;
      case ACTIONS.SET_SERVER_CONSTANTS:
        returnState = action.payload
          ? {...state, serverConstants: action.payload}
          : state;
        break;
      case ACTIONS.SET_GOOGLE_SDK:
        returnState = action.payload
          ? {
              ...state,
              googleKey: action.payload,
            }
          : state;
        break;
      case ACTIONS.SAVE_CONFIRMATION_RES:
        returnState = {
          ...state,
          authConfirmation: get(action, 'payload', null),
        };
        break;
      case ACTIONS.CLOSE_MODAL:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'NOTIFICATION' &&
          (get(state, 'modal', []) || []).slice(1).length === 0
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        }
        returnState = {
          ...state,
          modal: (get(state, 'modal', []) || []).slice(1),
          modalQueue,
        };
        break;
      case ACTIONS.SET_APP:
        returnState = {...state, app: get(action, 'payload', 'welcome')};
        break;
      case ACTIONS.HIDE_LOADER:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (
          modalQueue.length > 0 &&
          modalQueue[modalQueue.length - 1] === 'LOADER'
        ) {
          modalQueue = modalQueue.slice(0, modalQueue.length - 1);
        }
        returnState = {...state, loader: false, modalQueue};
        break;
      case ACTIONS.SHOW_LOADER:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (modalQueue.every(modal => modal !== 'LOADER')) {
          modalQueue = [...modalQueue, 'LOADER'];
        }
        returnState = {
          ...state,
          loader: get(action, 'payload', 'orange'),
          modalQueue,
        };
        break;
      case ACTIONS.OPEN_MODAL:
        modalQueue = get(state, 'modalQueue', []) || [];
        if (modalQueue.every(modal => modal !== 'NOTIFICATION')) {
          modalQueue = [...modalQueue, 'NOTIFICATION'];
        }
        removeDups = [{...action.payload, isVisible: true}];
        (get(state, 'modal', []) || []).forEach(modal => {
          if (removeDups.every(dup => dup.type !== modal.type)) {
            removeDups.push(modal);
          }
        });
        returnState = {
          ...state,
          modal: [...removeDups.slice(0, 3)],
          modalQueue,
        };
        break;
      case ACTIONS.LOCATION_TIME:
        returnState = {
          ...state,
          locationTime: Date.now(),
        };
        break;
      case ACTIONS.SAVE_COURIER_LOCATION:
        returnState = {
          ...state,
          courierLocation: action.payload,
        };
        break;
      case ACTIONS.SET_USER:
        returnState = {
          ...state,
          user: get(action, 'payload', {uid: '', name: ''}),
          userInProgress: false,
          offline: false,
        };
        break;

      case ACTIONS.COURIER_SET_USER_DATA:
        returnState = {
          ...state,
          user: get(action, 'payload', {}),
          userInProgress: false,
        };
        break;
      case ACTIONS.SET_USER_DATA:
        returnState = {
          ...state,
          userInProgress: false,
          user: {
            ...get(state, 'user', {}),
            ...action.payload,
            temp: false,
          },
        };
        break;
      case ACTIONS.SET_TEMP_USER_DATA:
        returnState = {
          ...state,
          user: {
            ...get(state, 'user', {}),
            ...action.payload,
            temp: true,
          },
        };
        break;
      case ACTIONS.SENDER_GET_DATA:
        returnState = {
          ...state,
          userInProgress: false,
          user: {
            ...get(state, 'user', {}),
            ...action.payload.user,
            temp: false,
          },
          senderApp: {
            ...get(state, 'senderApp', {}),
            orders: get(action, 'payload.orders', []) || [],
            offers: get(action, 'payload.offers', []) || [],
          },
        };
        break;
      case ACTIONS.SENDER_REPORT_ABUSE:
        returnState = {
          ...state,
          senderApp: {
            ...get(state, 'senderApp', {}),
            orders: (get(state, 'senderApp.orders', []) || []).map(order =>
              order.id === get(action, 'payload.id', '')
                ? get(action, 'payload', {})
                : order,
            ),
          },
        };
        break;
      case ACTIONS.SENDER_SEND_REVIEW:
        returnState = {
          ...state,
          senderApp: {
            ...get(state, 'senderApp', {}),
            orders: (get(state, 'senderApp.orders', []) || []).map(order =>
              order.id === get(action, 'payload.id', '')
                ? get(action, 'payload', {})
                : order,
            ),
          },
        };
        break;
      case ACTIONS.SENDER_CANCEL_ORDER:
        returnState = {
          ...state,
          senderApp: {
            ...get(state, 'senderApp', {}),
            orders: (get(state, 'senderApp.orders', []) || []).map(order =>
              order.id === get(action, 'payload.id', '')
                ? get(action, 'payload', {})
                : order,
            ),
          },
        };
        break;
      case ACTIONS.SENDER_CONFIRM_PICKUP:
        returnState = {
          ...state,
          senderApp: {
            ...get(state, 'senderApp', {}),
            orders: (get(state, 'senderApp.orders', []) || []).map(order =>
              order.id === get(action, 'payload.id', '')
                ? get(action, 'payload', {})
                : order,
            ),
          },
        };
        break;
      case ACTIONS.ADD_NOTIFICATION:
        {
          let notifications = [
            ...(state.notifications || []),
            ...get(action, 'payload', []),
          ];
          let removeDup = [];
          notifications.forEach(not => {
            if (removeDup.every(n => n.id !== not.id)) {
              removeDup.push(not);
            }
          });
          returnState = action.payload
            ? {...state, notifications: removeDup}
            : state;
        }
        break;
      case ACTIONS.DELETE_NOTIFICATION:
        returnState = {
          ...state,
          notifications: action.payload
            ? (state.notifications || []).filter(
                not => not.id !== action.payload,
              )
            : [],
        };
        break;
      case ACTIONS.USER_LOGOUT:
        returnState = {
          ...state,
          chats: {},
          receiverApp: {
            confirmedOrders: [],
            orders: [],
            offers: [],
            courierRoutes: [],
          },
          senderApp: {
            fields: {
              fromAddress: {},
              toAddress: {},
              size: '',
              time: '',
              description: '',
              receiptName: '',
              needReceipt: false,
              receiverPhone: '',
              receiverName: '',
              sendingToSelf: false,
              backAndFoth: false,
              pickupTime: '',
              volunteerOrder: false,
              volunteerOrganizationName: '',
              numberOfPackages: null,
            },
            orders: [],
            offers: [],
          },
          user: {
            uid: '',
            phone: '',
            name: '',
          },
          notifications: [],
        };
        break;
      case ACTIONS.SAVE_CARD_LOCAL:
        returnState = {
          ...state,
          user: {
            ...get(state, 'user', ''),
            savedCard: get(action, 'payload', {}),
          },
        };
        break;
      case ACTIONS.DELETE_CREDIT_CARD:
        returnState = {
          ...state,
          user: {
            ...get(state, 'user', ''),
            savedCard: null,
          },
          senderApp: {
            ...get(state, 'senderApp', {}),
            fields: {
              ...get(state, 'senderApp.fields', {}),
              creditCard: undefined,
            },
          },
        };
        break;
      case ACTIONS.SAVE_PACKAGE_FIELDS:
        returnState = action.payload
          ? {
              ...state,
              senderApp: {
                ...get(state, 'senderApp', {}),
                fields: {
                  ...get(state, 'senderApp.fields', {}),
                  ...action.payload,
                },
              },
            }
          : state;
        break;
      case ACTIONS.SENDER_SAVE_PRICES:
        returnState = action.payload
          ? {
              ...state,
              senderApp: {
                ...get(state, 'senderApp', {}),
                availablePrices: action.payload,
              },
            }
          : state;
        break;
      case ACTIONS.SAVE_CREDIT_CARD:
        returnState = {
          ...state,
          user: {
            ...get(state, 'user', ''),
            savedCard: get(action, 'payload', {}),
          },
        };
        break;
      case ACTIONS.SENDER_GET_ORDERS:
        returnState = {
          ...state,
          senderApp: {
            ...get(state, 'senderApp', {}),
            orders: get(action, 'payload', []),
          },
        };
        break;
      case ACTIONS.SENDER_GET_PACKAGE:
        returnState = {
          ...state,
          senderApp: {
            ...get(state, 'senderApp', {}),
            orders: (get(state, 'senderApp.orders', []) || []).map(order =>
              get(order, 'id', '') !== get(action, 'payload.id', '')
                ? order
                : get(action, 'payload', ''),
            ),
          },
        };
        break;
      case ACTIONS.SENDER_ACCEPT_OFFER:
        returnState = {
          ...state,
          senderApp: {
            ...get(state, 'senderApp', {}),
            orders: (get(state, 'senderApp.orders', []) || []).map(order =>
              get(order, 'id', '') !== get(action, 'payload.id', '')
                ? order
                : get(action, 'payload', ''),
            ),
            offers: (get(state, 'senderApp.offers', []) || []).filter(
              offer => get(offer, 'orderId') !== get(action, 'payload.id', ''),
            ),
          },
        };
        break;
      case ACTIONS.COURIER_GET_PACKAGE:
        if (
          (get(state, 'receiverApp.confirmedOrders', []) || []).some(
            order => get(order, 'id', '') === get(action, 'payload.id', ''),
          )
        ) {
          returnState = {
            ...state,
            receiverApp: {
              ...get(state, 'receiverApp', {}),
              confirmedOrders: (
                get(state, 'receiverApp.confirmedOrders', []) || []
              ).map(order =>
                get(order, 'id', '') !== get(action, 'payload.id', '')
                  ? order
                  : get(action, 'payload', ''),
              ),
            },
          };
        } else {
          returnState = {
            ...state,
            receiverApp: {
              ...get(state, 'receiverApp', {}),
              confirmedOrders: [
                ...(get(state, 'receiverApp.confirmedOrders', []) || []),
                get(action, 'payload', {}),
              ],
            },
          };
        }
        break;
      case ACTIONS.SENDER_ADD_ORDER:
        returnState = {
          ...state,
          user: get(action, 'payload.user', {}),
          senderApp: {
            ...get(state, 'senderApp', {}),
            orders: [
              ...(get(state, 'senderApp.orders', []) || []).filter(
                order => order.id !== get(action, 'payload.id', ''),
              ),
              get(action, 'payload.order', {}),
            ],
          },
        };
        break;
      case ACTIONS.SET_USER_IN_PROGRESS:
        returnState = {
          ...state,
          userInProgress: true,
        };
        break;
      case ACTIONS.COURIER_GET_DATA:
        returnState = {
          ...state,
          userInProgress: false,
          user: {
            ...get(state, 'user', {}),
            ...action.payload.user,
            temp: false,
          },
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            confirmedOrders: get(action, 'payload.orders', []) || [],
            offers: get(action, 'payload.offers', []) || [],
          },
        };
        break;
      case ACTIONS.COURIER_GET_ALL_PAYMENTS:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            payments: get(action, 'payload', []) || [],
          },
        };
        break;
      case ACTIONS.SET_PAYMENT:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            payments: (get(state, 'receiverApp.payments', []) || []).map(
              payment =>
                payment.id === get(action, 'payload.id')
                  ? get(action, 'payload', {})
                  : payment,
            ),
          },
        };
        break;
      case ACTIONS.COURIER_CANCEL_OFFER:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            offers: get(action, 'payload.offers', []).filter(
              offer => offer.id !== get(action, 'payload', ''),
            ),
          },
        };
        break;
      case ACTIONS.COURIER_GET_CONFIRMED_ORDERS:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            confirmedOrders: get(action, 'payload', []),
          },
        };
        break;
      case ACTIONS.COURIER_SET_ORDER_STATUSES:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            orderStatuses: get(action, 'payload', []),
          },
        };
        break;
      case ACTIONS.COURIER_CONFIRM_PICKUP:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            confirmedOrders: (
              get(state, 'receiverApp.confirmedOrders', []) || []
            ).map(order =>
              order.id === get(action, 'payload.id')
                ? get(action, 'payload')
                : order,
            ),
          },
        };
        break;
      case ACTIONS.COURIER_CANCEL_ORDER:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            confirmedOrders: (
              get(state, 'receiverApp.confirmedOrders', []) || []
            ).map(order =>
              order.id === get(action, 'payload.id')
                ? get(action, 'payload')
                : order,
            ),
          },
        };
        break;
      case ACTIONS.HIDE_WARNING_BEFORE_ACCEPTING_ORDER:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            hideWarningBeforeAccepting: true,
          },
        };
        break;
      case ACTIONS.COURIER_CONFIRM_DELIVERY:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            confirmedOrders: (
              get(state, 'receiverApp.confirmedOrders', []) || []
            ).map(order =>
              order.id === get(action, 'payload.id')
                ? get(action, 'payload')
                : order,
            ),
          },
        };
        break;
      case ACTIONS.SAVE_SHARE_LINK:
        returnState = {
          ...state,
          shareLink: get(action, 'payload'),
        };
        break;
      case ACTIONS.COURIER_ACCEPT_ORDER:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            confirmedOrders: [
              ...(get(state, 'receiverApp.confirmedOrders', []) || []),
              get(action, 'payload', {}),
            ],
            orders: (get(state, 'receiverApp.orders', []) || []).filter(
              o => o.id !== get(action, 'payload.id', ''),
            ),
          },
        };
        break;
      case ACTIONS.COURIER_ACCEPT_ORDERS_GROUP:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            confirmedOrders: [
              ...(get(state, 'receiverApp.confirmedOrders', []) || []),
              ...get(action, 'payload', []),
            ],
          },
        };
        break;
      case ACTIONS.COURIER_SEND_OFFER:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            offers: [
              ...(get(state, 'receiverApp.offers', []) || []),
              get(action, 'payload.offer', {}),
            ],
          },
        };
        break;
      case ACTIONS.COURIER_GET_ORDERS:
        {
          let orders = [
            ...(get(action, 'payload', []) || []),
            ...(get(state, 'receiverApp.orders', []) || []),
            ...(get(state, 'receiverApp.ordersCache', []) || []),
          ];
          let removeDupOr = [];
          orders.forEach(or => {
            if (removeDupOr.every(n => n.id !== or.id)) {
              removeDupOr.push(or);
            }
          });
          returnState = {
            ...state,
            receiverApp: {
              ...get(state, 'receiverApp', {}),
              orders: get(action, 'payload', []),
              ordersCache: removeDupOr,
            },
          };
        }
        break;
      case ACTIONS.COURIER_REMOVE_ORDER:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            orders: (get(state, 'receiverApp.orders', []) || []).filter(
              order => order.id !== get(action, 'payload', ''),
            ),
          },
        };
        break;
      case ACTIONS.COURIER_GET_ORDER:
        if (action.payload) {
          let orders = [
            ...(get(state, 'receiverApp.orders', []) || []),
            action.payload,
          ];
          let removeDupOr = [];
          orders.forEach(or => {
            if (removeDupOr.every(n => n.id !== or.id)) {
              removeDupOr.push(or);
            }
          });
          returnState = {
            ...state,
            receiverApp: {
              ...get(state, 'receiverApp', {}),
              orders: removeDupOr,
            },
          };
        } else {
          returnState = state;
        }
        break;
      case ACTIONS.COURIER_GET_ALL_OFFERS:
        returnState = {
          ...state,
          receiverApp: {
            ...get(state, 'receiverApp', {}),
            offers: get(action, 'payload', []),
          },
        };
        break;
      case ACTIONS.SENDER_GET_ALL_OFFERS:
        returnState = {
          ...state,
          senderApp: {
            ...get(state, 'senderApp', {}),
            offers: get(action, 'payload', []),
          },
        };
        break;
      case ACTIONS.INITIAL_URL_NAVIGATED:
        returnState = {
          ...state,
          initialURLNavigated: true,
        };
        break;
      case ACTIONS.SET_KEYBOARDACCESS_BUTTONS:
        returnState = {
          ...state,
          keyboardAccessButtons: action.payload,
        };
        break;
      default:
        returnState = state;
        break;
    }
    if (returnState.restored || action.type === ACTIONS.USER_LOGOUT) {
      saveState(returnState);
    }
    return returnState;
  } catch (err) {
    Analytics.reportError(err);
    return state;
  }
};

export default reduce;
