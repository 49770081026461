import {logEvent, setUserProperties} from 'firebase/analytics';
import {get} from 'lodash';
import {apiRequest} from './function';
import {
  getAppBuildNumber,
  getAppVersion,
  getUniqueDviceId,
} from './nativeFunctions';
import {
  formfactor,
  landscape,
  android,
  chromeos,
  tizen,
  ios,
  linuxBased,
  windows,
  macos,
  linux,
  edge,
  chrome,
  firefox,
  opera,
  samsungBrowser,
  safari,
  ie,
} from 'platform-detect';
import {analytics} from './firebase';

let browser = '';
if (edge) {
  browser = 'edge';
}
if (chrome) {
  browser = 'chrome';
}
if (firefox) {
  browser = 'firefox';
}
if (opera) {
  browser = 'opera';
}
if (samsungBrowser) {
  browser = 'samsungBrowser';
}
if (safari) {
  browser = 'safari';
}
if (ie) {
  browser = 'ie';
}

let manufactor = '';
if (android) {
  manufactor = 'android';
}
if (chromeos) {
  manufactor = 'chromeos';
}
if (android) {
  manufactor = 'android';
}
if (tizen) {
  manufactor = 'tizen';
}
if (ios) {
  manufactor = 'ios';
}
if (linuxBased) {
  manufactor = 'linuxBased';
}
if (windows) {
  manufactor = 'windows';
}
if (macos) {
  manufactor = 'macos';
}
if (linux) {
  manufactor = 'linux';
}

class Analytics {
  static async sendToServer(log) {
    await apiRequest('/api_v1/log', log, 'POST');
  }

  static async reportGetPrices(prices) {
    try {
      const params = {
        item_list_id: 'prices1',
        item_list_name: 'Calculated Prices',
        items: prices.map(price => ({
          item_id: price.type + '-' + price.time,
          item_name: price.type,
          item_category: price.type,
          item_variant: price.type,
          item_brand: price.type,
          price: price.price,
          currency: 'ILS',
        })),
      };
      logEvent(analytics, 'view_item_list', params);
    } catch (e) {
      console.log(e);
    }
  }

  static async reportSelectPrice(price) {
    try {
      const params = {
        item_list_id: 'prices1',
        item_list_name: 'Calculated Prices',
        items: [
          {
            item_id: price.type + '-' + price.time,
            item_name: price.type,
            item_category: price.type,
            item_variant: price.type,
            item_brand: price.type,
            price: price.price,
            currency: 'ILS',
          },
        ],
      };
      // Log event
      logEvent(analytics, 'select_item', params);
    } catch (e) {
      console.log(e);
    }
  }

  static async reportPostOrder(order) {
    try {
      const params = {
        transaction_id: get(order, 'id', ''),
        affiliation: 'Bambizz Order',
        currency: 'ILS',
        value: get(order, 'bambizzPrice', 1),
        tax: get(order, 'bambizzPrice', 1) * 0.17,
        shipping: 0,
        items: [
          {
            item_id: get(order, 'id', ''),
            item_name: 'post order',
            coupon: get(order, 'discountId', ''),
            affiliation: 'Bambizz Order',
            price: get(order, 'bambizzPrice', 1),
            currency: 'ILS',
            quantity: 1,
          },
        ],
      };

      logEvent(analytics, 'purchase', params);
    } catch (e) {
      console.log(e);
    }
  }

  static async reportCourierOrderInteraction(event, orderId, uid) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'COURIER_ORDER_INTERACTION',
        message: event,
        deviceID: uniqueID,
        orderId,
        attachment: JSON.stringify({
          event,
          orderId,
          uid,
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  static async reportError(error, uid, isFatal) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'ERROR',
        message: get(error, 'message', ''),
        deviceID: uniqueID,
        attachment: JSON.stringify({
          stack: error.stack,
          name: error.name,
          message: error.message,
          fileName: error.fileName,
          lineNumber: error.lineNumber,
          columnNumber: error.columnNumber,
          isFatal,
        }),
      });

      logEvent(analytics, 'silent_exception', {
        uid: uid || '',
        message: get(error, 'message', ''),
        description: error.toString(),
      });
    } catch (err) {
      //console.error(err)
    }
  }

  static async reportPage(page) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'PAGE',
        deviceID: uniqueID,
        message: 'Page: ' + (page || ''),
        attachment: JSON.stringify({page}),
      });
      logEvent(analytics, 'screen_view', {
        firebase_screen: page,
        firebase_screen_class: page,
      });
    } catch (err) {
      //console.error(err)
    }
  }

  static async reportSendSMS(phone) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'SMS',
        deviceID: uniqueID,
        message: 'Send sms to ' + (phone || ''),
        attachment: JSON.stringify({phone}),
      });
      logEvent(analytics, 'send_sms');
    } catch (err) {
      //        reportError(err)
    }
  }

  static async reportConfirm(phone) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'CONFIRM',
        deviceID: uniqueID,
        message: 'Confirm phone ' + (phone || ''),
        attachment: JSON.stringify({phone}),
      });
      logEvent(analytics, 'login', {
        method: 'phone',
      });
    } catch (err) {
      //        reportError(err)
    }
  }

  static async reportLogout(uid) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'LOGOUT',
        deviceID: uniqueID,
        message: 'Logout',
        attachment: JSON.stringify({uid}),
      });
      logEvent(analytics, 'logout');
    } catch (err) {
      //        reportError(err)
    }
  }

  static async reportAPICall(api, uid, success) {
    try {
      /*let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'API',
        deviceID: uniqueID,
        message: 'Call API: ' + (api || ''),
        attachment: JSON.stringify({
          api,
          uid,
          success,
        }),
      });*/
      logEvent(analytics, 'api_call', {
        uid: uid || '',
        api: api,
        success: success,
      });
    } catch (err) {
      //        reportError(err)
    }
  }

  static async reportSenderEvent(event, uid, payload = {}) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'SENDER',
        deviceID: uniqueID,
        message: 'Sender Event: ' + (event || ''),
        attachment: JSON.stringify({
          event,
          ...payload,
        }),
      });
      logEvent(analytics, 'sender_event', {
        type: event,
        uid: uid,
      });
    } catch (err) {
      //        reportError(err)
    }
  }

  static async reportCourierEvent(event, uid) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'COURIER',
        deviceID: uniqueID,
        message: 'Courier Event: ' + (event || ''),
        attachment: JSON.stringify({
          uid,
          event,
        }),
      });
      logEvent(analytics, 'courier_event', {
        type: event,
        uid: uid,
      });
    } catch (err) {
      //        reportError(err)
    }
  }

  static async reportNotification(notification, uid) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'NOTIFICATION',
        deviceID: uniqueID,
        message: 'Got notification',
        attachment: JSON.stringify({
          uid,
          notification,
        }),
      });
      logEvent(analytics, 'notification', {
        content: JSON.stringify(notification),
        uid: uid,
      });
    } catch (err) {
      //        reportError(err)
    }
  }

  static async registerDevice(lang, FCMToken) {
    let deviceName = get(navigator, 'appVersion', '');
    let deviceModel = formfactor;
    let firstInstall = -1;
    let fontScale = window.visualViewport.scale;
    let refferer = '';
    let lastUpdate = -1;
    let power = {};
    try {
      power = await navigator.getBattery();
    } catch (err) {}
    let isTablet = formfactor === 'tablet';
    let uniqueID = await getUniqueDviceId();
    await apiRequest(
      '/apisender_v1/register-device',
      {
        FCMToken,
        lang,
        OS: 'web',
        deviceName,
        AppVersion: getAppVersion(),
        DeviceVersion: '',
        Brand: browser,
        build: parseInt(getAppBuildNumber(), 10),
        deviceModel,
        firstInstall,
        fontScale,
        refferer,
        lastUpdate,
        manufactor,
        power: {
          batteryState: get(power, 'charging') ? 'charging' : 'not-charging',
          batteryLevel: get(power, 'level', -1) || -1,
        },
        isTablet,
        landscape,
        uniqueID,
        isInternetReachable: get(navigator, 'onLine', false),
        type: get(navigator, 'connection.type', ''),
        downlink: get(navigator, 'connection.downlink', ''),
        downlinkMax: get(navigator, 'connection.downlinkMax ', ''),
        rtt: get(navigator, 'connection.rtt', ''),
        cellularGeneration: get(navigator, 'connection.effectiveType ', ''),
        isWifiEnabled: false,
        isConnectionExpensive: false,
        strength: 0,
        frequency: 0,
        carrier: '',
      },
      'POST',
    );
  }

  static async reportClientDetails(lang) {
    try {
      let deviceName = get(navigator, 'appVersion', '');
      let deviceModel = formfactor;
      let firstInstall = -1;
      let fontScale = window.visualViewport.scale;
      let refferer = '';
      let lastUpdate = -1;
      let power = {};
      try {
        power = await navigator.getBattery();
      } catch (err) {}
      let isTablet = formfactor === 'tablet';
      let uniqueID = await getUniqueDviceId();

      this.sendToServer({
        type: 'DETAILS',
        message: 'Client Details',
        deviceID: uniqueID,
        attachment: JSON.stringify({
          lang,
          OS: 'web',
          deviceName,
          AppVersion: getAppVersion(),
          DeviceVersion: '',
          Brand: browser,
          build: parseInt(getAppBuildNumber(), 10),
          deviceModel,
          firstInstall,
          fontScale,
          refferer,
          lastUpdate,
          manufactor,
          power,
          isTablet,
          landscape,
          uniqueID,
          isInternetReachable: get(navigator, 'onLine', false),
          type: get(navigator, 'connection.type', ''),
          downlink: get(navigator, 'connection.downlink', ''),
          downlinkMax: get(navigator, 'connection.downlinkMax ', ''),
          rtt: get(navigator, 'connection.rtt', ''),
          cellularGeneration: get(navigator, 'connection.effectiveType ', ''),
        }),
      });
      setUserProperties(analytics, {
        lang,
        deviceName,
        AppVersion: getAppVersion(),
        DeviceVersion: '',
        Brand: browser,
      });
    } catch (err) {
      console.log(err);
    }
  }

  static async reportClientDetectedLocation(location, source) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'LOCATION',
        deviceID: uniqueID,
        message: 'Report location',
        attachment: JSON.stringify({}),
      });
      logEvent(analytics, 'client_location');
    } catch (err) {
      //        reportError(err)
    }
  }

  static async initializeUser() {}

  static async reportShowModal(title, text, type) {
    try {
      let uniqueID = await getUniqueDviceId();
      this.sendToServer({
        type: 'MODAL',
        deviceID: uniqueID,
        message: 'Report modal show: ' + (type || ''),
        attachment: JSON.stringify({
          title,
          text,
          type,
        }),
      });
      logEvent(analytics, 'show_modal', {
        title: title || '',
        text: text || '',
      });
    } catch (err) {
      //        reportError(err)
    }
  }
}

export default Analytics;
