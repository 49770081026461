import React from 'react';
import Analytics from '../../common/analytics';
import {reloadApp} from '../../common/nativeFunctions';
import getLang from '../../language/lang';
import Button from './Button';
import Div from './Div';
import Span from './Span';
import Title from './Title';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, error: {}, errorInfo: {}};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true, error: error || {}};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    Analytics.reportError(error);
  }

  render() {
    if (this.state.hasError) {
      const lang = getLang();
      return (
        <Div
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Div>
            <Title style={{marginBottom: 30}}>
              {lang['something-went-wrong']}
            </Title>
            <details style={{whiteSpace: 'pre-wrap', margin: '20px'}}>
              <Span>{this.state.error && this.state.error.toString()}</Span>
              <Span>{this.state.errorInfo.componentStack}</Span>
            </details>
            <Button theme="purple" onClick={() => reloadApp()}>
              {lang.reload}
            </Button>
          </Div>
        </Div>
      );
    }

    return this.props.children;
  }
}
