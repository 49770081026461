import he from './he.json';
import en from './en.json';
import enImperial from './en_imperial.json';
import React from 'react';
import {getQueryParams} from '../common/function';

const enImperialMerged = {...en, ...enImperial};

export const getCountry = () => {
  if (navigator?.languages || navigator?.language) {
    let languages = (navigator?.languages || [])
      .map(
        lang =>
          /^[a-z\d]{2,4}-(?<country>[A-Z\d]{2,4})$/.exec(lang)?.groups?.country,
      )
      .filter(x => x);
    let language =
      /^[a-z\d]{2,4}-(?<country>[A-Z\d]{2,4})$/.exec(navigator?.language || '')
        ?.groups?.country || '';
    if (language) {
      return language;
    }
    if (languages.length) {
      return languages[0];
    }
    return 'US';
  }
};

export const getLangId = () => {
  if (navigator?.languages || navigator?.language) {
    let languages = (navigator?.languages || [])
      .map(lang => /^(?<langId>[a-z\d]{2,4})/.exec(lang)?.groups?.langId)
      .filter(x => x);
    let language =
      /^(?<langId>[a-z\d]{2,4})/.exec(navigator?.language || '')?.groups
        ?.langId || '';
    if (language) {
      return language;
    }
    if (languages.length) {
      return languages[0];
    }
    return 'en';
  }
};

export const LANGUAGES = {
  HE: 'HE',
  EN: 'EN',
  EN_IMPERIAL: 'EN_IMPERIAL',
};

const LanguagesMap = {
  [LANGUAGES.EN]: en,
  [LANGUAGES.HE]: he,
  [LANGUAGES.EN_IMPERIAL]: enImperialMerged,
};

const setHTMLLang = lang => {
  try {
    document.getElementById('html-tag').lang = lang.toLowerCase();
    document.getElementById('general-script').innerText = `
    * {
      direction: ${lang === LANGUAGES.HE ? 'rtl' : 'ltr'};
    }
    `;
    document.getElementById('page-title').innerText =
      lang === LANGUAGES.HE ? he['page-title'] : en['page-title'];
    document.getElementById('page-description').content =
      lang === LANGUAGES.HE ? he['page-description'] : en['page-description'];
    document.getElementById(
      'desktop-canonical',
    ).href = `https://bambizz.com/${lang.toLowerCase()}/application${
      window.location.pathname
    }`;
  } catch (err) {
    // Analytics.reportError(err);
  }
};

export const flipDirection = () => {
  return false;
};
export const LanguageContext = React.createContext({});
export const setLang = () => {};
export const getSystemLang = () => {
  let languageCode = getLangId().toLowerCase(),
    countryCode = getCountry().toUpperCase();
  if (languageCode === 'en') {
    if (countryCode === 'US') {
      return LANGUAGES.EN_IMPERIAL;
    }
    return LANGUAGES.EN;
  }
  if (['he', 'iw'].includes(languageCode)) {
    return LANGUAGES.HE;
  }
  return LANGUAGES.EN;
};
const getLang = str => {
  if (LanguagesMap[str]) {
    setHTMLLang(str);
    return LanguagesMap[str];
  }
  const paramsLang = getQueryParams(
    document.location.search,
  )?.lang?.toUpperCase?.();
  if (Object.keys(LanguagesMap).includes(paramsLang)) {
    setHTMLLang(paramsLang);
    return LanguagesMap[paramsLang];
  }
  const systemLang = getSystemLang();
  if (LanguagesMap[systemLang]) {
    setHTMLLang(systemLang);
    return LanguagesMap[systemLang];
  }
  setHTMLLang(LANGUAGES.EN);
  return en;
};

export default getLang;
