import {runFunction} from '../debug/useDebug';
import {makeApiCall, showMessage} from '../common/function';
import {ACTIONS, CONSTANTS} from '../common/constants';
import {hideLoader, setNavigation, showLoader} from './actions';
import {get} from 'lodash';
import Analytics from '../common/analytics';
import {getRealTimeDB, onAuthStateChanged} from '../common/firebase';

export const initializeSender = navigation => {
  return async (dispatch, getState) => {
    runFunction('initializeSender');
    try {
      dispatch(setNavigation(navigation));

      let initialized = false;
      onAuthStateChanged(async user => {
        if (user && !initialized) {
          initialized = true;
          dispatch(getSenderData());
          getRealTimeDB()
            .ref('language/' + get(user, 'uid', ''))
            .set(getState().lang)
            .catch(console.log);
        }
      });
    } catch (err) {
      Analytics.reportError(err);
    }
  };
};

export const senderGetAllPackages = () => {
  return async (dispatch, getState) => {
    runFunction('senderGetAllPackages');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/get-all-orders',
      action: ACTIONS.SENDER_GET_ORDERS,
      parseRes: res => res.orders,
    });
  };
};

const sendChatMessage = (dispatch, getState, message, timeout = 500) => {
  makeApiCall(dispatch, getState, {
    url: '/apisender_v1/send-chat-message',
    action: ACTIONS.NONE,
    payload: message,
    callback: res => {
      if (!res.success) {
        setTimeout(() => {
          sendChatMessage(dispatch, getState, message, timeout * 2);
        }, timeout);
      }
    },
  });
};

export const senderPostMessage = message => {
  return async (dispatch, getState) => {
    runFunction('senderPostMessage');
    sendChatMessage(dispatch, getState, message);
  };
};

export const senderReadMessage = data => {
  return async (dispatch, getState) => {
    runFunction('senderReadMessage');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/read-chat-messages',
      action: ACTIONS.NONE,
      payload: data,
    });
  };
};

export const senderGetPackage = (orderId, callback) => {
  return async (dispatch, getState) => {
    runFunction('senderGetPackage');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/get-order',
      payload: {orderId},
      action: ACTIONS.SENDER_GET_PACKAGE,
      parseRes: res => res.order,
      callback,
    });
  };
};

export const senderGetPrice = (
  fields,
  callback,
  duplicateProtectionTimestamp = Date.now(),
) => {
  console.log('senderGetPrice', fields);
  return async (dispatch, getState) => {
    runFunction('senderGetPrice');
    dispatch(showLoader('create-package'));
    if (get(getState(), 'user.temp')) {
      dispatch(
        senderGetUserData(res => {
          if (res.success) {
            dispatch(
              senderGetPrice(fields, callback, duplicateProtectionTimestamp),
            );
          } else {
            setTimeout(() => {
              dispatch(
                senderGetPrice(fields, callback, duplicateProtectionTimestamp),
              );
            }, 2000);
          }
        }),
      );
      return;
    }
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/add-order',
      payload: {
        ...fields,
        duplicateProtectionTimestamp,
        buildNum: CONSTANTS.buildNum,
        timeZoneOffset: new Date().getTimezoneOffset(),
      },
      action: ACTIONS.SENDER_ADD_ORDER,
      parseRes: res => ({order: res.order, user: res.user}),
      callback: res => {
        dispatch(hideLoader());
        if (res.success) {
          Analytics.reportSenderEvent(
            'post-new-package',
            get(getState(), 'user.uid', ''),
          );
        } else {
          showMessage(dispatch, 'error', res.code);
        }
        if (typeof callback === 'function') {
          callback(res);
        }
      },
    });
  };
};

export const senderAcceptOffer = fields => {
  return async (dispatch, getState) => {
    let result = await showMessage(
      dispatch,
      'confirmation',
      'are-you-sure-qs',
      ['yes', 'no'],
    );
    if (result === 'no') {
      return;
    }
    runFunction('senderAcceptOffer');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/accept-offer',
      payload: fields,
      action: ACTIONS.SENDER_ACCEPT_OFFER,
      showLoader: true,
      parseRes: res => res.order,
      callback: res => {
        if (res.success) {
          Analytics.reportSenderEvent(
            'accept-offer',
            get(getState(), 'user.uid', ''),
          );
          dispatch(senderGetUserData());
        }
      },
    });
  };
};

export const senderCancelOrder = fields => {
  return async (dispatch, getState) => {
    runFunction('senderCancelOrder');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/delete-order',
      payload: fields,
      action: ACTIONS.SENDER_CANCEL_ORDER,
      parseRes: res => res.order,
      callback: res => {
        if (res.success) {
          Analytics.reportSenderEvent(
            'cancel-order',
            get(getState(), 'user.uid', ''),
          );
        }
      },
    });
  };
};

export const senderConfirmPickup = fields => {
  return async (dispatch, getState) => {
    let result = await showMessage(
      dispatch,
      'confirmation',
      'are-you-sure-confirm-pickup-qs',
      ['yes', 'no'],
    );
    if (result === 'no') {
      return;
    }
    runFunction('senderConfirmPickup');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/confirm-pickup',
      payload: fields,
      action: ACTIONS.SENDER_CONFIRM_PICKUP,
      parseRes: res => res.order,
      showLoader: true,
      callback: res => {
        if (res.success) {
          Analytics.reportSenderEvent(
            'confirm-pickup',
            get(getState(), 'user.uid', ''),
          );
        }
      },
    });
  };
};

export const senderSendReview = fields => {
  return async (dispatch, getState) => {
    runFunction('senderSendReview');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/rate-order',
      payload: fields,
      action: ACTIONS.SENDER_SEND_REVIEW,
      parseRes: res => res.order,
    });
  };
};

export const senderGetAllOffers = () => {
  return async (dispatch, getState) => {
    runFunction('senderGetAllOffers');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/get-all-offers',
      action: ACTIONS.SENDER_GET_ALL_OFFERS,
      parseRes: res => res.offers,
    });
  };
};

export const senderReportAbuse = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('senderReportAbuse');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/report-a-problem',
      payload: fields,
      action: ACTIONS.SENDER_REPORT_ABUSE,
      parseRes: res => res.order,
      showLoader: true,
      callback,
    });
  };
};

export const senderSaveCreditCard = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('senderSaveCreditCard');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/add-payment-method',
      payload: fields,
      action: ACTIONS.SAVE_CREDIT_CARD,
      parseRes: () => fields,
      callback,
    });
  };
};

export const senderDeleteCreditCard = (data, callback) => {
  return async (dispatch, getState) => {
    runFunction('senderDeleteCreditCard');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/delete-payment-method',
      payload: {},
      action: ACTIONS.DELETE_CREDIT_CARD,
      showLoader: true,
      callback,
    });
  };
};

export const getSenderData = (data = {}, callback) => {
  return async (dispatch, getState) => {
    runFunction('getSenderData');
    if (data.uid) {
      let user = {...data};
      dispatch({
        type: ACTIONS.SET_TEMP_USER_DATA,
        payload: user,
      });
      delete data.uid;
    }
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/get-all-data',
      payload: data,
      action: ACTIONS.SENDER_GET_DATA,
      parseRes: res => ({
        user: res.user,
        orders: res.orders,
        offers: res.offers,
      }),
      callback,
      retry: true,
    });
  };
};

export const senderGetUserData = callback => {
  return async (dispatch, getState) => {
    runFunction('senderGetUserData');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/get-user-data',
      payload: {},
      action: ACTIONS.SET_USER_DATA,
      parseRes: res => res.user,
      callback,
      retry: true,
    });
  };
};

export const senderSetUserData = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('setUserData');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/set-user-data',
      payload: fields,
      action: ACTIONS.SET_USER_DATA,
      parseRes: res => res.user,
      callback,
    });
  };
};

export const senderDeleteUser = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('senderDeleteUser');
    makeApiCall(dispatch, getState, {
      url: '/apisender_v1/delete-user',
      showLoader: true,
      payload: fields,
      action: ACTIONS.DELETE_USER,
      parseRes: res => res.user,
      callback,
    });
  };
};
