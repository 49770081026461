import React, {useEffect, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import {FilterContainer, Modal} from './Components';
import ModalComponent from './Modal';
import PackagesModalSender from './senderApp/PackagesModal';
import PackagesModalCourier from './courierApp/PackagesModal';
import OrderQuickView from './courierApp/OrderQuickView';
import {
  closeModal,
  hideOrderIdsModal,
  hideQuickShowOrder,
  hideSenderOrdersModal,
  hideAutoComplete,
  hideFilter,
  hideSelectModal,
  hideAutoCompleteBank,
  hideOrderGroupModal,
  hideAutoCompleteLang,
} from '../redux/actions';
import Loader from './Loader';
import AutoComplete from './AutoComplete';
import OpenWebView from './OpenWebView';
import SelectModal from './SelectModal';
import AutoCompleteBank from './AutoCompleteBank';
import PackagesGroupModal from './courierApp/PackagesGroupModal';
import AutoCompleteLang from './AutoCompleteLang';

const MODAL_TYPES = {
  NOTIFICATION: 'NOTIFICATION',
  ORDERS_LIST_SENDER: 'ORDERS_LIST_SENDER',
  ORDERS_LIST_COURIER: 'ORDERS_LIST_COURIER',
  ORDER_QUICK_VIEW: 'ORDER_QUICK_VIEW',
  LOADER: 'LOADER',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  AUTOCOMPLETE_BANK: 'AUTOCOMPLETE_BANK',
  AUTOCOMPLETE_LANG: 'AUTOCOMPLETE_LANG',
  FILTER: 'FILTER',
  WEBVIEW: 'WEBVIEW',
  SELECT_MODAL: 'SELECT_MODAL',
  ORDERS_GROUP_COURIER: 'ORDERS_GROUP_COURIER',
};

function ModalManagerComponent({modalQueue}) {
  const dispatch = useDispatch();

  let top = modalQueue.length > 0 ? modalQueue[modalQueue.length - 1] : 'NONE';

  const topRef = useRef(top);
  useEffect(() => {
    topRef.current = top;
  }, [top]);

  let noModal = false;
  let modalComponent = false;
  let packagesModalSender = false;
  let packagesModalCourier = false;
  let packagesGroupModal = false;
  let orderQuickView = false;
  let loader = false;
  let autoComplete = false;
  let autoCompleteBank = false;
  let autoCompleteLang = false;
  let openWebView = false;
  let filterContainer = false;
  let selectModal = false;

  switch (top) {
    case MODAL_TYPES.NOTIFICATION:
      modalComponent = true;
      break;
    case MODAL_TYPES.ORDERS_LIST_SENDER:
      packagesModalSender = true;
      break;
    case MODAL_TYPES.ORDERS_LIST_COURIER:
      packagesModalCourier = true;
      break;
    case MODAL_TYPES.ORDER_QUICK_VIEW:
      orderQuickView = true;
      break;
    case MODAL_TYPES.LOADER:
      loader = true;
      break;
    case MODAL_TYPES.AUTOCOMPLETE:
      autoComplete = true;
      break;
    case MODAL_TYPES.AUTOCOMPLETE_BANK:
      autoCompleteBank = true;
      break;
    case MODAL_TYPES.AUTOCOMPLETE_LANG:
      autoCompleteLang = true;
      break;
    case MODAL_TYPES.WEBVIEW:
      openWebView = true;
      break;
    case MODAL_TYPES.FILTER:
      filterContainer = true;
      break;
    case MODAL_TYPES.SELECT_MODAL:
      selectModal = true;
      break;
    case MODAL_TYPES.ORDERS_GROUP_COURIER:
      packagesGroupModal = true;
      break;
    default:
      noModal = true;
      break;
  }

  const onCloseAction = () => {
    switch (topRef.current) {
      case MODAL_TYPES.NOTIFICATION:
        dispatch(closeModal());
        break;
      case MODAL_TYPES.ORDERS_LIST_SENDER:
        dispatch(hideSenderOrdersModal());
        break;
      case MODAL_TYPES.ORDERS_LIST_COURIER:
        dispatch(hideOrderIdsModal());
        break;
      case MODAL_TYPES.ORDER_QUICK_VIEW:
        dispatch(hideQuickShowOrder());
        break;
      case MODAL_TYPES.AUTOCOMPLETE:
        dispatch(hideAutoComplete());
        break;
      case MODAL_TYPES.AUTOCOMPLETE_BANK:
        dispatch(hideAutoCompleteBank());
        break;
      case MODAL_TYPES.AUTOCOMPLETE_LANG:
        dispatch(hideAutoCompleteLang());
        break;
      case MODAL_TYPES.FILTER:
        dispatch(hideFilter());
        break;
      case MODAL_TYPES.SELECT_MODAL:
        dispatch(hideSelectModal());
        break;
      case MODAL_TYPES.ORDERS_GROUP_COURIER:
        dispatch(hideOrderGroupModal());
        break;
    }
  };

  return (
    <Modal
      animationIn="zoomIn"
      animationOut="zoomOut"
      useNativeDriver={true}
      style={{margin: 0}}
      isVisible={modalQueue.length > 0 && !noModal}
      onBackButtonPress={onCloseAction}
      onEscPressed={onCloseAction}
      onBackdropPress={onCloseAction}>
      <ModalComponent isVisible={modalComponent} />
      <PackagesModalSender isVisible={packagesModalSender} />
      <PackagesModalCourier isVisible={packagesModalCourier} />
      <OrderQuickView isVisible={orderQuickView} />
      <Loader isVisible={loader} />
      <AutoComplete isVisible={autoComplete} />
      <AutoCompleteBank isVisible={autoCompleteBank} />
      <AutoCompleteLang isVisible={autoCompleteLang} />
      <OpenWebView isVisible={openWebView} />
      <FilterContainer isVisible={filterContainer} />
      <SelectModal isVisible={selectModal} />
      <PackagesGroupModal isVisible={packagesGroupModal} />
    </Modal>
  );
}
const mapStateToProps = state => ({
  modalQueue: state.modalQueue || [],
});

export default connect(mapStateToProps)(ModalManagerComponent);
