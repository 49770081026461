import {get} from 'lodash';
import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import useDebug from './debug/useDebug';
import getLang, {LanguageContext} from './language/lang';
import {
  initializeApp,
  restoreSaveState,
  setApp,
  setLanguage,
  setWindowSize,
} from './redux/actions';
import {getTheme, ThemeContext} from './themes/theme';
import {useWindowSize} from '@react-hook/window-size';
import ModalManager from './pages/ModalManager';
import SenderApp from './pages/SenderApp';
import {BrowserRouter} from 'react-router-dom';

const App = ({
  lang,
  restored,
  setWindowSizeHandler,
  restoreSaveStateHandler,
  setReduxApp,
}) => {
  useDebug('App');
  const [width, height] = useWindowSize();
  useEffect(() => {
    setWindowSizeHandler({width, height}, {width, height});
  }, [width, height]);

  const dispatch = useDispatch();
  useEffect(() => {
    restoreSaveStateHandler();
    setReduxApp();
  }, []);

  useEffect(() => {
    if (restored) {
      dispatch(initializeApp());
    }
  }, [restored]);
  return restored ? (
    <LanguageContext.Provider value={getLang(lang)}>
      <ThemeContext.Provider value={getTheme('sender', width, height)}>
        <BrowserRouter>
          <SenderApp />
          <ModalManager />
        </BrowserRouter>
      </ThemeContext.Provider>
    </LanguageContext.Provider>
  ) : null;
};

const mapStateToProps = state => ({
  lang: state.lang,
  app: state.app || 'welcome',
  restored: state.restored,
  width: get(state, 'window.width', 1),
  height: get(state, 'window.height', 1),
});

const mapDispatchToProps = dispatch => ({
  setWindowSizeHandler: (...params) => dispatch(setWindowSize(...params)),
  restoreSaveStateHandler: () => dispatch(restoreSaveState()),
  setLanguageHandler: lang => dispatch(setLanguage(lang)),
  setReduxApp: () => dispatch(setApp('sender')),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
