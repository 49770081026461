import React, {useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, CheckBox, Div, Span} from './Components';
import {LanguageContext} from '../language/lang';
import {get} from 'lodash';
import {COLORS} from '../common/constants';
import Analytics from '../common/analytics';
import {ICInfo} from './Icons';
import {useId} from './hooks/useId';
import Title from './components/Title';

function ModalComponent({modal, width, isVisible, app}) {
  const confirmationKeys = get(modal, 'confirmations', []) || [];
  const labelId = useId();
  const lang = useContext(LanguageContext);
  const [value, setvalue] = useState(get(modal, 'input', undefined));
  const [confirmations, setConfirmations] = useState(
    confirmationKeys.reduce((a, b) => {
      a[b] = false;
      return a;
    }, {}),
  );
  useEffect(() => {
    setvalue(get(modal, 'value', undefined));
    setConfirmations(
      confirmationKeys.reduce((a, b) => {
        a[b] = false;
        return a;
      }, {}),
    );
  }, [modal]);
  useEffect(() => {
    if (isVisible) {
      Analytics.reportShowModal(
        lang[get(modal, 'title', '')] || get(modal, 'title', ''),
        lang[get(modal, 'text', '')] || get(modal, 'text', ''),
        get(modal, 'type'),
      );
    }
  }, [modal, isVisible]);
  let button1Text = get(modal, 'button1.text', '');
  if (lang[button1Text]) {
    button1Text = lang[button1Text];
  }
  let button2Text = get(modal, 'button2.text', '');
  if (lang[button2Text]) {
    button2Text = lang[button2Text];
  }
  let button3Text = get(modal, 'button3.text', '');
  if (lang[button3Text]) {
    button3Text = lang[button3Text];
  }
  let color = COLORS.mainColorSender;
  if (app === 'courier') {
    color = COLORS.mainColorCourier;
  }
  let Children = null;
  if (get(modal, 'children', null)) {
    Children = get(modal, 'children', null);
  }
  const text = lang[get(modal, 'text', '')] || get(modal, 'text', '');
  const actionDisabled =
    Object.values(confirmations).some(x => !x) &&
    Object.values(confirmations).length;

  return isVisible ? (
    <Div style={styles.background}>
      <Div
        style={{...styles.foreground, width: width - 50}}
        role="dialog"
        aria-labelledby={labelId}>
        {get(modal, 'title') || get(modal, 'text') ? (
          <Div style={{padding: 20, paddingBottom: 10}}>
            {get(modal, 'title', '') ? (
              <Title id={labelId} style={styles.title(color)}>
                {get(modal, 'title', '')}
              </Title>
            ) : null}
            {text
              .split('<br/>')
              .filter(r => r)
              .map((row, i) => (
                <Span key={i} style={styles.text}>
                  {row}
                </Span>
              ))}
          </Div>
        ) : null}
        {get(modal, 'warning', []).map((warning, i) => (
          <Div
            key={i}
            theme="flex-row ai-start"
            style={{
              backgroundColor: '#FFEDE5',
              paddingVertical: 15,
              paddingHorizontal: 5,
            }}>
            <ICInfo color="#E74700" width={50} />
            <Span style={styles.warning(width)}>{warning}</Span>
          </Div>
        ))}
        {Children ? (
          <Children onChange={val => setvalue(val)} value={value} />
        ) : null}
        {confirmationKeys.map(key => (
          <CheckBox
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              paddingBottom: 0,
              paddingTop: 10,
            }}
            key={key}
            value={confirmations[key]}
            onChange={val => setConfirmations(old => ({...old, [key]: val}))}
            label={key}
            title={key}
          />
        ))}
        <Div
          theme="flex-row"
          style={{marginHorizontal: 24, marginVertical: 16}}>
          {get(modal, 'button1', '') ? (
            <Button
              disabled={actionDisabled}
              style={styles.button(actionDisabled ? COLORS.disabled : color)}
              textStyle={styles.buttonText(COLORS.white)}
              onClick={() => get(modal, 'button1.onClick', '')(value)}>
              {button1Text + ' '}
            </Button>
          ) : null}
          {get(modal, 'button2', '') ? (
            <Button
              style={styles.button(
                get(modal, 'button3') ? color : COLORS.white,
              )}
              textStyle={styles.buttonText(
                !get(modal, 'button3') ? color : COLORS.white,
              )}
              onClick={() => get(modal, 'button2.onClick', '')(value)}>
              {button2Text + ' '}
            </Button>
          ) : null}
          {get(modal, 'button3', '') ? (
            <Button
              style={styles.button(COLORS.white)}
              textStyle={styles.buttonText(color)}
              onClick={() => get(modal, 'button3.onClick', '')(value)}>
              {button3Text + ' '}
            </Button>
          ) : null}
        </Div>
      </Div>
    </Div>
  ) : null;
}

const styles = {
  background: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  button: color => ({
    backgroundColor: color,
    borderRadius: 4,
    paddingVertical: 4,
    marginHorizontal: 4,
    height: 32,
  }),
  buttonText: (color = '#000') => ({
    color,
    fontSize: 14,
    lineHeight: 23,
    fontWeight: 'bold',
  }),
  foreground: {
    backgroundColor: 'white',
    borderRadius: 5,
  },
  input: {
    marginVertical: 20,
  },
  text: {
    fontSize: 16,
    lineHeight: 20,
    paddingVertical: 5,
  },
  title: (color = '#000') => ({
    color,
    fontSize: 20,
    fontWeight: 'bold',
  }),
  warning: width => ({
    color: '#000',
    width: width - 130,
    fontSize: 14,
    lineHeight: 18,
  }),
};
const mapStateToProps = state => ({
  width: get(state, 'window.width', 0),
  height: get(state, 'window.height', 0),
  modal: get(state, 'modal[0]', {}),
  app: state.app,
});

export default connect(mapStateToProps)(ModalComponent);
