import React from 'react';
import Div from './Div';

export default () => {
  let styles = {height: 40, width: 40, resizeMode: 'contain'};
  return (
    <Div theme="flex-row jc-center flex-1 ai-center">
      <img src="./images/3balls.gif" style={styles} />
    </Div>
  );
};
