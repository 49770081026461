import {useContext, useEffect} from 'react';
import {get} from 'lodash';
import {getPrices} from '../../common/function';
import Analytics from '../../common/analytics';
import {saveAvailablePrices} from '../../redux/actions';
import {connect, useDispatch} from 'react-redux';
import useOrderFields from './useOrderFields';
import {LanguageContext} from '../../language/lang';

const InputDetailsDataManager = ({savedFields, user}) => {
  const [fields, setField, fieldsRef] = useOrderFields(savedFields);
  const dispatch = useDispatch();
  const lang = useContext(LanguageContext);

  useEffect(() => {
    if (
      savedFields &&
      get(user, 'savedCard.last4Digits', false) &&
      !get(fieldsRef, 'creditCard.last4Digits')
    ) {
      setField('creditCard', get(user, 'savedCard', {}));
    }
  }, [savedFields, user]);
  useEffect(() => {
    getPricesFromServer().catch(console.log);
  }, [fields.fromAddress, fields.toAddress, fields.size, fields.backAndFoth]);
  return null;
  async function getPricesFromServer() {
    if (
      get(fields.fromAddress, 'googleId', '') &&
      get(fields.toAddress, 'googleId', '')
    ) {
      dispatch(
        saveAvailablePrices({prices: [], success: true, timeStamp: Date.now()}),
      );
      let res = await getPrices(
        get(fields.fromAddress, 'googleId', ''),
        get(fields.toAddress, 'googleId', ''),
        fields.size,
        lang.id,
        !!fields.backAndFoth,
      );
      Analytics.reportGetPrices(res);
      dispatch(saveAvailablePrices({...res, timeStamp: Date.now()}));
    }
  }
};

const mapStateToProps = state => {
  return {
    user: state.user,
    savedFields: get(state, 'senderApp.fields', false),
  };
};

export default connect(mapStateToProps)(InputDetailsDataManager);
