import {runFunction} from '../debug/useDebug';
import {makeApiCall, showMessage} from '../common/function';
import {ACTIONS, CONSTANTS} from '../common/constants';
import {setNavigation, initializeRealTimeOrders} from './actions';
import {get} from 'lodash';
import Analytics from '../common/analytics';
import {getRealTimeDB, onAuthStateChanged} from '../common/firebase';
import {openUrl} from '../common/nativeFunctions';
import getLang from '../language/lang';

export const setHideWarningBeforeAccepting = () => {
  return async dispatch => {
    runFunction('showQuickShowOrder');
    try {
      dispatch({
        type: ACTIONS.HIDE_WARNING_BEFORE_ACCEPTING_ORDER,
      });
    } catch (e) {}
  };
};

export const courierSetUserDetails = (fields, quiet, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierSetUserDetails');
    fields.pid = fields.id;
    delete fields.id;
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/set-courier-details',
      payload: fields,
      showLoader: !quiet,
      action: quiet
        ? ACTIONS.COURIER_SET_USER_DATA_QUIET
        : ACTIONS.COURIER_SET_USER_DATA,
      parseRes: res => res.user,
      callback: res => {
        if (quiet) {
          return;
        }
        dispatch(courierGetAllPayments());
        if (typeof callback === 'function') {
          callback(res);
        }
      },
    });
  };
};

export const courierGetUserData = callback => {
  return async (dispatch, getState) => {
    runFunction('courierGetUserData');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/get-user-data',
      payload: {},
      action: ACTIONS.COURIER_SET_USER_DATA,
      parseRes: res => res.user,
      callback,
    });
  };
};

export const getCourierData = (data = {}, callback) => {
  return async (dispatch, getState) => {
    runFunction('getCourierData');
    if (data.uid) {
      let user = {...data};
      dispatch({
        type: ACTIONS.SET_TEMP_USER_DATA,
        payload: user,
      });
      delete data.uid;
    }
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/get-all-data',
      payload: data,
      action: ACTIONS.COURIER_GET_DATA,
      parseRes: res => ({
        user: res.user,
        orders: res.orders,
        offers: res.offers,
      }),
      callback,
      retry: true,
    });
  };
};

const sendChatMessage = (dispatch, getState, message, timeout = 500) => {
  makeApiCall(dispatch, getState, {
    url: '/apicourier_v1/send-chat-message',
    action: ACTIONS.NONE,
    payload: message,
    callback: res => {
      if (!res.success) {
        setTimeout(() => {
          sendChatMessage(dispatch, getState, message, timeout * 2);
        }, timeout);
      }
    },
  });
};

export const courierPostMessage = message => {
  return async (dispatch, getState) => {
    runFunction('courierPostMessage');
    sendChatMessage(dispatch, getState, message);
  };
};

export const courierReadMessage = data => {
  return async (dispatch, getState) => {
    runFunction('courierReadMessage');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/read-chat-messages',
      action: ACTIONS.NONE,
      payload: data,
    });
  };
};

export const courierGetAllPackages = () => {
  return async (dispatch, getState) => {
    runFunction('senderGetAllPackages');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/get-all-orders',
      action: ACTIONS.COURIER_GET_CONFIRMED_ORDERS,
      parseRes: res => res.orders,
    });
  };
};

export const courierGetPackage = (orderId, callback) => {
  return async (dispatch, getState) => {
    runFunction('senderGetPackage');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/get-order',
      payload: {orderId},
      action: ACTIONS.COURIER_GET_PACKAGE,
      parseRes: res => res.order,
      callback,
    });
  };
};

export const courierRemoveOrder = orderId => {
  return async dispatch => {
    runFunction('courierRemoveOrder');
    dispatch({
      type: ACTIONS.COURIER_REMOVE_ORDER,
      payload: orderId,
    });
  };
};

export const courierConfirmDelivery = (fields, callback) => {
  return async (dispatch, getState) => {
    let result = await showMessage(
      dispatch,
      'confirmation',
      'are-you-sure-qs',
      ['yes', 'no'],
    );
    if (result === 'no') {
      return;
    }
    runFunction('courierConfirmDelivery');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/confirm-delivery',
      payload: fields,
      action: ACTIONS.COURIER_CONFIRM_DELIVERY,
      showLoader: true,
      parseRes: res => res.order,
      callback: res => {
        if (typeof callback === 'function') {
          callback(res);
        }
        if (res.success) {
          if (get(res, 'order.status') === 'PACKAGE_RECEIVED') {
            dispatch(courierGetUserData());
          }
          Analytics.reportCourierEvent(
            'confirm-delivery',
            get(getState(), 'user.uid', ''),
          );
        }
      },
    });
  };
};

export const courierConfirmPickup = (fields, callback) => {
  return async (dispatch, getState) => {
    let result = await showMessage(
      dispatch,
      'confirmation',
      'are-you-sure-qs',
      ['yes', 'no'],
    );
    if (result === 'no') {
      return;
    }
    runFunction('courierConfirmPickup');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/confirm-pickup',
      payload: fields,
      action: ACTIONS.COURIER_CONFIRM_PICKUP,
      showLoader: true,
      parseRes: res => res.order,
      callback: res => {
        if (typeof callback === 'function') {
          callback(res);
        }
        if (res.success) {
          Analytics.reportCourierEvent(
            'confirm-pickup',
            get(getState(), 'user.uid', ''),
          );
        }
      },
    });
  };
};

export const courierCancelOrder = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierCancelOrder');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/cancel-order',
      payload: fields,
      action: ACTIONS.COURIER_CANCEL_ORDER,
      showLoader: true,
      parseRes: res => res.order,
      callback: res => {
        if (typeof callback === 'function') {
          callback(res);
        }
        if (res.success) {
          Analytics.reportCourierEvent(
            'delete-order',
            get(getState(), 'user.uid', ''),
          );
        }
      },
    });
  };
};

export const courierSendOffer = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierSendOffer');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/add-offer',
      payload: {...fields, buildNum: CONSTANTS.buildNum},
      action: ACTIONS.COURIER_SEND_OFFER,
      showLoader: true,
      parseRes: res => ({order: res.order, offer: res.offer}),
      callback: res => {
        if (typeof callback === 'function') {
          callback(res);
        }
        if (res.success) {
          showMessage(
            dispatch,
            'offer-sent',
            'we-will-tell-you-if-it-got-accepted',
            ['close'],
            'SEND_OFFER',
          );
          Analytics.reportCourierEvent(
            'send-offer',
            get(getState(), 'user.uid', ''),
          );
        }
      },
    });
  };
};

export const courierAcceptOrder = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierAcceptOrder');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/accept-order',
      payload: {...fields, buildNum: CONSTANTS.buildNum},
      action: ACTIONS.COURIER_ACCEPT_ORDER,
      showLoader: true,
      parseRes: res => res.order,
      callback: async res => {
        if (typeof callback === 'function') {
          callback(res);
        }
        if (res.success) {
          const sourceType = get(res, 'order.sourceType', 'APP');

          let result = await showMessage(
            dispatch,
            'congratulation',
            'you-got-the-order',
            ['contact-sender', 'show-order'],
            'GOT_ORDER',
          );
          if (result === 'show-order') {
            try {
              getState().navigation.navigate('courier-order-view', {
                id: get(res, 'order.id', ''),
              });
            } catch (err) {}
          }
          if (result === 'contact-sender' && sourceType === 'APP') {
            try {
              getState().navigation.navigate('courier-chat-view', {
                orderId: get(res, 'order.id', ''),
                uid: get(res, 'order.uid', ''),
              });
            } catch (err) {}
          }
          if (result === 'contact-sender' && sourceType === 'BOT') {
            try {
              const langId = getState().lang.toUpperCase();
              let message = getLang(langId)['courier-whatsapp-message'] || '';
              message = message.replace(
                '$$name$$',
                get(res, 'order.courierName'),
              );
              openUrl(
                `https://wa.me/${get(
                  res,
                  'order.phone',
                )}?text=${encodeURIComponent(message)}`,
              );
            } catch (err) {}
          }
          Analytics.reportCourierEvent(
            'accept-order',
            get(getState(), 'user.uid', ''),
          );
        }
      },
    });
  };
};

export const courierAcceptOrdersGroup = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierAcceptOrder');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/accept-orders-group',
      payload: {...fields, buildNum: CONSTANTS.buildNum},
      action: ACTIONS.COURIER_ACCEPT_ORDERS_GROUP,
      showLoader: true,
      parseRes: res => res.orders,
      callback: res => {
        if (typeof callback === 'function') {
          callback(res);
        }
        if (res.success) {
          showMessage(
            dispatch,
            'congratulation',
            'you-got-the-order',
            ['close'],
            'GOT_ORDER',
          );
          dispatch(courierGetOrders());
          Analytics.reportCourierEvent(
            'accept-orders-group',
            get(getState(), 'user.uid', ''),
          );
        }
      },
    });
  };
};

export const courierCancelOffer = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierCancelOffer');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/cancel-offer',
      payload: fields,
      action: ACTIONS.COURIER_CANCEL_OFFER,
      showLoader: true,
      parseRes: () => fields.offerId,
      callback: res => {
        if (typeof callback === 'function') {
          callback(res);
        }
        if (res.success) {
          Analytics.reportCourierEvent(
            'cancel-offer',
            get(getState(), 'user.uid', ''),
          );
        }
      },
    });
  };
};

export const courierGetAllOffers = callback => {
  return async (dispatch, getState) => {
    runFunction('courierGetAllOffers');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/get-all-offers',
      payload: {},
      action: ACTIONS.COURIER_GET_ALL_OFFERS,
      parseRes: res => res.offers,
      callback,
    });
  };
};

export const courierGetAllPayments = callback => {
  return async (dispatch, getState) => {
    runFunction('courierGetAllPayments');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/get-payments',
      payload: {},
      action: ACTIONS.COURIER_GET_ALL_PAYMENTS,
      parseRes: res => res.payments,
      callback,
    });
  };
};

export const courierGetOrder = (orderId, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierGetOrder');
    makeApiCall(dispatch, getState, {
      url: '/api_v1/get-order',
      payload: {orderId, lang: getState().lang},
      action: ACTIONS.COURIER_GET_ORDER,
      parseRes: res => res.order,
      callback,
    });
  };
};

export const courierGetOrderGroup = (groupId, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierGetOrderGroup');
    makeApiCall(dispatch, getState, {
      url: '/api_v1/get-orders-group',
      payload: {groupId, lang: getState().lang},
      action: ACTIONS.COURIER_GET_ORDER,
      parseRes: res => res.group,
      callback,
    });
  };
};

export const courierGetOrders = (location, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierGetOrders');
    let filter =
      location && location.lat && location.lng
        ? {
            lat: location.lat,
            lng: location.lng,
            googleId: location.googleId,
            country: location.country,
          }
        : {};
    makeApiCall(dispatch, getState, {
      url: '/api_v1/get-all-orders',
      payload: {
        filter,
        buildNum: CONSTANTS.buildNum,
        getGroups: true,
        lang: getState().lang,
      },
      action: ACTIONS.COURIER_GET_ORDERS,
      parseRes: res => res.orders,
      callback,
    });
  };
};

export const initializeCourier = navigation => {
  return async (dispatch, getState) => {
    runFunction('initializeCourier');
    try {
      dispatch(setNavigation(navigation));
      dispatch(initializeRealTimeOrders());
      let initialized = false;
      onAuthStateChanged(async user => {
        if (user && !initialized) {
          initialized = true;
          dispatch(getCourierData({}));
          dispatch(courierGetAllPayments());
          getRealTimeDB()
            .ref('language/' + get(user, 'uid', ''))
            .set(getState().lang)
            .catch(console.log);
        }
      });
    } catch (err) {
      Analytics.reportError(err);
    }
  };
};

export const courierSetUserData = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierSetUserData');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/set-user-data',
      payload: fields,
      action: ACTIONS.SET_USER_DATA,
      parseRes: res => res.user,
      callback,
    });
  };
};

export const courierUploadReceipt = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierSetUserData');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/upload-receipt',
      showLoader: true,
      payload: fields,
      action: ACTIONS.SET_PAYMENT,
      parseRes: res => res.payment,
      callback,
    });
  };
};

export const senderValidateWithCreditCard = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('senderValidateWithCreditCard');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/validate-payment-method',
      payload: fields,
      action: ACTIONS.VALIDATE_CREDIT_CARD,
      parseRes: () => fields,
      callback,
    });
  };
};

export const courierDeleteUser = (fields, callback) => {
  return async (dispatch, getState) => {
    runFunction('courierDeleteUser');
    makeApiCall(dispatch, getState, {
      url: '/apicourier_v1/delete-user',
      showLoader: true,
      payload: fields,
      action: ACTIONS.DELETE_USER,
      parseRes: res => res.user,
      callback,
    });
  };
};
