import React, {useEffect, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {get} from 'lodash';
import {LanguageContext, getSystemLang} from '../../language/lang';
import {showSelectModal, hideSelectModal} from '../../redux/actions';
import Div from './Div';
import Span from './Span';
import {COLORS} from '../../common/constants';

export default ({
  children,
  noTranslate,
  placeholder,
  disabled,
  value,
  onChange,
  error,
  style,
  invisible,
}) => {
  const lang = useContext(LanguageContext);
  const [show, setshow] = useState(true);
  const [first, setfirst] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (first) {
      setfirst(false);
    } else {
      setshow(false);
      setTimeout(() => {
        setshow(true);
      }, 2);
    }
  }, [first, lang]);
  let text = children || '';
  let options = (children || [])
    .filter(element => get(element, 'type', '') === 'option')
    .map((element, i) => {
      text = get(element, 'props.children', '');
      if (!noTranslate && lang[text]) {
        text = lang[text];
      }
      return {id: String(i), text, value: get(element, 'props.value', '')};
    });
  if (!noTranslate && lang[text]) {
    text = lang[text];
  }
  const styles = {
    text: {
      color: disabled ? 'grey' : COLORS.black,
      opacity:
        placeholder &&
        !get(
          options.find(option => option.value === value),
          'text',
          '',
        )
          ? 0.3
          : 1,
      width: style?.width || '100%',
      ...(getSystemLang() !== lang.id
        ? {textAlign: 'right'}
        : {textAlign: 'left'}),
      fontSize: 18,
      lineHeight: 44,
      flexDirection: 'row',
      alignItems: 'center',
    },
    touchable: {
      border: 'none',
      background: 'none',
      ...(!invisible
        ? {
            backgroundColor: 'white',
            borderWidth: 1,
          }
        : {}),
      borderStyle: 'solid',
      height: 60,
      backgroundColor: disabled ? '#f7f6f2' : '#f0f0f0',
      borderColor: error ? 'red' : '#f0f0f0',
      borderRadius: 0,
      borderWidth: 1,
      color: COLORS.black,
      fontSize: 17,
      paddingBottom: 4,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 4,
      lineHeight: 24,
      ...(style || {}),
    },
  };

  let css = styles.touchable;
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }
  return show ? (
    <>
      <Div>
        <button
          disabled={disabled}
          style={css}
          onClick={() =>
            dispatch(
              showSelectModal(options, val => {
                if (typeof onChange === 'function' && val) {
                  onChange(val);
                }
                dispatch(hideSelectModal());
              }),
            )
          }>
          <Span numberOfLines={1} style={styles.text}>
            {placeholder &&
            !get(
              options.find(option => option.value === value),
              'text',
              '',
            )
              ? lang[placeholder] || placeholder || ''
              : get(
                  options.find(option => option.value === value),
                  'text',
                  '',
                )}
          </Span>
        </button>
      </Div>
    </>
  ) : (
    <Div>
      <button
        style={styles.touchable}
        onClick={() =>
          dispatch(
            showSelectModal(options, val => {
              if (typeof onChange === 'function' && val) {
                onChange(val);
              }
              dispatch(hideSelectModal());
            }),
          )
        }
      />
    </Div>
  );
};
