import React from 'react';

export default ({top = true, bottom}) => {
  let styles = {};
  if (top) {
    styles = {height: 4, background: 'linear-gradient(#d1d1d1, #FCFCFC)'};
  }
  if (bottom) {
    styles = {height: 4, background: 'linear-gradient(#FCFCFC, #d1d1d1)'};
  }

  return <div style={styles} />;
};
