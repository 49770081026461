import {useEffect, useRef} from 'react';
import {Platform} from '../../common/nativeFunctions';

export const useFocusOnRender = () => {
  const elementRef = useRef();

  useEffect(() => {
    if (elementRef.current) {
      try {
        elementRef.current.focus();
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  return elementRef;
};
