import React, {useState, useContext, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {LanguageContext, LANGUAGES} from '../language/lang';
import {get} from 'lodash';
import {
  Div,
  IconButton,
  Title,
  Shadow,
  Input,
  Loader,
  Image,
  Span,
} from './Components';
import {COLORS} from '../common/constants';
import {ICClose, ICHistory} from './Icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useDebounce} from 'use-debounce';
import {
  getAutoCompleteDetails,
  getAutoCompleteToken,
  getPlaceDetails,
  Platform,
} from '../common/nativeFunctions';
import {googleIconBase64} from './GoogleBase64';
import {useId} from './hooks/useId';

function AutoComplete({
  onChange,
  isVisible,
  address,
  type,
  error,
  app,
  googleKey,
  width,
  googleMapLoaded,
  rightOffset,
  leftOffset,
}) {
  const lang = useContext(LanguageContext);
  const [token, settoken] = useState(getAutoCompleteToken());
  const [input, setinput] = useState(get(address, 'text', ''));
  const [editable, seteditable] = useState(false);
  const [predictions, setpredictions] = useState([]);
  const [loader, setloader] = useState(false);
  const inputRef = useRef();
  const [recentPlaces, setrecentPlaces] = useState([]);
  const [, setfocus] = useState(false);
  const [debouncedInput] = useDebounce(input, 200);
  const labelId = useId();

  useEffect(() => {
    settoken(getAutoCompleteToken());
  }, [isVisible, googleMapLoaded]);
  useEffect(() => {
    (async () => {
      try {
        console.log('get-places');
        let places = await AsyncStorage.getItem('@saved-places');
        console.log('places', places);
        places = JSON.parse(places);
        setrecentPlaces(places.sort((a, b) => b.freq - a.freq).slice(0, 10));
      } catch (err) {
        setrecentPlaces([]);
      }
    })();
  }, [isVisible]);
  useEffect(() => {
    if (isVisible && editable) {
      setTimeout(() => {
        try {
          inputRef.current.focus();
        } catch (e) {}
      }, 200);
    }
  }, [isVisible, editable]);
  useEffect(() => {
    if (debouncedInput.length > 1) {
      (async () => {
        setpredictions(
          await getAutoCompleteDetails(
            debouncedInput,
            token,
            lang.id,
            googleKey,
            type,
          ),
        );
      })();
    } else {
      setpredictions([]);
    }
  }, [debouncedInput]);

  let mainColor = COLORS.mainColorSender;
  if (app === 'courier') {
    mainColor = COLORS.mainColorCourier;
  }

  return isVisible ? (
    <Div
      style={{...styles.mainContainer, width}}
      role="dialog"
      aria-labelledby={labelId}>
      <Div
        style={{...styles.mainBG, width, left: leftOffset, right: rightOffset}}>
        <Div
          theme="flex-row jc-space-between"
          style={{backgroundColor: mainColor}}>
          <IconButton
            title="close"
            onClick={() =>
              typeof onChange === 'function' ? onChange() : null
            }>
            <ICClose color={COLORS.white} width={25} height={40} />
          </IconButton>
          <Title id={labelId} style={styles.title(COLORS.white)}>
            enter-address
          </Title>
          <Div style={{width: 40}} />
        </Div>
        <Shadow />
        <Div>
          <Div
            style={{
              backgroundColor: '#f0f0f0',
              borderWidth: 1,
              borderColor: error ? 'red' : '#f0f0f0',
              color: COLORS.black,
              height: 42,
              margin: 10,
            }}
            theme="flex-row jc-space-between ai-center">
            <Input
              ref={inputRef}
              value={input}
              placeholder="type-address"
              onBecameEditable={() => seteditable(true)}
              onChange={val => setinput(val)}
              onFocus={() => setfocus(true)}
              onBlur={() => setfocus(false)}
              style={{
                fontSize: 17,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 14,
                paddingRight: 14,
                width: width - 95,
                color: COLORS.black,
                borderWidth: 0,
                backgroundColor: '#f0f0f0',
              }}
            />
            <IconButton title="clear" onClick={() => setinput('')}>
              <ICClose width={25} height={25} color={'#BDBDBD'} />
            </IconButton>
          </Div>
          <Shadow />
          <Div>
            {!loader
              ? predictions.map((pred, i) => (
                  <IconButton
                    key={i}
                    onClick={() => selectLocation(pred)}
                    title={pred.text}
                    style={{
                      borderBottomWidth: 1,
                      borderColor: '#dddddd',
                      padding: 15,
                    }}>
                    <Div theme="flex-row">
                      <Title
                        style={{
                          fontSize: 16,
                          textAlign: 'auto',
                        }}>
                        {pred.text}
                      </Title>
                    </Div>
                  </IconButton>
                ))
              : null}
            {!loader &&
            predictions.length === 0 &&
            recentPlaces.length === 0 ? (
              <Div theme="flex-row jc-center ai-center" style={{padding: 20}}>
                <ICHistory width={40} height={40} />
                <Title style={{color: '#aaaaaa'}}>No History</Title>
              </Div>
            ) : null}
            {!loader
              ? recentPlaces.map((pred, i) => (
                  <IconButton
                    key={i}
                    title={pred.text}
                    onClick={() => selectLocation(pred)}
                    style={{
                      borderBottomWidth: 1,
                      borderColor: '#dddddd',
                      padding: 15,
                    }}>
                    <Div theme="flex-row">
                      <ICHistory width={25} height={35} />
                      <Title
                        style={{
                          fontSize: 16,
                          textAlign: 'auto',
                          marginHorizontal: 10,
                        }}>
                        {pred.text}
                      </Title>
                    </Div>
                  </IconButton>
                ))
              : null}
            {loader ? (
              <Div theme="flex-row jc-center">
                <Loader />
              </Div>
            ) : null}
            <Div theme="flex-row jc-flex-end ai-center" style={{padding: 10}}>
              {lang.id !== LANGUAGES.HE && <Span>powered by</Span>}
              <Image
                title="poweredByGoogle"
                style={{height: 23, width: 75}}
                src={'/images/google.png'}
                source={{
                  uri: googleIconBase64,
                }}
              />
              {lang.id === LANGUAGES.HE && <Span>powered by</Span>}
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  ) : null;

  async function selectLocation(pred) {
    setloader(true);
    savePlace(pred);
    let place = await getPlaceDetails(
      get(pred, 'placeId', ''),
      token,
      lang.id,
      googleKey,
    );
    if (place) {
      savePlace({placeId: place.googleId, text: place.text});
    }
    if (typeof onChange === 'function') {
      onChange(place);
    }
  }

  function savePlace(pred) {
    let place = recentPlaces.find(place => place.placeId === pred.placeId);
    let places;
    if (place) {
      pred.freq = place.freq + 1;
      places = [
        ...recentPlaces.filter(place => place.placeId !== pred.placeId),
        pred,
      ];
    } else {
      places = [...recentPlaces, {...pred, freq: 1}];
    }
    AsyncStorage.setItem('@saved-places', JSON.stringify(places));
  }
}

const styles = {
  mainBG: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    backgroundColor: '#FCFCFC',
    height: '100%',
    width: '100%',
  },
  mainContainer: {
    backgroundColor: '#000000',
    flex: 1,
    height: '100%',
    width: '100%',
  },
  selectedTab: {
    borderColor: COLORS.mainColorSender,
  },
  shadow: {
    height: 4,
  },
  tabButton: {
    backgroundColor: 'transparent',
    borderBottomWidth: 4,
    borderColor: 'transparent',
    borderRadius: 0,
    width: '50%',
  },
  tabText: {
    color: COLORS.black,
  },
  title: color => ({
    lineHeight: 53,
    fontSize: 16,
    color,
  }),
};

const mapStateToProps = state => ({
  app: state.app,
  width: get(state, 'window.width', 0),
  address: get(state, 'autocomplete.address', ''),
  error: get(state, 'autocomplete.error', ''),
  type: get(state, 'autocomplete.type', ''),
  googleMapLoaded: state.googleMapLoaded,
  onChange: get(state, 'autocomplete.onChange', () => null),
  googleKey: get(state, 'googleKey', ''),
  leftOffset: get(state, 'window.leftOffset', 0),
  rightOffset: get(state, 'window.rightOffset', 0),
});

export default connect(mapStateToProps)(AutoComplete);
