import React from 'react';

export default ({style}) => {
  const styles = {
    border: 'none',
    borderBottom: 'solid 1px #c7c7c7',
    marginVertical: 20,
    ...(style || {}),
  };
  let css = styles;
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }
  return <hr style={css} />;
};
