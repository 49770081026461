import {v4 as uuid} from 'uuid';

export const getUniqueDviceId = () => {
  try {
    let deviceId = localStorage.getItem('@device-id');
    if (deviceId) {
      return deviceId;
    }
    deviceId = uuid();
    localStorage.setItem('@device-id', deviceId);
    return deviceId;
  } catch (err) {
    return 'incognito';
  }
};
