import React, {useContext} from 'react';
import {COLORS} from '../../common/constants';
import {LanguageContext} from '../../language/lang';
import {ThemeContext} from '../../themes/theme';
import {FocusTrapWithErrorBoundary} from './FocusTrapWithErrorBoundary';

export default React.forwardRef(
  (
    {
      children,
      noTranslate,
      style,
      scrollY,
      scrollX,
      theme,
      className,
      tabIndex,
      focusTrap,
      ariaHidden,
      renderAsHtml = 'div',
      ...props
    },
    ref,
  ) => {
    const lang = useContext(LanguageContext);
    const themeContext = useContext(ThemeContext);
    if (typeof children === 'string' && !noTranslate && lang[children]) {
      children = (
        <p style={{color: COLORS.black, margin: 5, ...(style || {})}}>
          {lang[children]}
        </p>
      );
    } else if (typeof children === 'string') {
      children = (
        <p style={{color: COLORS.black, margin: 5, ...(style || {})}}>
          {children}
        </p>
      );
    }
    let styleDiv = {};
    (theme || '').split(' ').forEach(t => {
      if (themeContext[t]) {
        styleDiv = {...styleDiv, ...themeContext[t]};
      }
    });

    let css = {...style, ...styleDiv};
    if (scrollY) {
      css.overflowY = 'scroll';
    }
    if (scrollY && !css.height) {
      css.height = '100%';
    }
    if (scrollX) {
      css.overflowX = 'scroll';
      css.display = 'flex';
    }
    for (let key in css) {
      if (key === 'marginHorizontal') {
        css.marginLeft = css[key];
        css.marginRight = css[key];
      }
      if (key === 'marginVertical') {
        css.marginTop = css[key];
        css.marginBottom = css[key];
      }
      if (key === 'paddingHorizontal') {
        css.paddingLeft = css[key];
        css.paddingRight = css[key];
      }
      if (key === 'paddingVertical') {
        css.paddingTop = css[key];
        css.paddingBottom = css[key];
      }
    }
    for (let key in css) {
      if (typeof css[key] === 'number' && key !== 'zIndex') {
        css[key] = css[key] + 'px';
      }
    }
    if (
      Object.keys(css).some(
        key => typeof key === 'string' && key.indexOf('flex') !== -1,
      )
    ) {
      css.display = 'flex';
    }
    if (
      Object.keys(css).some(
        key => typeof key === 'string' && key.indexOf('elevation') !== -1,
      )
    ) {
      css.boxShadow = 'rgb(216 216 216) 0px -2px 4px 0px';
    }

    const result = React.createElement(
      renderAsHtml,
      {
        ref: ref,
        style: css,
        className: className || '',
        tabIndex: tabIndex,
        ['aria-hidden']: ariaHidden,
        ...props,
      },
      children,
    );

    if (focusTrap) {
      return <FocusTrapWithErrorBoundary>{result}</FocusTrapWithErrorBoundary>;
    }

    return result;
  },
);
