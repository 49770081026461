import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {createStore, compose, applyMiddleware} from 'redux';
import {thunk} from 'redux-thunk';
import reducer from './redux/reducer';
import './index.css';
import ErrorBoundary from './pages/components/ErrorBoundry';

try {
  //save gclid
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === 'gclid') {
      window.gclid = decodeURIComponent(pair[1]);
    }
  }
} catch (e) {}

try {
  const store = createStore(
    reducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f,
    ),
  );
  const ReduxContainer = () => {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>
    );
  };

  document.getElementById('root').innerHTML = '';

  ReactDOM.render(<ReduxContainer />, document.getElementById('root'));
} catch (err) {
  console.log(err);
}
