import React, {useContext, useEffect, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import {
  Address,
  Button,
  Div,
  IconButton,
  Loader,
  Span,
  Title,
} from '../Components';
import {LanguageContext, LANGUAGES} from '../../language/lang';
import {get} from 'lodash';
import {
  closeModal,
  hideOrderIdsModal,
  hideQuickShowOrder,
  initializeCloudMessaging,
  openModal,
} from '../../redux/actions';
import {COLORS} from '../../common/constants';
import moment from 'moment';
import {
  ICBilling,
  ICChats,
  ICClock,
  ICInfo,
  ICMap,
  ICQuestionInfo,
  ICSizeIcon,
} from '../Icons';
import {
  checkNotifications,
  requestNotifications,
  RESULTS,
} from '../../common/permissions';
import {showMessage} from '../../common/function';
import useDebug from '../../debug/useDebug';
import {
  courierAcceptOrder,
  courierCancelOffer,
  courierGetOrder,
  courierSendOffer,
} from '../../redux/courierActions';
import Analytics from '../../common/analytics';
import {Platform} from '../../common/nativeFunctions';
import BackgroundMap from '../senderApp/BackgroundMap';
import Shadow from '../components/Shadow';

const MapViewComponent = (googleKey, width, order, lang) => () => {
  let distance =
    ' - ' +
    Math.round(get(order, 'directions.distance', 0) / 1000) +
    ' ' +
    lang.km;
  if (lang.id === LANGUAGES.EN_IMPERIAL) {
    distance =
      ' - ' +
      Math.round(get(order, 'directions.distance', 0) / 1609) +
      ' ' +
      lang.miles;
  }
  return (
    <Div>
      <Div
        style={{
          backgroundColor: COLORS.bgColorCourier,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}>
        <Span style={{paddingHorizontal: 5, lineHeight: 22}}>
          {`${lang.from}: ${order?.fromAddress?.text}`}
        </Span>
        <Span style={{paddingHorizontal: 5, lineHeight: 22}}>
          {`${lang.to}: ${order?.toAddress?.text}`}
        </Span>
        <Span style={{paddingHorizontal: 5, lineHeight: 22}}>
          {lang.distance + distance}
        </Span>
        <Span style={{paddingHorizontal: 5, lineHeight: 22}}>
          {lang.time +
            ' - ' +
            Math.round(get(order, 'directions.duration', 0) / 60) +
            ' ' +
            lang.min}
        </Span>
      </Div>
      <Shadow />
      <BackgroundMap
        noPadding
        googleKey={googleKey}
        locationOne={get(order, 'fromAddress', {})}
        locationTwo={get(order, 'toAddress', {})}
        height={400}
        width={width - 50}
      />
      <Shadow bottom />
    </Div>
  );
};

function OrderQuickView({
  order,
  orderId,
  isVisible,
  orderStatus,
  width,
  height,
  user,
  navigation,
  acceptOrder,
  googleKey,
}) {
  console.log(order);
  useDebug('OrderQuickView');
  const lang = useContext(LanguageContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (orderId && !order) {
      dispatch(courierGetOrder(orderId));
    }
  }, [order, orderId]);

  const notificationsEnabled = useRef(false);
  useEffect(() => {
    checkNotifications().then(({status}) => {
      notificationsEnabled.current = status === RESULTS.GRANTED;
    });
  }, []);
  useEffect(() => {
    if (isVisible && orderId) {
      Analytics.reportCourierEvent(
        'open-quick-order-view',
        get(user, 'uid', ''),
        {
          orderId,
        },
      );
    }
  }, [orderId, isVisible]);

  let liveStatus = orderStatus.status;

  return isVisible ? (
    <>
      <Div
        style={styles.background}
        role="dialog"
        aria-label={lang.orderQuickView}>
        <Div
          style={{
            ...styles.foreground,
            width: width - 50,
            minHeight: (3 * height) / 5,
          }}
          theme="flex-column jc-space-between">
          <Div>
            <Div
              theme="flex-column jc-space-between ai-center"
              style={{
                backgroundColor: COLORS.bgColorCourier,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
              }}>
              {order?.courierOrderDescription ? (
                <Div
                  theme="flex-row jc-start ai-center"
                  style={{width: '100%', padding: 10}}>
                  <Div style={styles.labelBackground(COLORS.labelYellow)}>
                    <Span style={styles.labelText(COLORS.black)}>
                      {order?.courierOrderDescription}
                    </Span>
                  </Div>
                </Div>
              ) : null}
              <IconButton
                style={{
                  padding: 0,
                  width: '100%',
                  height: 80,
                }}
                title="showOrder"
                onClick={showMap}>
                <Div theme="flex-row jc-space-between ai-center">
                  <Div style={{width: 44}} />
                  <Address
                    arrowColor={COLORS.black}
                    style={{marginVertical: 10, flex: 1}}
                    fromAddress={get(order, 'fromAddress', {})}
                    toAddress={get(order, 'toAddress', {})}
                  />
                  <Div
                    style={{
                      paddingTop: 15,
                      paddingHorizontal: 12,
                    }}>
                    <ICMap
                      height={20}
                      width={20}
                      color={COLORS.mainColorCourier}
                    />
                  </Div>
                </Div>
              </IconButton>
              <Div
                style={{
                  width: width - 80,
                  borderStyle: 'solid',
                  borderBottomWidth: 1,
                  borderColor: '#ffd7c3',
                }}
              />
              {get(order, 'sourceType') === 'APP' ? (
                <IconButton
                  title="send-message-to-sender"
                  onClick={goToChats}
                  style={{width: '100%', paddingHorizontal: 24}}>
                  <Div theme="flex-row jc-start ai-center">
                    <ICChats
                      height={25}
                      width={25}
                      color={COLORS.mainColorCourier}
                    />
                    <Span style={styles.spanText(COLORS.mainColorCourier)}>
                      send-message-to-sender
                    </Span>
                  </Div>
                </IconButton>
              ) : (
                <Span style={styles.warningText(COLORS.black)}>
                  {lang['warning-bot-order']}
                </Span>
              )}
            </Div>
            {order && liveStatus === 'POSTED' ? (
              <>
                {get(order, 'pickupTime') ? (
                  <>
                    <Div
                      theme="flex-row jc-space-between ai-center"
                      style={{
                        paddingHorizontal: 20,
                        paddingTop: 10,
                        marginBottom: 5,
                      }}>
                      <Div theme="flex-row jc-start ai-center">
                        <ICClock height={30} width={30} color={COLORS.black} />
                        <Span style={styles.spanText(COLORS.black)}>
                          {lang['courier-pickup-time'] + ': '}
                        </Span>
                      </Div>
                    </Div>
                    <Div
                      theme="flex-row jc-start ai-start"
                      style={{paddingHorizontal: 20, paddingBottom: 0}}>
                      <Span style={{paddingHorizontal: 8, lineHeight: 16}}>
                        •
                      </Span>
                      <Span style={{fontSize: 14, lineHeight: 16}}>
                        {get(order, 'pickupTime', '').replace(/\n/g, ' ')}
                      </Span>
                    </Div>
                  </>
                ) : null}
                {get(order, 'maxDeliveryTime') ? (
                  <>
                    <Div
                      theme="flex-row jc-space-between ai-center"
                      style={{
                        paddingHorizontal: 20,
                        paddingTop: 10,
                        marginBottom: 5,
                      }}>
                      <Div theme="flex-row jc-start ai-center">
                        <ICClock height={30} width={30} color={COLORS.black} />
                        <Span style={styles.spanText(COLORS.black)}>
                          {lang['courier-delivery-time'] + ': '}
                        </Span>
                      </Div>
                    </Div>
                    <Div
                      theme="flex-row jc-start ai-start"
                      style={{paddingHorizontal: 20, paddingBottom: 0}}>
                      <Span style={{paddingHorizontal: 8, lineHeight: 16}}>
                        •
                      </Span>
                      <Span style={{fontSize: 14, lineHeight: 16}}>
                        {getDay(get(order, 'maxDeliveryTime', '')) +
                          ' ' +
                          moment(get(order, 'maxDeliveryTime', '')).format(
                            lang['moment-short-datetime'],
                          )}
                      </Span>
                    </Div>
                  </>
                ) : null}
                <Div
                  theme="flex-row jc-space-between ai-center"
                  style={{paddingHorizontal: 20, paddingVertical: 10}}>
                  <Div theme="flex-row jc-start ai-center">
                    <ICSizeIcon
                      height={20}
                      width={30}
                      color={COLORS.black}
                      size={order?.size}
                    />
                    <Span style={styles.spanText(COLORS.black)}>size</Span>
                  </Div>
                  <Span
                    style={styles.spanText(
                      COLORS.black,
                    )}>{`${order?.size}-s`}</Span>
                </Div>
                <Div
                  theme="flex-row jc-space-between ai-center"
                  style={{
                    paddingHorizontal: 5,
                    marginHorizontal: 15,
                    paddingVertical: 10,
                    backgroundColor: order.volunteerOrder
                      ? '#f8e6fa'
                      : '#e9fae9',
                    borderRadius: 8,
                  }}>
                  <Div theme="flex-row jc-start ai-center">
                    <ICBilling
                      height={20}
                      width={30}
                      color={
                        order.volunteerOrder ? COLORS.volunteerColor : '#01bd0c'
                      }
                    />
                    <Span
                      style={styles.spanText(
                        order.volunteerOrder
                          ? COLORS.volunteerColor
                          : '#01bd0c',
                      )}>
                      {order.volunteerOrder
                        ? `${lang['volunteer-order']} ${order.volunteerOrganizationName}`
                        : 'price'}
                    </Span>
                  </Div>
                  <Span
                    style={styles.spanText(
                      order.volunteerOrder ? COLORS.volunteerColor : '#01bd0c',
                    )}>
                    {order.volunteerOrder
                      ? ''
                      : parseFloat(
                          get(order, 'bambizzCourierMaxPrice', 0),
                        ).toFixed(2) +
                        ' ' +
                        lang[get(order, 'bambizzPriceCurrency', 'ILS')]}
                  </Span>
                </Div>
                {get(order, 'backAndFoth') ? (
                  <Div
                    theme="flex-row jc-space-between ai-center"
                    style={{
                      paddingHorizontal: 20,
                      paddingVertical: 10,
                      paddingBottom: 0,
                    }}>
                    <Div theme="flex-row jc-start ai-center">
                      <ICInfo height={25} width={30} color={COLORS.black} />
                      <Span style={styles.spanText(COLORS.black)}>
                        back-and-foth
                      </Span>
                    </Div>
                    <IconButton
                      title={`${lang.moreInformation} ${lang['back-and-foth']}`}
                      onClick={() =>
                        showMessage(
                          dispatch,
                          'info',
                          'back-and-forth-explanation-courier',
                          ['close'],
                          'INFO',
                        )
                      }>
                      <ICQuestionInfo />
                    </IconButton>
                  </Div>
                ) : null}

                {get(order, 'description') ? (
                  <>
                    <Div
                      theme="flex-row jc-space-between ai-center"
                      style={{
                        paddingHorizontal: 20,
                        paddingTop: 10,
                        marginBottom: 5,
                      }}>
                      <Div theme="flex-row jc-start ai-center">
                        <ICChats height={22} width={30} color={COLORS.black} />
                        <Span style={styles.spanText(COLORS.black)}>
                          {lang.description + ': '}
                        </Span>
                      </Div>
                    </Div>
                    <Div
                      theme="flex-row jc-start ai-start"
                      style={{paddingHorizontal: 20, paddingBottom: 10}}>
                      <Span style={{paddingHorizontal: 8, lineHeight: 16}}>
                        •
                      </Span>
                      <Span style={{fontSize: 14, lineHeight: 16}}>
                        {get(order, 'description', '').replace(/\n/g, ' ')}
                      </Span>
                    </Div>
                    {order.volunteerOrder ? (
                      <Div
                        theme="flex-row jc-start ai-start"
                        style={{paddingHorizontal: 20, paddingBottom: 10}}>
                        <Span style={{paddingHorizontal: 8, lineHeight: 16}}>
                          •
                        </Span>
                        <Span
                          style={{fontSize: 14, lineHeight: 16}}
                          noTranslate>
                          {`${lang['number-of-packages']}: ${get(
                            order,
                            'numberOfPackages',
                          )}`}
                        </Span>
                      </Div>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </Div>
          {!order ? <Loader /> : null}
          {order && liveStatus !== 'POSTED' ? (
            <Title style={{paddingHorizontal: 20}}>oops-order-taken</Title>
          ) : null}
          <Div>
            <Button
              theme="orange"
              onClick={() => acceptOrderHand()}
              disabled={get(order, 'example') || liveStatus !== 'POSTED'}
              style={styles.buttonConfirm}>
              take-package
            </Button>
            <Button
              style={{backgroundColor: COLORS.white}}
              textStyle={{color: COLORS.mainColorCourier}}
              onClick={onClose}>
              cancel
            </Button>
          </Div>
        </Div>
      </Div>
    </>
  ) : null;

  function goToChats() {
    onClose();
    dispatch(hideOrderIdsModal());
    navigation.navigate('courier-chat-view', {
      orderId: get(order, 'id', ''),
      uid: get(order, 'uid', ''),
    });
  }

  function getDay(time) {
    let day;
    if (new Date().getDay() === new Date(time).getDay()) {
      day = lang.today;
    } else if (new Date().getDay() + 1 === new Date(time).getDay()) {
      day = lang.tomorrow;
    } else {
      day = lang[new Date(time).getDay()];
    }
    return day;
  }

  function onClose() {
    dispatch(hideQuickShowOrder());
  }

  async function acceptOrderHand() {
    Analytics.reportCourierOrderInteraction(
      'clickAcceptOrder',
      get(order, 'id', ''),
      get(user, 'uid', ''),
    );
    const paymentsEnabled = get(order, 'paymentsEnabled') !== false;
    const volunteer = order?.volunteerOrder;
    let warnings = ['call-sender-warning'];
    if (paymentsEnabled) {
      warnings.push('about-to-accept-order-warning-1');
    } else if (get(order, 'sourceType') === 'APP') {
      warnings.push('about-to-accept-order');
    }
    if (get(order, 'backAndFoth')) {
      warnings.push('back-and-forth-explanation-courier-warning');
    }
    if (get(order, 'size') === 'custom-package' && paymentsEnabled) {
      warnings.push('custom-package-courier-warning');
    }
    if (volunteer) {
      warnings = [];
    }
    let message = 'about-to-accept-order';
    if (!paymentsEnabled) {
      message = 'about-to-accept-order-payments-disabled';
    }
    if (volunteer) {
      message = (lang['about-to-accept-order-volunteer'] || '').replace(
        '$$volunteerOrg$$',
        order?.volunteerOrganizationName,
      );
    }
    if (get(order, 'sourceType') === 'BOT') {
      message = 'about-to-accept-order-bot';
    }
    let result = await showMessage(
      dispatch,
      'warning',
      message,
      ['accept-order', 'close'],
      'WARN_BEFORE_ACCEPT',
      warnings,
      paymentsEnabled || volunteer
        ? null
        : ['i-can-provide-receipt', 'i-read-all-above'],
    );
    if (result !== 'accept-order') {
      Analytics.reportCourierOrderInteraction(
        'rejectWarningNoOffers',
        get(order, 'id', ''),
        get(user, 'uid', ''),
      );
      return;
    }

    if (Platform.OS !== 'web' && !notificationsEnabled.current) {
      await requestNotifications(['alert', 'badge', 'sound', 'criticalAlert']);
      dispatch(initializeCloudMessaging(navigation));
    }
    if (!get(user, 'uid', '')) {
      Analytics.reportCourierOrderInteraction(
        'rejectNotLoggedIn',
        get(order, 'id', ''),
        get(user, 'uid', ''),
      );
      try {
        dispatch(hideQuickShowOrder());
        dispatch(hideOrderIdsModal());
        navigation.navigate('courier-login', {
          redirect: 'courier-order-around',
          redirectParams: {},
        });
      } catch (e) {}
      return;
    }
    if (user?.needsToSubscribe) {
      Analytics.reportCourierOrderInteraction(
        'rejectNeedToSubscribe',
        get(order, 'id', ''),
        get(user, 'uid', ''),
      );
      dispatch(hideQuickShowOrder());
      dispatch(hideOrderIdsModal());
      navigation.navigate('courier-subscribe');
      return;
    }
    acceptOrder(
      get(order, 'bambizzCourierMaxPrice', 0),
      order,
      get(order, 'maxPickUpTime', 0) - 10,
      result => {
        console.log(result);
        if (typeof onClose === 'function') {
          onClose();
        }
      },
    );
  }

  function showMap() {
    const modalConf = {
      type: 'MAP_VIEW',
      children: MapViewComponent(googleKey, width, order, lang),
      value: false,
      button1: {
        text: 'close',
        onClick: () => {
          dispatch(closeModal());
        },
      },
    };
    dispatch(openModal(modalConf));
  }
}

const styles = {
  spanText: color => ({
    color,
    fontSize: 16,
    marginHorizontal: 10,
    lineHeight: 20,
  }),
  background: {
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
    width: '100%',
  },
  buttonConfirm: {
    borderRadius: 8,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
  },
  buttonRight: {
    backgroundColor: 'grey',
    borderBottomRightRadius: 10,
    borderRadius: 0,
    width: '50%',
  },
  buttonTop: {
    backgroundColor: COLORS.mainColorCourier,
    borderRadius: 0,
    borderTopRightRadius: 10,
    width: '30%',
  },
  labelBackground: (background, width) => ({
    backgroundColor: background || '#f4f4f4',
    borderRadius: 25,
    overflow: 'hidden',
    width,
    paddingHorizontal: 6,
    minWidth: 200,
  }),
  labelText: (color, width) => ({
    width,
    color: color || 'grey',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 26,
  }),
  foreground: {
    backgroundColor: 'white',
    borderRadius: 8,
    elevation: 4,
    minHeight: 500,
  },
  link: {
    color: '#0056e0',
    fontSize: 14,
    fontWeight: '600',
    textAlign: 'auto',
    textDecorationLine: 'underline',
  },
  offerLine: {
    fontSize: 18,
    lineHeight: 52,
  },
  warningText: color => ({
    color,
    fontSize: 16,
    marginHorizontal: 10,
    marginVertical: 15,
    lineHeight: 20,
  }),
};
const mapStateToProps = state => {
  let orderId = get(state, 'receiverApp.quickOrderView', null);
  return {
    orderId,
    navigation: state.navigation,
    user: state.user,
    width: get(state, 'window.width', 0),
    height: get(state, 'window.height', 0),
    order:
      (get(state, 'receiverApp.orders', []) || []).find(
        order => get(order, 'id', '') === orderId,
      ) || null,
    offer:
      (get(state, 'receiverApp.offers', []) || []).find(
        offer => get(offer, 'orderId', '') === orderId,
      ) || null,
    constants: state.serverConstants,
    hideWarningBeforeAccepting: get(
      state,
      'receiverApp.hideWarningBeforeAccepting',
    ),
    orderStatus: get(state, 'receiverApp.orderStatuses')
      ? get(state, 'receiverApp.orderStatuses.' + orderId, {}) || {}
      : {status: 'POSTED'},
    googleKey: get(state, 'googleKey', ''),
  };
};

const mapDispatchToProps = dispatch => ({
  acceptOrder: (offer, order, expectedPickup, callback) => {
    dispatch(
      courierAcceptOrder(
        {
          offer: parseFloat(offer, 10),
          orderId: order.id,
          expectedPickup,
        },
        callback,
      ),
    );
  },
  sentOffer: (
    offer,
    description,
    currency,
    order,
    expectedPickup,
    callback,
  ) => {
    dispatch(
      courierSendOffer(
        {
          offer: parseFloat(offer, 10),
          orderId: order.id,
          expectedPickup,
          currency,
          description,
        },
        callback,
      ),
    );
  },
  cancelOffer: (offerId, orderId) =>
    dispatch(
      courierCancelOffer({
        offerId,
        orderId,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderQuickView);
