const Storage = {
  getItem: async key => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  },
  setItem: async (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (err) {}
  },
  removeItem: async key => {
    try {
      localStorage.removeItem(key);
    } catch (err) {}
  },
};

export default Storage;
