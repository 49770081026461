import React, {useContext, useMemo, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {
  Title,
  IconButton,
  Div,
  Span,
  Address,
  Card as CardView,
  Button,
  Loader,
  Shadow,
  List,
} from '../Components';
import {LanguageContext} from '../../language/lang';
import {get, isEqual} from 'lodash';
import {ICClose} from '../Icons';
import moment from 'moment';
import {COLORS} from '../../common/constants';
import {
  hideOrderIdsModal,
  showOrderGroupModal,
  showQuickShowOrder,
} from '../../redux/actions';
import useDebug from '../../debug/useDebug';
import {useId} from '../hooks/useId';

class Card extends React.Component {
  shouldComponentUpdate(nextProps) {
    let item = get(this, 'props.item', {});
    let nextItem = get(nextProps, 'item', {});
    return !isEqual(item, nextItem);
  }
  render() {
    let item = get(this, 'props.item', {});
    return (
      <CardView style={{...styles.card, width: item.width - 70}}>
        <IconButton title="showOrder" onClick={() => item.onShowModal(item.id)}>
          <Div
            theme="flex-row jc-space-between"
            style={{marginBottom: 5, marginTop: 2}}>
            <Div style={styles.labelBackground(item.labelBackground, 150)}>
              <Span style={styles.labelText(item.labelColor, 150)}>
                {item.label}
              </Span>
            </Div>
            <Div
              theme="flex-row jc-center ai-center"
              style={{width: item.width / 2 - 60}}>
              {item.price ? (
                <>
                  <Span
                    style={{
                      color: COLORS.mainColorCourier,
                      fontWeight: 'bold',
                      fontSize: 14,
                    }}
                    noTranslate>
                    {item.price}
                  </Span>
                </>
              ) : null}
            </Div>
          </Div>
          {item.fromAddress && item.toAddress ? (
            <Address
              fromAddress={item.fromAddress}
              toAddress={item.toAddress}
            />
          ) : (
            <Title style={{lineHeight: 60}} noTranslate>
              {item.numberOfOrder}
            </Title>
          )}
          <Div theme="flex-row jc-space-between">
            <Div />
            {!item.example ? (
              <Span
                animation="rubberBand"
                iterationCount={1}
                duration={2000}
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  backgroundColor: COLORS.mainColorCourier,
                  marginBottom: 0,
                  paddingHorizontal: 23,
                  borderRadius: 4,
                  color: COLORS.white,
                }}>
                {item.takeOrder}
              </Span>
            ) : null}
          </Div>
        </IconButton>
      </CardView>
    );
  }
}
const itemRenderer = ({item}) => <Card item={item} />;
const SIX_HOURS = 1000 * 60 * 60 * 6;

function PackagesModal({
  oldOrders,
  isVisible,
  orders,
  orderIds,
  loggedIn,
  navigation,
  width,
  height,
  userInProgress,
  courierLoading,
}) {
  useDebug('PackagesModal');
  const lang = useContext(LanguageContext);
  const [noOrderes, setnoOrderes] = useState(false);
  const dispatch = useDispatch();
  const labelId = useId();

  const ordersToRender = useMemo(() => {
    let filteredOrders = orders || [];
    if (
      (orderIds || []).length === 0 &&
      (orders || []).length === 0 &&
      (oldOrders || []).length > 0
    ) {
      filteredOrders = oldOrders;
      setnoOrderes(true);
    } else {
      setnoOrderes(false);
    }
    return filteredOrders.map(order => {
      let nextTime = moment(get(order, 'createdTime', '')).format(
        lang['moment-short-datetime'],
      );
      let label = 'out-city-label';
      let labelColor = 'grey';
      let labelBackground = COLORS.labelOrange;

      if (Date.now() - get(order, 'createdTime', 0) < SIX_HOURS) {
        label = 'new-label';
        labelBackground = COLORS.labelGreen;
      }

      if (
        get(order, 'EN.fromAddress.city', '') ===
        get(order, 'EN.toAddress.city', '')
      ) {
        label = 'in-city-label';
        labelBackground = COLORS.labelBlue;
      }

      if (get(order, 'status') !== 'POSTED') {
        label = 'not-relevant-label';
        labelBackground = '#CBCCCE';
      }

      if (order.type === 'GROUP') {
        nextTime = '';
        label = 'group';
        labelBackground = '#a2ecf2';
      }

      if (order.volunteerOrder) {
        label = 'volunteer';
        labelBackground = COLORS.bgVolunteerColor;
      }
      const addressLang = lang.id?.includes('EN') ? 'EN' : lang.id;

      return {
        id: get(order, 'id', ''),
        renderId: get(order, 'id', ''),
        nextTime,
        label,
        labelColor,
        labelBackground,
        example: get(order, 'example', false),
        fromAddress: get(order, addressLang + '.fromAddress', null),
        numberOfOrder:
          (get(order, 'orders', []) || []).length + ' ' + lang.orders,
        toAddress: get(order, addressLang + '.toAddress', null),
        expectedDeliverTime: get(order, 'expectedDeliverTime', 0),
        senderPickedUpTime: get(order, 'senderPickedUpTime', 0),
        expectedPickupTime: get(order, 'expectedPickupTime', 0),
        courierAcceptTime: get(order, 'courierAcceptTime', 0),
        status: get(order, 'status', ''),
        size: get(order, 'size', ''),
        width,
        price: order?.bambizzCourierMaxPrice
          ? `${parseFloat(order?.bambizzCourierMaxPrice).toFixed(2)} ${
              lang[order?.bambizzPriceCurrency]
            }`
          : '',
        langId: lang.id,
        onShowModal:
          get(order, 'status') === 'POSTED'
            ? id => {
                dispatch(showQuickShowOrder(id));
              }
            : get(order, 'type') === 'GROUP'
            ? id => {
                dispatch(showOrderGroupModal(id));
              }
            : () => null,
        takeOrder:
          get(order, 'status') === 'POSTED' || get(order, 'type') === 'GROUP'
            ? lang['take-order']
            : '',
      };
    });
  }, [orders, orderIds]);
  return isVisible ? (
    <Div style={styles.background} role="dialog" aria-labelledby={labelId}>
      <Div
        style={{...styles.foreground, width: width - 30, height: height - 150}}>
        <Div theme="flex-row jc-space-between ai-center">
          <IconButton title="close" onClick={onClose}>
            <ICClose width={30} height={40} color={COLORS.white} />
          </IconButton>
          <Title
            id={labelId}
            style={{lineHeight: 20, fontSize: 18, color: COLORS.white}}>
            {noOrderes && loggedIn && !courierLoading
              ? 'there-are-no-orders'
              : 'packages'}
          </Title>
          <Div style={{width: 50}} />
        </Div>
        <Shadow />
        <Div style={{backgroundColor: 'white'}}>
          {courierLoading ? (
            <Div theme="flex-column jc-center" style={{height: 50}}>
              <Loader />
            </Div>
          ) : null}
          {loggedIn ? (
            <>
              {(ordersToRender || []).length === 0 && !courierLoading ? (
                <Div theme="flex-column jc-center" style={{height: 80}}>
                  <Title>there-are-no-orders</Title>
                </Div>
              ) : null}
              <List
                itemsToRender={ordersToRender || []}
                itemRenderer={itemRenderer}
                offset={270}
              />
            </>
          ) : userInProgress ? (
            <Div theme="flex-row jc-center ai-center" style={{height: 300}}>
              <Loader />
            </Div>
          ) : (
            <Div style={{height: height - 280}}>
              <Title style={{marginVertical: 10, color: 'grey'}}>
                you-are-logged-out
              </Title>
              <Button
                theme="orange empty"
                style={{margin: 20}}
                onClick={() => {
                  navigation.navigate('courier-login');
                  dispatch(hideOrderIdsModal());
                }}>
                login
              </Button>
            </Div>
          )}
        </Div>
        <Shadow bottom />
      </Div>
    </Div>
  ) : null;
  function onClose() {
    dispatch(hideOrderIdsModal());
  }
}

const styles = {
  background: {
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
    width: '100%',
  },
  card: {
    padding: 1,
    paddingBottom: 0,
    paddingTop: 0,
  },
  foreground: {
    backgroundColor: COLORS.mainColorCourier,
    borderRadius: 5,
    paddingBottom: 0,
  },
  labelBackground: (background, width) => ({
    backgroundColor: background || '#f4f4f4',
    borderRadius: 25,
    overflow: 'hidden',
    width,
    paddingHorizontal: 3,
  }),
  labelText: (color, width) => ({
    width,
    color: color || 'grey',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 26,
  }),
  list: height => ({
    height: height - 270,
  }),
};

const mapStateToProps = (state, ownProps) => {
  let lang = (state.lang || '').toUpperCase();
  let orders = null;
  let oldOrders = [];
  let orderStatuses = get(state, 'receiverApp.orderStatuses', null);
  let loggedIn = !!get(state, 'user.uid', null);
  let checkOrder = () => true;
  if (orderStatuses) {
    checkOrder = order => get(orderStatuses, order.id + '.status') === 'POSTED';
  }
  let stateOrders = (get(state, 'receiverApp.orders', []) || []).filter(
    order => {
      if (order.type === 'SINGLE') {
        return order.status === 'POSTED' && checkOrder(order);
      } else {
        return (order.orders || []).some(
          order => order.status === 'POSTED' && checkOrder(order),
        );
      }
    },
  );
  stateOrders = stateOrders.map(order => {
    if (order.type === 'GROUP') {
      order.orders = (order.orders || []).filter(
        order => order.status === 'POSTED' && checkOrder(order),
      );
    }
    return order;
  });
  let stateOldOrders = (get(state, 'receiverApp.orders', []) || []).filter(
    order => order.status !== 'POSTED',
  );
  if (Array.isArray(stateOrders)) {
    orders = stateOrders.map(order => {
      let fromAddress = get(order, lang + '.fromAddress', {});
      let toAddress = get(order, lang + '.toAddress', {});
      if (Object.keys(fromAddress).length > 0) {
        order.fromAddress = fromAddress;
      }
      if (Object.keys(toAddress).length > 0) {
        order.toAddress = toAddress;
      }
      return order;
    });
  }
  if (Array.isArray(stateOrders)) {
    oldOrders = stateOldOrders.map(order => {
      let fromAddress = get(order, lang + '.fromAddress', {});
      let toAddress = get(order, lang + '.toAddress', {});
      if (Object.keys(fromAddress).length > 0) {
        order.fromAddress = fromAddress;
      }
      if (Object.keys(toAddress).length > 0) {
        order.toAddress = toAddress;
      }
      return order;
    });
  }
  let orderIds = get(state, 'receiverApp.orderIdsModal', null);
  if ((orderIds || []).length) {
    orders = (orders || []).filter(order => orderIds.indexOf(order.id) !== -1);
  } else {
    orders = orders || [];
  }
  let isVisible = ownProps.isVisible;
  if (!Array.isArray(orderIds)) {
    isVisible = false;
    orderIds = [];
  }
  return {
    loggedIn,
    orderIds,
    isVisible,
    navigation: get(state, 'navigation', 0),
    orders,
    oldOrders,
    width: get(state, 'window.width', 0),
    height: get(state, 'window.height', 0),
    userInProgress: state.userInProgress,
    courierLoading: state.courierLoading,
  };
};

export default connect(mapStateToProps)(PackagesModal);
