import React, {useState, useContext, useRef, useEffect, useMemo} from 'react';
import {connect, useDispatch} from 'react-redux';
import {LanguageContext, LANGUAGES} from '../language/lang';
import {get} from 'lodash';
import {Div, IconButton, Title, Shadow, Input} from './Components';
import {COLORS} from '../common/constants';
import {ICClose} from './Icons';
import {useDebounce} from 'use-debounce';
import {hideAutoCompleteLang, setLanguage} from '../redux/actions';
import {Platform} from '../common/nativeFunctions';
import {useId} from './hooks/useId';

function AutoCompleteLang({isVisible, error, app, width}) {
  const dispatch = useDispatch();
  const lang = useContext(LanguageContext);
  const labelId = useId();

  const options = useMemo(
    () => Object.values(LANGUAGES).map(code => ({code, text: lang[code]})),
    [lang],
  );
  const [input, setinput] = useState('');
  const [predictions, setpredictions] = useState([]);
  const inputRef = useRef();
  const [, setfocus] = useState(false);
  const [editable, seteditable] = useState(false);
  const [debouncedInput] = useDebounce(input, 200);
  useEffect(() => {
    if (isVisible && editable) {
      setTimeout(() => {
        try {
          inputRef.current.focus();
        } catch (e) {}
      }, 200);
    }
  }, [isVisible, editable]);

  useEffect(() => {
    if (debouncedInput.length > 0) {
      setpredictions(
        options.filter(option =>
          option.text.toLowerCase().includes(debouncedInput.toLowerCase()),
        ),
      );
    } else {
      const base = [
        {code: LANGUAGES.EN, text: lang.EN},
        {code: LANGUAGES.EN_IMPERIAL, text: lang.EN_IMPERIAL},
      ];
      if (lang.id === LANGUAGES.HE) {
        base.push({code: LANGUAGES.HE, text: lang.HE});
      }
      setpredictions(base);
    }
  }, [debouncedInput, lang]);

  let mainColor = COLORS.mainColorSender;
  if (app === 'courier') {
    mainColor = COLORS.mainColorCourier;
  }
  return isVisible ? (
    <Div style={styles.mainContainer} role="dialog" aria-labelledby={labelId}>
      <Div style={styles.mainBG} keyboardShouldPersistTaps="always">
        <Div theme="flex-row jc-space-between">
          <IconButton
            title="close"
            onClick={() => dispatch(hideAutoCompleteLang())}>
            <ICClose color={mainColor} width={25} height={40} />
          </IconButton>
          <Title id={labelId} style={styles.title(mainColor)}>
            select-language
          </Title>
          <Div style={{width: 30}} />
        </Div>
        <Div>
          <Div
            style={{
              marginHorizontal: 10,
              height: 60,
              backgroundColor: '#f0f0f0',
              borderColor: error ? 'red' : '#f0f0f0',
              borderRadius: 0,
              borderWidth: 1,
              color: COLORS.black,
              fontSize: 17,
              paddingBottom: 4,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 4,
              lineHeight: 24,
            }}
            theme="flex-row jc-space-between">
            <Input
              ref={inputRef}
              value={input}
              onBecameEditable={() => seteditable(true)}
              onFocus={() => setfocus(true)}
              onBlur={() => setfocus(false)}
              onChange={val => setinput(val)}
              placeholder="enter-language"
              style={{
                fontSize: 17,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 14,
                paddingRight: 14,
                width: width - (Platform.OS === 'web' ? 95 : 65),
                borderWidth: 0,
                color: COLORS.black,
              }}
            />
            <IconButton title="clear" onClick={() => setinput('')}>
              <ICClose width={25} height={30} color={mainColor} />
            </IconButton>
          </Div>
          <Div style={{height: 10}} />
          <Shadow />
          <Div>
            {predictions.slice(0, 6).map((pred, i) => (
              <IconButton
                key={i}
                onClick={() => selectLanguage(pred)}
                title={pred.text}
                style={{
                  borderBottomWidth: 1,
                  borderColor: '#dddddd',
                  padding: 15,
                }}>
                <Div theme="flex-row">
                  <Title
                    style={{
                      fontSize: 16,
                      textAlign: 'auto',
                    }}>
                    {pred.text}
                  </Title>
                </Div>
              </IconButton>
            ))}
          </Div>
        </Div>
      </Div>
    </Div>
  ) : null;
  async function selectLanguage(pred) {
    dispatch(hideAutoCompleteLang());
    dispatch(setLanguage(pred.code));
  }
}

const styles = {
  mainBG: {
    backgroundColor: '#FCFCFC',
    height: '100%',
  },
  mainContainer: {
    backgroundColor: '#000000',
    flex: 1,
    height: '100%',
  },
  selectedTab: {
    borderColor: COLORS.mainColorSender,
  },
  shadow: {
    height: 4,
  },
  tabButton: {
    backgroundColor: 'transparent',
    borderBottomWidth: 4,
    borderColor: 'transparent',
    borderRadius: 0,
    width: '50%',
  },
  tabText: {
    color: COLORS.black,
  },
  title: color => ({
    lineHeight: 53,
    fontSize: 16,
    color,
  }),
};

const mapStateToProps = state => ({
  app: state.app,
  width: get(state, 'window.width', 0),
});

export default connect(mapStateToProps)(AutoCompleteLang);
