import React, {useContext} from 'react';
import {COLORS} from '../../common/constants';
import {flipDirection, LanguageContext} from '../../language/lang';
import {ThemeContext} from '../../themes/theme';

export default ({
  children,
  theme,
  noTranslate,
  fontSize,
  lineHeight,
  noOverflow,
  style,
  id,
  ...props
}) => {
  const lang = useContext(LanguageContext);
  const themeContext = useContext(ThemeContext);
  let text = children || '';
  if (!noTranslate && lang[text]) {
    text = lang[text];
  }
  let styles = {
    fontSize: fontSize || 20,
    lineHeight: lineHeight || 30,
    textAlign: 'center',
    color: COLORS.black,
    margin: 0,
    fontWeight: 'normal',
    ...(noOverflow
      ? {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}
      : {}),
    ...style,
  };
  (theme || '').split(' ').forEach(t => {
    if (themeContext[t]) {
      styles = {...styles, ...themeContext[t]};
    }
  });
  if (styles.flexDirection && flipDirection(lang.id)) {
    if (styles.flexDirection === 'row') {
      styles.flexDirection = 'row-reverse';
    } else if (styles.flexDirection === 'row-reverse') {
      styles.flexDirection = 'row';
    }
  }
  let css = styles;
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }
  return (
    <h3 {...props} id={id} style={css}>
      {text}
    </h3>
  );
};
