import React, {useEffect, useState, useRef} from 'react';
import {get} from 'lodash';
import {COLORS} from '../../common/constants';
import {connect, useDispatch} from 'react-redux';
import Div from './Div';
import Button from './Button';
import CheckBox from './Checkbox';
import {hideFilter} from '../../redux/actions';
import {Span} from '../Components';
import {Platform} from '../../common/nativeFunctions';
import {useFocusOnRender} from '../hooks/useFocusOnRender';
import {useOnEsc} from '../hooks/useOnEsc';
import Title from './Title';
import {useId} from '../hooks/useId';

const FilterContainerComponent = ({
  isVisible,
  width,
  filters,
  app,
  onChange,
}) => {
  const dispatch = useDispatch();
  const [filtersState, setfiltersState] = useState(filters);
  const filtersRef = useRef(filters);
  const modalRef = useFocusOnRender();
  const labelId = useId();

  useOnEsc({
    onEscPressed: () => dispatch(hideFilter()),
  });

  useEffect(() => {
    filtersRef.current = filtersState;
  }, [filtersState]);

  useEffect(() => {
    setfiltersState(filters);
  }, [filters]);
  const styles = {
    background: {
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'flex-end',
      width: '100%',
    },
    filterButton: {
      backgroundColor: 'transparent',
      margin: 0,
      paddingVertical: 7,
    },
    filterButtonApply: {
      backgroundColor: 'transparent',
      margin: 0,
      borderColor:
        app === 'sender' ? COLORS.mainColorSender : COLORS.mainColorCourier,
      borderWidth: 2,
      borderStyle: 'solid',
      borderRadius: 5,
      paddingVertical: 7,
    },
    filterButtonText: {
      fontSize: 14,
      color:
        app === 'sender' ? COLORS.mainColorSender : COLORS.mainColorCourier,
    },
    filterCheckbox: {
      paddingVertical: 10,
    },
    foreground: {
      backgroundColor:
        app === 'sender' ? COLORS.bgColorSender : COLORS.bgColorCourier,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      padding: 20,
      width: Platform.OS === 'web' ? width - 40 : '100%',
    },
    title: {
      color:
        app === 'sender' ? COLORS.mainColorSender : COLORS.mainColorCourier,
      fontSize: 20,
    },
  };
  return isVisible ? (
    <Div style={styles.background} role="dialog" aria-labelledby={labelId}>
      <Div ref={modalRef} style={styles.foreground}>
        <Div theme="flex-row jc-space-between">
          <Title id={labelId} style={styles.title}>
            filters
          </Title>
          <Div theme="flex-row">
            <Button
              onClick={() => dispatch(hideFilter())}
              style={styles.filterButton}
              textStyle={styles.filterButtonText}>
              CANCEL
            </Button>
            <Button
              onClick={() => {
                dispatch(hideFilter());
                if (typeof onChange === 'function') {
                  onChange(filtersRef.current);
                }
              }}
              style={styles.filterButtonApply}
              textStyle={styles.filterButtonText}>
              APPLY
            </Button>
          </Div>
        </Div>
        {filtersState.map((filter, i) => (
          <CheckBox
            key={i}
            value={filter.value}
            onChange={val =>
              setfiltersState([
                ...filtersState.map(f =>
                  f.key === filter.key ? {...f, value: val} : f,
                ),
              ])
            }
            theme={
              app === 'sender'
                ? COLORS.mainColorSender
                : COLORS.mainColorCourier
            }
            style={styles.filterCheckbox}
            label={filter.label}
            title={filter.label}
          />
        ))}
      </Div>
    </Div>
  ) : null;
};

export default connect(state => ({
  filters: get(state, 'filter.filters', []),
  onChange: get(state, 'filter.onChange'),
  app: state.app,
  width: get(state, 'window.width', 0),
}))(FilterContainerComponent);
